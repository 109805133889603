import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  margin-bottom: 70px;
  margin-top: 30px;
`;

export const LoadingContainerChannels = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  margin-top: 100px;
`;
