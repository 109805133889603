interface GetOneAttendanceQueueProps {
    guid: string
    queueName: string
}

export const GetOneAttendanceQueue = ({ guid, queueName }: GetOneAttendanceQueueProps) => {
    return {
        id: guid,
        to: 'postmaster@desk.msging.net',
        method: 'get',
        uri: `/attendance-queues/${queueName}`
    }
}
