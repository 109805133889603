interface IModalText {
  [field: string]: any;
}

export const MODAL_TEXTS = {
  Instagram: {
    title: 'Conecte o seu Blip Go! ao Instagram',
    info1a: `Uma nova aba abrirá para você conectar o canal Instagram, 
        e começar a automatizar as suas DMs com o bot Blip Go!.`,
    info2: `Após conectar, você deve fechar a aba e realizar todas 
        as configurações necessárias aqui na plataforma Blip Go!.`,
  },
  Messenger: {
    title: 'Conecte o seu Blip Go! ao Messenger',
    info1a: `Uma nova aba abrirá para você conectar o canal Messenger, 
        e começar a automatizar os seus chats com o bot Blip Go!.`,
    info2: `Após conectar, você deve fechar a aba e 
        realizar todas as configurações necessárias aqui na plataforma Blip Go!.`,
  },
  WhatsApp: [
    {
      title: '✅ Bot pronto (automático 24H):',
      subtitle: '',
      subitems: [
        { title: 'Recepção imediata' },
        { title: 'Menu direcionador' },
        { title: 'Respostas automáticas para dúvidas frequentes' },
        { title: 'Mensagens com botões de navegação' },
        { title: 'Captura de necessidade de atendimento e encaminhamento para atendimento humano' },
      ],
      basicPlanDisabled: false,
      basicPlanTitle: '✅ Bot pronto (automático 24H):',
    },
    {
      title: '✅ Pesquisa de satisfação do atendimento humano',
      subtitle: '',
      subitems: [],
      basicPlanDisabled: false,
      basicPlanTitle: '✅ Pesquisa de satisfação do atendimento humano',
    },
    {
      title: '✅ Indicadores de atendimento',
      subtitle: '',
      subitems: [],
      basicPlanDisabled: false,
      basicPlanTitle: '✅ Indicadores de atendimento',
    },
    {
      title: '✅ Canal Blip chat conectável ao bot',
      subtitle: '',
      subitems: [],
      basicPlanDisabled: false,
      basicPlanTitle: '✅ Canal Blip chat conectável ao bot',
    },
    {
      title: '✅ Canal Facebook messenger conectável ao bot',
      subtitle: '',
      subitems: [],
      basicPlanDisabled: false,
      basicPlanTitle: '✅ Canal Facebook messenger conectável ao bot',
    },
    {
      title: '✅ Canal Instagram conectável ao bot',
      subtitle: '',
      subitems: [],
      basicPlanDisabled: false,
      basicPlanTitle: '✅ Canal Instagram conectável ao bot',
    },
    {
      title: '✅ Canal Whatsapp conectável ao bot',
      subtitle: '(450 conversas por mês via whatsapp)*',
      subitems: [],
      basicPlanDisabled: true,
      basicPlanTitle: '⛔ Canal Whatsapp conectável ao bot',
    },
    {
      title: '✅ Campanhas de marketing via whatsapp',
      subtitle: '',
      subitems: [],
      basicPlanDisabled: true,
      basicPlanTitle: '⛔ Campanhas de marketing via whatsapp',
    },
    {
      title: '✅ Até 7 atendentes 👤👤👤👤👤👤👤',
      subtitle: '',
      subitems: [],
      basicPlanDisabled: false,
      basicPlanTitle: '✅ Até 5 atendentes 👤👤👤👤👤',
    },
    {
      title: '✅ Até 5 equipes de atendimento',
      subtitle: '',
      subitems: [],
      basicPlanDisabled: false,
      basicPlanTitle: '✅ Até 3 equipes de atendimento',
    },
  ],
  Action: {
    button: {
      cancel: 'Voltar',
      redirect: 'Conectar',
    },
  },
} as IModalText;
