import { useAuthContext } from 'contexts/AuthContext';
import { ModuleBotConfigurationTracks } from 'services/trackingService/handleSendTrackings';

type AlertProps = {
  isOpen: boolean;
  setIsOpen(value: boolean): void;
  handleRemove: () => void;
  screenName: string;
  type: string;
  merchantName: string;
};

export const AlertDelete = ({ isOpen, handleRemove, setIsOpen, screenName, type, merchantName }: AlertProps) => {
  const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();

  const { activationOption } = useAuthContext();

  const handleSendTracking = async () => {
    moduleBotConfigurationTracks.sendTrackClickButtonConfirmDelete({
      activationOption,
      success: true,
      screenName,
      type,
      error: 'none',
      merchantName,
    });
  };

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleConfirm = () => {
    handleRemove();
    handleSendTracking();
  };

  return (
    <bds-alert open={isOpen}>
      <bds-alert-header variant="delete" icon="trash">
        <bds-typo variant="fs-16" bold="bold" style={{ color: 'white' }}>
          Deseja mesmo excluir?
        </bds-typo>
      </bds-alert-header>

      <bds-alert-body>
        <bds-typo variant="fs-14">Você perderá todos os dados inseridos neste tópico.</bds-typo>
      </bds-alert-body>

      <bds-alert-actions>
        <bds-button variant="secondary" onClick={handleIsOpen}>
          <bds-typo variant="fs-14" bold="bold">
            Cancelar
          </bds-typo>
        </bds-button>

        <bds-button variant="secondary" onClick={handleConfirm}>
          <bds-typo variant="fs-14" bold="bold">
            Confirmar
          </bds-typo>
        </bds-button>
      </bds-alert-actions>
    </bds-alert>
  );
};
