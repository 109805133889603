export class DefaultUserTraits {
  moduleName: string;
  packName: string | undefined;
  packId: string | undefined;
  plan: string | undefined;
  botIdentifier: string;
  tenant: string;
  merchantName: string;
  userMail: string;
  screenResolution: string;
  deviceType: string;
  operationalSystem?: string | null;

  constructor(
    moduleName: string,
    botIdentifier: string,
    tenant: string,
    merchantName: string,
    userMail: string,
    packName: string,
    packId: string,
    plan: string,
  ) {
    this.moduleName = moduleName;
    this.userMail = userMail;
    this.botIdentifier = botIdentifier;
    this.packName = packName;
    this.packId = packId;
    this.plan = plan;
    this.tenant = tenant;
    this.merchantName = merchantName;
    this.screenResolution = `${window.screen.width}x${window.screen.height}`;
    this.deviceType = this.getDeviceType();
    this.operationalSystem = this.getOperationalSystem();
  }

  private getOperationalSystem() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let operationalSystem = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      operationalSystem = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      operationalSystem = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      operationalSystem = 'Windows';
    } else if (/Android/.test(userAgent)) {
      operationalSystem = 'Android';
    } else if (!operationalSystem && /Linux/.test(platform)) {
      operationalSystem = 'Linux';
    }

    return operationalSystem;
  }

  private getDeviceType() {
    const userAgent = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        userAgent,
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }
}
