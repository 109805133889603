import { useCallback } from 'react';
import { ButtonIconContainer, InputContainer, InputTextContainer, LabelContainer, MainContainer } from './styles';
import InputText from 'components/InputText';
import tabsTexts from 'texts/tabsTexts.json';
import { useAppTabsContext } from 'contexts/TabsContext';
import { FAQField } from 'types/botMolule/FAQField';
import { MENU_FIELD_ANSWER, MENU_FIELD_TOPIC } from 'pages/BotConfiguration/constants';
import { validateInputPontuationMarks } from 'utils/validates/validateInputPontuationMarks';

interface IMenuFieldsProps {
  menuFieldIndex: number;
  menuField: { topic: string; answer: string };
  quantityFields: number;
  faqList: FAQField[];
  handleClickDeleteButton: () => void;
}

export const MenuField = ({
  menuFieldIndex,
  menuField,
  quantityFields,
  faqList,
  handleClickDeleteButton,
}: IMenuFieldsProps) => {
  const {
    setFaqList,
    setFAQ,
    setBlurMenuComponent,
    setFocusMenuComponent,
    setFocusTopicValueComponent,
    setFocusMenuValueComponent
  } = useAppTabsContext();
  const topicIsEmpty = menuField.topic === '';

  const setModifiedTabMenu = useCallback(() => {
    if (menuField.answer !== '' || menuField.topic !== '') {
      setFAQ(FAQ => ({ ...FAQ, isModified: true }));
    } else {
      setFAQ(FAQ => ({ ...FAQ, isModified: false }));
    }
  }, [menuField.answer, menuField.topic, setFAQ]);

  const save = useCallback(
    (updateList: FAQField[]) => {
      setFaqList(updateList);
      setModifiedTabMenu();
    },
    [setModifiedTabMenu, setFaqList],
  );

  const updateFaqList = useCallback(
    (value: string, menuFieldIndex: number, name: string) => {
      return faqList.map((faqList, index) => {
        if (index === menuFieldIndex) {
          return {
            ...faqList,
            [name]: value,
          };
        }
        return faqList;
      });
    },
    [faqList],
  );

  const handleOnChange = useCallback(
    (ev: Event, index: number, name: string) => {
      const { value } = ev.target as HTMLBdsInputElement;

      if (value !== undefined && value !== null && faqList) {
        const updateList = updateFaqList(value, index, name);
        save(updateList);
        setFocusMenuValueComponent(value);
      }
    },
    [faqList, save, updateFaqList, setFocusMenuValueComponent],
  );

  const handleFocusComponent = (component: string) => {
    setFocusMenuComponent(component);
    handleBlurComponent(false);

    if (component === MENU_FIELD_TOPIC) {
      setFocusTopicValueComponent(menuField.topic);

    } else {
      setFocusMenuValueComponent(menuField.answer);
      setFocusTopicValueComponent(`${menuFieldIndex + 1}`);
    }
  };

  const handleBlurComponent = (isBlur: boolean) => {
    setBlurMenuComponent(isBlur);
  };

  return (
    <MainContainer>
      <LabelContainer topicIsEmpty={topicIsEmpty}>
        <InputTextContainer listLength={quantityFields}>
          <InputText
            label={tabsTexts.customizableMenu.optionMenu}
            placeholder={tabsTexts.customizableMenu.titleOptionMenu}
            onChange={event => handleOnChange(event, menuFieldIndex, 'topic')}
            value={menuField.topic}
            required={true}
            requiredErrorMessage={tabsTexts.error.requiredField}
            danger={!validateInputPontuationMarks(menuField.topic)}
            errorMessage={menuField.topic == '' ? tabsTexts.error.requiredField : ''}
            onFocus={() => handleFocusComponent(MENU_FIELD_TOPIC)}
            onBlur={() => handleBlurComponent(true)}
          />
        </InputTextContainer>

        {quantityFields > 1 && (
          <ButtonIconContainer>
            <bds-button-icon variant="ghost" icon="trash" onClick={() => handleClickDeleteButton()} />
          </ButtonIconContainer>
        )}
      </LabelContainer>

      <InputContainer>
        <InputText
          isTextArea={true}
          rows={4}
          placeholder={tabsTexts.customizableMenu.responseMenuDescription}
          type="email"
          label={tabsTexts.customizableMenu.responseMenu}
          value={menuField.answer}
          onChange={event => handleOnChange(event, menuFieldIndex, 'answer')}
          required={true}
          requiredErrorMessage={tabsTexts.error.requiredField}
          danger={menuField.answer == ''}
          errorMessage={menuField.answer == '' ? tabsTexts.error.requiredField : ''}
          onFocus={() => handleFocusComponent(MENU_FIELD_ANSWER)}
          onBlur={() => handleBlurComponent(true)}
        />
      </InputContainer>
    </MainContainer>
  );
};

export default MenuField;
