import axios from 'axios';

const api = () => {
  const url = `${process.env.REACT_APP_API_SERVER}/api/v1`;
  return axios.create({
    baseURL: url,
  });
};

export default api;
