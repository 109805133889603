import { useHumanServiceContext } from 'contexts/HumanServiceContext';
import InputMessageEditable from 'components/InputMessageEditable';
import { Switch } from 'components/Switch/Switch';
import { TextContainer } from '../styles';

import tabsTexts from 'texts/tabsTexts.json';
import QueueFields from '../components/QueueFields';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Tabs } from 'types/Tabs';
import { Requests } from 'services/requests/Requests';

import { TabsPanelContainer } from 'styles/tabs.styles';
import { InputContainer } from './styles';
import SubmitButton from '../components/SubmitButton';
import AlertsButtonCancel from '../components/AlertsButtonCancel';
import Grid from 'components/Grid';
import TabGridContainer from 'components/TabGridContainer';
import AttendanceQueueSimulator from '../components/Simulators/AttendanceQueueSimulator';

type AttendanceQueueProps = {
  idxRemoved: number;
  setIdxRemoved: Dispatch<SetStateAction<number>>;
  setAttendanceQueue: Dispatch<SetStateAction<Tabs>>;
  isCanceledAction: boolean;
  setIsCanceledAction: Dispatch<SetStateAction<boolean>>;
  requestObject?: Requests;
};

export const AttendanceQueue = ({
  idxRemoved,
  setIdxRemoved,
  setAttendanceQueue,
  isCanceledAction,
  setIsCanceledAction,
  requestObject,
}: AttendanceQueueProps) => {
  const { preQueueMessage, setPreQueueMessage, emojis, setEmojis, queueList, setQueueList } = useHumanServiceContext();
  const [blurQueueComponent, setBlurQueueComponent] = useState<boolean>(true);
  const [blurQueueMessageComponent, setBlurQueueMessageComponent] = useState<boolean>(true);
  const [cancelWarning, setCancelWarning] = useState(false);
  const memoizedQueueFields = useMemo(
    () => (
      <QueueFields
        setIdxRemoved={setIdxRemoved}
        idxRemoved={idxRemoved}
        queueList={queueList}
        setQueueList={setQueueList}
        setTabData={setAttendanceQueue}
        setIsCanceledAction={setIsCanceledAction}
        isCanceledAction={isCanceledAction}
        requestObject={requestObject}
        setBlurComponent={setBlurQueueComponent}
      />
    ),
    [
      idxRemoved,
      isCanceledAction,
      queueList,
      requestObject,
      setAttendanceQueue,
      setIdxRemoved,
      setIsCanceledAction,
      setQueueList,
    ],
  );

  const handleChangePreQueueMessage = (ev: Event) => {
    const input = ev.target as HTMLBdsInputElement | null;
    setPreQueueMessage(input?.value as string);
  };

  const handleSetEmojis = async (event: Event) => {
    const input = event.currentTarget as HTMLBdsSwitchElement | null;
    const value = await input?.getValue();

    setEmojis(!!value);
  };

  return (
    <TabGridContainer>
      <Grid xxs="12" md="8" lg="9" flex-wrap="wrap" direction="row">
        <TabsPanelContainer>
          <TextContainer>
            <bds-typo variant="fs-16" bold="bold" tag="p">
              Cadastre os atendentes e personalize os assuntos, tópicos, equipes ou setores da sua empresa que seu
              cliente poderá escolher ao solicitar atendimento humano.
            </bds-typo>
          </TextContainer>
          <Grid xxs="12" flex-wrap="wrap" style={{ padding: 0 }}>
            <Grid xxs="12" md="6">
              <InputMessageEditable
                disabled={false}
                onChange={handleChangePreQueueMessage}
                label={tabsTexts.attendanceQueue.preQueueMessage.label}
                message={preQueueMessage}
                rows={3}
                required={false}
                requiredErrorMessage={tabsTexts.error.requiredField}
                onFocus={() => setBlurQueueMessageComponent(false)}
                onBlur={() => setBlurQueueMessageComponent(true)}
                danger={preQueueMessage === ''}
                errorMessage={preQueueMessage === '' ? tabsTexts.error.requiredField : ''}
              />
            </Grid>
            <Grid xxs="12" md="6">
              <Switch
                text={tabsTexts.attendanceQueue.emojis}
                name="emojis"
                checked={emojis}
                isEmojiSwitch={true}
                onClick={handleSetEmojis}
              />
            </Grid>
          </Grid>
          <InputContainer>{memoizedQueueFields}</InputContainer>
        </TabsPanelContainer>
        <SubmitButton cancelWarning={cancelWarning} setCancelWarning={setCancelWarning} />
        <AlertsButtonCancel cancelWarning={cancelWarning} setCancelWarning={setCancelWarning} />
      </Grid>

      <Grid md="3" style={{ width: 'auto' }}>
        <AttendanceQueueSimulator
          isBlurQueue={blurQueueComponent}
          isBlurQueueMessage={blurQueueMessageComponent}
        />
      </Grid>
    </TabGridContainer>
  );
};
