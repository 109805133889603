import { UpdateContactHubspot } from 'constants/UpdateContactHubspot';
import { api } from './requests/Api';
import { Requests } from './requests/Requests';
import { v4 as uuidv4 } from 'uuid';
import {
    IS_PRO_PLAN_REQUESTED_ACTIVE,
    handleGetIsProPlanRequestedResource,
    handleUpdateContactHubspot
} from './HubspotIntegration';

const CHANNLES = {
    Messenger: 'messenger',
    Instagram: 'instagram'
};

type ValidatePageToken = {
    IsValidated: string;
    IsValidAccessToken: string;
};

type ResponseBase = {
    resource: ValidatePageToken;
};

export const handleInstagramAndMessengerChannelsWithHubspot = async (
    keyRouter: string, accessToken: string) => {
    const isProPlanRequested = await handleGetIsProPlanRequestedResource(keyRouter);

    if (isBlipGoStatusProActive(isProPlanRequested)) {
        return;
    }

    const [validateInstagramChannel, validateMessengerChannel] =
        await getInstagramAndMessengerChannels(keyRouter);

    if (isConnectedChannel(validateInstagramChannel.resource)
        || isConnectedChannel(validateMessengerChannel.resource)) {
        await handleUpdateContactHubspot(UpdateContactHubspot.BlipGoFreeConectado, accessToken);
    }
};

const getInstagramAndMessengerChannels = async (keyRouter: string) => {
    const request = new Requests(api(keyRouter));

    return await Promise.all<ResponseBase>([
        request.getValidatePageToken(CHANNLES.Instagram, uuidv4()),
        request.getValidatePageToken(CHANNLES.Messenger, uuidv4())
    ]);
};

const isConnectedChannel = (channel: ValidatePageToken) => {
    return channel.IsValidAccessToken && channel.IsValidated;
}

const isBlipGoStatusProActive = (blipGoStatusValue: any) => {
    return blipGoStatusValue?.resource === IS_PRO_PLAN_REQUESTED_ACTIVE;
};