import Grid from 'components/Grid/Grid';
import React from 'react';
import { MainContainer } from 'styles/tabs.styles';
import { WhatsappActivationFooter } from 'components/WhatsappActivationFooter';
import ExternalUrls from 'constants/ExternalUrls';
import { UpgradeWATracks as _upgradeWATracks } from './trackings/UpgradeWATracks';
import { useNavigate } from 'react-router-dom';
import { CHANNELS_ROUTE, PACK_MANAGER_ROUTE } from 'constants/Routes';
import { MailService as _mailService } from 'services/mailService';
import { MailSubject as _mailsubject } from 'services/MailSubject';
import { useAuthContext } from 'contexts/AuthContext';
import { MAIL_TEMPLATE } from 'constants/ActivationEmail';
import { EMAIL_TEAM } from 'constants/MailCredentials';

export const ProcessUpgrade = () => {
  const navigate = useNavigate();
  const { userData } = useAuthContext();

  const cancelUpgrade = () => {
    sendCancelMail();
    _upgradeWATracks.sendTrackCancelWaActivation();
    navigate(`${PACK_MANAGER_ROUTE}/${CHANNELS_ROUTE}`);
  };

  const connectApi = () => {
    _upgradeWATracks.sendTrackConnectWaApi();
    window.open(`${ExternalUrls.whatsappActivationChat}${createWhatsappMessage()}`, '_blank');
  };

  const sendCancelMail = () => {
    const tos = { toName: 'Experience Cluster', toMailAddress: EMAIL_TEAM };
    let html = MAIL_TEMPLATE.html.code;
    html = html.replace(
      'userData',
      `
        <li> Nome: ${userData.FullName}</li>
        <li> E-mail: ${userData.Email}</li>
    `,
    );
    _mailService.sendEmail(userData.FullName, tos, _mailsubject.subjectCancel(), html, true);
  };

  const createWhatsappMessage = () => {
    if (_mailsubject.isFromMeta() === '[META]') {
      return 'Quero saber mais sobre o Blip Go!☺️'
    } else {
      return 'Quero vender mais pelo WhatsApp'
    }
  }

  return (
    <MainContainer>
      <Grid xxs="12" flex-wrap="wrap" direction="column" gap="3" margin="t-1">
        <bds-typo variant="fs-24" bold="bold" margin={false}>
          Contratação Plano Pro
        </bds-typo>
        <bds-grid direction="column" gap="2">
          <bds-typo variant="fs-16">
            Estamos <b>processando seu upgrade...</b>
          </bds-typo>
          <bds-loading-bar percent={100} />
          <bds-typo variant="fs-16">
            Você receberá o <b>documento do contrato</b> do Plano Pro
            <b> no email do representante legal nos próximos dias.</b>
          </bds-typo>
        </bds-grid>
        <bds-button onClick={() => connectApi()} variant="primary" data-testid="connect-WA-api-btn">
          Conectar com Whatsapp API
        </bds-button>
        <WhatsappActivationFooter linkPressed={() => cancelUpgrade()} linkTitle="Cancelar contratação de upgrade" />
      </Grid>
    </MainContainer>
  );
};
