import './styles.scss';
import React, { useState } from 'react';
import WhatsappPaywall from '../whatsappPaywall';
import { BlipGoIntroduction } from 'pages/channels/components/blipGoIntroduction';
import { BLIP_GO_INTRO, WPP_PAYWALL, ACTIVATION_FORM, TRIAL_WARN } from 'pages/channels/constants/wppModalInfo';
import { ChannelsTracks } from 'pages/channels/services/trackings/handleSendTrackings';
import { ActivationForm } from '../activationForm';
import { TrialModal } from '../trialModal';

interface IWhatsappModal {
  modalRef: React.MutableRefObject<HTMLBdsModalElement | null>;
  introductionTitle?: boolean;
}

const WhatsappModal = (props: IWhatsappModal) => {
  const [currentPage, setCurrentPage] = useState(BLIP_GO_INTRO);
  const [previousPage, setPreviousPage] = useState('');
  const [activateTrial, setActivateTrial] = useState(false);
  const channelsTracks = new ChannelsTracks();
  const modalRef = props.modalRef;

  const handleModalClose = () => {
    if (currentPage === BLIP_GO_INTRO || currentPage === TRIAL_WARN) {
      closeModal();
    } else {
      if (process.env.REACT_APP_WHATSAPP_TRIAL) {
        setPreviousPage(currentPage);
        setCurrentPage(TRIAL_WARN);
      } else {
        closeModal();
      }
    }
  };

  const closeModal = () => {
    if (currentPage === TRIAL_WARN) {
      channelsTracks.sendTrackSevenDaysClosed();
    }
    channelsTracks.sendTrackCloseMeetBlipGo({ screenName: currentPage });
    if (modalRef.current) {
      modalRef.current.open = false;
      setCurrentPage(previousPage);
    }
  };

  const handleBackFromTrial = () => {
    channelsTracks.sendTrackSevenDaysConfirmed();
    setCurrentPage(ACTIVATION_FORM);
    setActivateTrial(true);
  };

  return (
    <bds-modal ref={modalRef} closeButton={true} size="dynamic" close-button={false}>
      <div className="modal-shell-wrapper">
        <div className="modal-shell-header">
          {currentPage !== TRIAL_WARN ? (
            <bds-button-icon icon="close" variant="secondary" onClick={handleModalClose} />
          ) : (
            <></>
          )}
        </div>
        <div>
          {currentPage === BLIP_GO_INTRO ? (
            <BlipGoIntroduction
              finishBtnPressed={() => setCurrentPage(WPP_PAYWALL)}
              screenTitle={props?.introductionTitle}
            />
          ) : currentPage === WPP_PAYWALL ? (
            <WhatsappPaywall modalRef={modalRef} finishBtnPressed={() => setCurrentPage(ACTIVATION_FORM)} />
          ) : currentPage === ACTIVATION_FORM ? (
            <ActivationForm modalRef={modalRef} activateTrial={activateTrial} />
          ) : (
            <TrialModal agreementEvent={handleBackFromTrial} cancelEvent={closeModal} />
          )}
        </div>
      </div>
    </bds-modal>
  );
};
export default WhatsappModal;
