import { applyPolyfills, defineCustomElements } from 'blip-ds/loader';
import { PackProvider } from 'contexts/PackContext';
import { PackManagerProvider } from 'contexts/PackManagerContext';
import { GlobalStyles } from 'globalStyles';
import { useHashPrevention } from 'hooks/useHashPrevention';
import { useAuth } from 'oidc-react';
import NavigationBars from 'pages/NavigationBars';
import { FC, useEffect, useState } from 'react';
import { Configuration } from 'typings/Configuration';
import { Profile } from 'typings/OIDCProfile';

interface BaseUserData {
  access_token: string;
  profile: Profile;
  settingsSource: Configuration;
}

const PackManager: FC = () => {
  const auth = useAuth();
  const [appData, setAppData] = useState<BaseUserData>({} as BaseUserData);
  useHashPrevention('/pack-manager');

  applyPolyfills().then(() => {
    defineCustomElements(window);
  });

  useEffect(() => {
    document.title = 'Blip Go!';

    const userData = {
      access_token: auth.userData?.access_token ?? '',
      profile: auth.userData?.profile as Profile,
      settingsSource: {
        ApiServer: process.env.REACT_APP_API_SERVER ?? '',
        MsgingServer: process.env.REACT_APP_MSGING_URL ?? '',
        ApiPort: process.env.REACT_APP_API_PORT ?? '',
        BlipGoIdHmg: Number(process.env.REACT_APP_BLIP_GO_ID_HMG),
        BlipGoId: Number(process.env.REACT_APP_BLIP_GO_ID),
        Environment: process.env.REACT_APP_ENVIRONMENT ?? '',
        HubspotApiKey: process.env.REACT_APP_HUBSPOT_API_KEY ?? '',
        InstallationChatBot: process.env.REACT_APP_INSTALLATION_CHAT_BOT ?? '',
        NumberDaysToExpireUserSession: Number(process.env.REACT_APP_NUMBER_DAYS_TO_EXPIRE_USER_SESSION),
        FacebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID ?? '',
        FacebookBusinessSystemUserToken: process.env.REACT_APP_FACEBOOK_BUSINESS_SYSTEM_USER_TOKEN ?? '',
        SelfOnboardingUrl: process.env.REACT_APP_SELF_ONBOARDING_URL ?? '',
      },
    };

    setAppData(userData);
    (window as any).fbq('trackSingle', '199545954151742', 'StartTrial');
    (window as any).gtag('event', 'conversion', { send_to: 'AW-874108198/q8-3CJq68poYEKaq56AD' });
  }, [auth.userData]);

  return (
    <>
      <GlobalStyles />
      <PackManagerProvider
        access_token={appData.access_token}
        profile={appData.profile}
        settingsSource={appData.settingsSource}
      >
        <PackProvider>
          <NavigationBars />
        </PackProvider>
      </PackManagerProvider>
    </>
  );
};

export default PackManager;
