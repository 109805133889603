import styled from 'styled-components';

export const IframeContent = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

export const ModalContent = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 50vw;
  height: 50vh;

  bds-loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
`;

export const ModalFooter = styled.footer`
  position: absolute;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 3px;
  width: 100%;
  height: 10%;
  bottom: 0;
`;

export const SurveyContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;

  bds-button {
    padding: 4px;
  }

  @media screen and (max-width: 1385px) {
    width: 50%;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 1385px) {
    width: 50%;
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
`;
