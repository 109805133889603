import base64 from 'base-64';
import {
  INSTALLATIONS_CHANNEL_ACTIVATED_ROUTE,
  PACK_INSTALLATIONS_ROUTE,
  INSTALLATION_BY_ID,
  PLUGIN_ROUTE,
} from 'constants/ApiRoutes';
import ExternalUrls from 'constants/ExternalUrls';
import { ModuleIds } from 'constants/ModuleIds';
import { PackNames } from 'constants/PackNames';
import { PlanNames } from 'constants/PlanNames';
import { usePackManagerContext } from 'contexts/PackManagerContext';
import { useCallback, useMemo } from 'react';
import { GetRouterDataFromInstallation } from 'services/PackService';
import { Installation, InstallationDetails } from 'typings/Installation';
import { Module, ModuleAppData } from 'typings/Module';
import { Pack } from 'typings/Pack';
import { PluginData } from 'typings/PluginData';
import { useAuth } from 'oidc-react';

export type Application = {
  id: string;
  name?: string;
  description?: string;
  channels?: string;
  version?: string;
  routerShortName?: string;
  plugin?: PluginData;
};

export const usePack = () => {
  const auth = useAuth();
  const { apiService, profile, settings, accessToken } = usePackManagerContext();
  const urlParams = useMemo(() => new URLSearchParams(window.location.search.toLowerCase()), []);
  const isHomologation = settings.Environment == 'HMG' || settings.Environment == 'staging';

  const getPackNameByPackId = useCallback(
    (packId: number) => {
      const packNamesById = {
        [isHomologation ? settings.BlipGoIdHmg : settings.BlipGoId]: PackNames.go,
      };
      console.warn('packNamesById[packId]', packNamesById[packId]);
      return packNamesById[packId] ? packNamesById[packId] : '';
    },
    [isHomologation, settings],
  );

  const getPackName = useCallback(
    (packId: string) => {
      const packNameFromUrlWhenHmgEnv = isHomologation && urlParams.get('packname');
      return packNameFromUrlWhenHmgEnv ? packNameFromUrlWhenHmgEnv : getPackNameByPackId(+packId);
    },
    [isHomologation, urlParams, getPackNameByPackId],
  );

  const getLastInstallationAndDetails = useCallback(
    async (installations: Installation[]) => {
      const currentInstallation = installations.reduce((prev, current) => {
        return prev.installationId > current.installationId ? prev : current;
      });

      const installationDetails = await apiService.get<InstallationDetails>(
        `${INSTALLATION_BY_ID}/${currentInstallation.installationId}`,
      );

      return { currentInstallation, installationDetails };
    },
    [apiService],
  );

  const getSpecificInstallationAndDetails = useCallback(
    async (installations: Installation[], installationId: string | null) => {
      const currentInstallation = installations.find(installation => installation.installationId == installationId);
      if (currentInstallation) {
        const installationDetails = await apiService.get<InstallationDetails>(
          `${INSTALLATION_BY_ID}/${currentInstallation.installationId}`,
        );

        return { currentInstallation, installationDetails };
      }
      throw new Error('No installation for this ID.');
    },
    [apiService],
  );

  const fetchPluginFromPack = useCallback(
    async (packId: string) => {
      return await apiService.get<PluginData>(`${PLUGIN_ROUTE}/${packId}`, false);
    },
    [apiService],
  );

  const fetchPackData = useCallback(async () => {
    const packId = urlParams.get('packid')
      ? urlParams.get('packid')
      : isHomologation
      ? settings.BlipGoIdHmg
      : settings.BlipGoId;
    const installationId = urlParams.get('installationid');

    let installations: any;
    let pack: Pack = {} as Pack;
    let isOwner = false;
    let installationData: { currentInstallation: Installation; installationDetails: InstallationDetails };

    const profileEmail = profile?.email ?? '';

    if (packId) {
      installations = await apiService.get<Installation[]>(`${PACK_INSTALLATIONS_ROUTE}?packId=${packId}`);
      if (installations?.installations) {
        installations = installations.installations;
      }
    } else {
      installations = await apiService.get<Installation[]>(`${INSTALLATIONS_CHANNEL_ACTIVATED_ROUTE}/${profileEmail}`);
    }

    if (installations.length == 0) {
      pack.id = '';
      pack.name = '';
      pack.tenant = { id: '' };
      await redirectToSelfOnboarding();
    }

    isOwner = true;

    try {
      installationData = await getSpecificInstallationAndDetails(installations, installationId);
    } catch {
      installationData = await getLastInstallationAndDetails(installations);
    }

    pack = {
      id: installationData.currentInstallation.applicationId,
      routerData: GetRouterDataFromInstallation(installationData.currentInstallation),
      isChannelActivated: installationData.currentInstallation.isChannelActived,
      pluginData: await fetchPluginFromPack(installationData.currentInstallation.applicationId),
      name: getPackName(installationData.currentInstallation.applicationId),
      tenant: installationData.installationDetails.tenant,
      companyName: installationData.installationDetails.clientIdentity,
      installationId: installationData.installationDetails.id,
    };

    return { pack, isOwner };
  }, [
    settings.BlipGoId,
    settings.BlipGoIdHmg,
    isHomologation,
    apiService,
    fetchPluginFromPack,
    getLastInstallationAndDetails,
    getPackName,
    getSpecificInstallationAndDetails,
    profile?.email,
    urlParams,
  ]);

  const getIsFreePlan = useCallback((planName: string) => planName == PlanNames.standard, []);

  const getDeskModule = useCallback((): Module => {
    return {
      id: ModuleIds.desk,
      title: 'Atendimento',
      tooltipText: 'Atendimento',
      iconName: 'agent',
      url: isHomologation ? ExternalUrls.deskHmg : ExternalUrls.deskPrd,
      isIframe: true,
      isInternalModule: true,
    };
  }, [isHomologation]);

  const getWhatsAppProfileEditModule = useCallback((): Module => {
    return {
      id: ModuleIds.whatsAppProfile,
      title: 'Perfil WhatsApp Business',
      tooltipText: 'Perfil WhatsApp Business',
      iconName: 'avatar-user',
      isIframe: false,
      isInternalModule: true,
    };
  }, []);

  const getConfigModule = useCallback((): Module => {
    return {
      id: ModuleIds.configuration,
      title: 'Configurações',
      tooltipText: 'Configurações',
      iconName: 'settings-general',
      isIframe: false,
    };
  }, []);

  const getChannelsModule = useCallback((): Module => {
    return {
      id: ModuleIds.channels,
      title: 'Ativar canais',
      tooltipText: 'Ativar canais',
      iconName: 'channels',
      isIframe: false,
      isInternalModule: true,
    };
  }, []);

  const getDefaultWhatsAppModules = useCallback(
    (isOwner: boolean, isFreePlan: boolean): Module[] => {
      const modules: Module[] = [];

      if (isOwner && !isFreePlan) {
        modules.push(getWhatsAppProfileEditModule());
        modules.push(getChannelsModule());
      }

      return modules;
    },
    [getWhatsAppProfileEditModule, getChannelsModule],
  );

  const getDefaultModules = useCallback(
    (isOwner: boolean, isFreePlan: boolean): Module[] => {
      let modules: Module[] = [getDeskModule()];

      if (!isOwner) {
        return modules;
      }

      modules = modules.concat(getDefaultWhatsAppModules(isOwner, isFreePlan));
      modules.push(getConfigModule());
      return modules;
    },
    [getDeskModule, getDefaultWhatsAppModules, getConfigModule],
  );

  const getGoPackModules = useCallback(
    (isOwner: boolean, isFreePlan: boolean, appData: string | undefined) => {
      const modules: Module[] = [
        {
          id: ModuleIds.blipGoHome,
          title: 'Começo',
          tooltipText: 'Começo',
          iconName: 'home',
        },
        {
          ...getDeskModule(),
          title: 'Central de atendimento',
          tooltipText: 'Central de atendimento',
          iconName: 'builder-text-message',
        },
      ];

      const helpModule: Module = {
        id: ModuleIds.blipGoHelp,
        title: 'Central de Ajuda',
        tooltipText: 'Central de Ajuda',
        iconName: 'question',
        url: ExternalUrls.blipGoHelp,
        isIframe: false,
        isExternalLink: true,
      };

      if (!appData || !isOwner) {
        modules.push(helpModule);
        return modules;
      }

      if (profile.email?.includes('@blip.ai') || profile.email?.includes('@take.net')) {
        modules.push(
          {
            id: ModuleIds.blipGoBot,
            title: 'Bot',
            tooltipText: 'Bot',
            iconName: 'robot',
          },
          {
            id: ModuleIds.blipGoConfig,
            title: 'Atendimento Humano',
            tooltipText: 'Atendimento Humano',
            iconName: 'agent',
          },
          {
            id: ModuleIds.blipGoChannels,
            title: 'Canais de atendimento',
            tooltipText: 'Canais de atendimento',
            iconName: 'channels',
          },
          {
            id: ModuleIds.blipGoActiveMessage,
            title: 'Mensagens Ativas',
            tooltipText: 'Mensagens Ativas',
            iconName: 'builder-publish-bot',
            url: isHomologation ? ExternalUrls.activeCampaignHmg + appData : ExternalUrls.activeCampaignProd + appData,
            isIframe: true,
            isInternalModule: true,
          },

          helpModule,
        );
      } else {
        modules.push(
          {
            id: ModuleIds.blipGoBot,
            title: 'Bot',
            tooltipText: 'Bot',
            iconName: 'robot',
          },
          {
            id: ModuleIds.blipGoConfig,
            title: 'Atendimento Humano',
            tooltipText: 'Atendimento Humano',
            iconName: 'agent',
          },
          {
            id: ModuleIds.blipGoChannels,
            title: 'Canais de atendimento',
            tooltipText: 'Canais de atendimento',
            iconName: 'channels',
          },
          helpModule,
        );
      }

      return modules;
    },
    [getDeskModule],
  );

  const getModules = useCallback(
    (pack: Pack, isOwner: boolean) => {
      const isFreePlan = getIsFreePlan(pack.tenant.name ?? '');
      let encodedAppData: string | undefined;

      if (isOwner) {
        const appData: ModuleAppData = {
          accountToken: accessToken,
          profile: profile,
          routerData: pack.routerData,
          tenantId: pack.tenant.id,
        };

        appData.profile.isOwner = isOwner;

        encodedAppData = base64.encode(JSON.stringify(appData));
      }

      return pack.name === PackNames.go
        ? getGoPackModules(isOwner, isFreePlan, encodedAppData)
        : getDefaultModules(isOwner, isFreePlan);
    },
    [accessToken, profile, getGoPackModules, getDefaultModules, getIsFreePlan],
  );

  const redirectToSelfOnboarding = async () => {
    await auth.signOutRedirect();
    await auth.userManager.clearStaleState();

    window.location.assign(settings.SelfOnboardingUrl);
  };

  return { fetchPackData, fetchPluginFromPack, getPackName, getIsFreePlan, getModules };
};
