import React, { ReactNode } from 'react'

interface IGrid extends Partial<Omit<HTMLBdsGridElement, 'children' | 'style'>> {
  children?: ReactNode;
  style?: React.CSSProperties;
}

const Grid = (props: IGrid) => {
  return (
    <bds-grid
      containerFluid
      xxs={props.xxs || '12'}
      {...props as any}
    >
      {props.children}
    </bds-grid>
  );
}

export default Grid