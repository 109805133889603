import { ILogArgs, logger } from 'packs-template-baseweb';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { ApiService } from 'services/Api';
import { Configuration } from 'typings/Configuration';
import { Profile } from 'typings/OIDCProfile';
import { UserData } from 'typings/UserData';

export type PackManagerContextData = {
  profile: Profile;
  accessToken: string;
  apiService: ApiService;
  settings: Configuration;
};

const logArgs: ILogArgs = {
  className: "PackManagerContext"
}

export const PackManagerContext = createContext<PackManagerContextData>({} as PackManagerContextData);

export const PackManagerProvider: React.FC<UserData> = ({ children, access_token, profile, settingsSource }) => {
  const [settings, setSettings] = useState({} as Configuration);
  const [initialized, setInitialized] = useState(false);

  const apiService = useMemo(
    () => new ApiService(settings.ApiServer, settings.ApiPort, access_token),
    [access_token, settings.ApiPort, settings.ApiServer],
  );

  useEffect(() => {
    if (settingsSource) {
      setSettings(settingsSource);
      setInitialized(true);
    }
  }, [settingsSource]);

  return initialized ? (
    <>
      <PackManagerContext.Provider
        value={{
          profile,
          accessToken: access_token,
          apiService,
          settings,
        }}
      >
        {children}
      </PackManagerContext.Provider>
    </>
  ) : (
    <></>
  );
};

export function usePackManagerContext(): PackManagerContextData {
  logArgs.methodName = "usePackManagerContext";

  const context = React.useContext(PackManagerContext);

  if (!context) {
    logger.fatal(`Error on create PackManagerContext`, logArgs);

    throw new Error('use app context must be used within an AppProvider');
  }

  return context;
}
