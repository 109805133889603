import { BlipTo } from '../constants/Commands/BlipTo';
import { BlipMethod } from '../constants/Commands/BlipMethod';
import { BlipType } from '../constants/Commands/BlipType';
import { v4 as uuidv4 } from 'uuid';

export class BlipCommandBaseRequest {
  id: string = uuidv4();
  method: string;
  uri: string;
  type?: string;
  to?: string;
  from?: string;
  pp?: string;

  public constructor(
    uri: string,
    method: keyof typeof BlipMethod,
    to?: keyof typeof BlipTo,
    type?: keyof typeof BlipType,
    from?: string,
    pp?: string,
  ) {
    this.uri = uri;
    this.method = BlipMethod[method];

    if (type !== undefined) this.type = BlipType[type!];

    if (to !== undefined) this.to = BlipTo[to!];

    if (from !== undefined) this.from = from;

    if (pp !== undefined) this.pp = pp;
  }
}
