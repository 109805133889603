export const PACK_MANAGER_ROUTE = '/pack-manager';
export const BOT_ROUTE = 'bot';
export const CHANNELS_ROUTE = 'canais';
export const HUMAN_SERVICE_ROUTE = 'atendimento-humano';
export const DESK_ROUTER = 'central-de-atendimento';
export const NUMBER_PREPARATION = 'preparar-numero';
export const PROCESSING_UPGRADE = 'processando-upgrade';
export const META_CONNECTION = 'conectando-meta';
export const CONTRACT_INFORMATION = 'dados-empresa';
export const ACTIVE_MESSAGE = 'mensagens-ativas';
