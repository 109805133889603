import Guid from 'guid';
import { Requests } from 'services/requests/Requests';
import { Resource } from 'types/Resource';

const saveResourceData = async (request: Requests, resources: Resource[], isFatal: boolean = true) => {
  const guid = Guid.raw();
  const responses = [];
  for (const res of resources) {
    const response = await request.setResource(res.value, res.name, guid, isFatal);
    responses.push({ [res.name]: response });
  }
  return responses;
};

export default saveResourceData;
