import React from 'react';

import { AlertChanges, FooterChanges, LeftContainer, RightContainer, SingleButtonContainer } from './styles';
import { LoadingContainerSubmitButton } from 'styles/tabs.styles';

import { useAppTabsContext } from 'contexts/TabsContext';
import { useAuthContext } from 'contexts/AuthContext';

import { saveFAQs } from 'services/save/saveFAQs';
import { saveGreetings } from 'services/save/saveGreetings';

import tabsTexts from 'texts/tabsTexts.json';

import { SendTrackProps } from 'types/botMolule/SendTrackProps';
import { FAQField } from 'types/botMolule/FAQField';

import Button from 'components/Button';
import { Resource } from 'types/Resource';
import { mountKey } from 'utils/mountKey';

import { TEXT_TYPE } from 'constants/Application';
import { saveCallButton } from 'services/save/saveCallButton';
import ButtonFlowBot from 'components/ButtonFlowBot';

type ButtonSubmitProps = {
  isModified?: boolean;
  style?: React.CSSProperties;
};

export const SubmitButton = ({ isModified, style }: ButtonSubmitProps): JSX.Element => {
  const { userData, routerData } = useAuthContext();
  const { resources, setResources } = useAppTabsContext();

  const {
    previousTab,
    returnMessage,
    firstContactMessage,
    greetings,
    setGreetings,
    queueList,
    hasFaq,
    faqList,
    FAQ,
    setFAQ,
    twentyFourHours,
    buttonMessage,
    setCallButton,
    callButton,
    research,
  } = useAppTabsContext();

  const tabsIsLoading = () => {
    return (greetings && greetings.isLoading) || (FAQ && FAQ.isLoading) || (callButton && callButton.isLoading);
  };

  const tabsIsInvalid = () => {
    switch (previousTab) {
      case tabsTexts.customizableMenu.name:
        return FAQ && FAQ.isInvalid;
      case tabsTexts.callButton.name:
        return callButton && callButton.isInvalid;
      default:
        return greetings && greetings.isInvalid;
    }
  };

  const configTrackingSave = (): SendTrackProps => {
    return {
      merchantName: userData.name,
      numQueue: queueList.length,
      numQuestions: faqList?.length || 0,
      service24h: twentyFourHours,
      statusskill: `pesquisa satisfacao ${research}`,
    };
  };

  const handleClickButton = async () => {
    const routerKey =
      routerData.shortName && routerData.accessKey ? mountKey(routerData.shortName, routerData.accessKey) : undefined;

    switch (previousTab) {
      case tabsTexts.callButton.name:
        setCallButton(current => {
          return {
            ...current,
            isLoading: true,
            isOpenModalSuccess: false,
          };
        });
        saveCallButton({
          routerData,
          trackConfig: configTrackingSave(),
          keyRouter: routerKey || '',
          setCallButton,
          messages: [buttonMessage || ''],
        }).then(() => {
          setCallButton(current => {
            return {
              ...current,
              isLoading: false,
            };
          });
          updateResources(tabsTexts.callButton.name);
        });
        setCallButton(callButton => ({ ...callButton, isModified: false }));
        break;
      case tabsTexts.saudation.name:
        setGreetings(current => {
          return {
            ...current,
            isLoading: true,
            isOpenModalSuccess: false,
          };
        });

        saveGreetings({
          routerData,
          trackConfig: configTrackingSave(),
          keyRouter: routerKey || '',
          setGreetings,
          messages: [returnMessage || '', firstContactMessage || ''],
        }).then(() => {
          setGreetings(current => {
            return {
              ...current,
              isLoading: false,
            };
          });
          updateResources(tabsTexts.saudation.name);
        });
        setGreetings(greetings => ({ ...greetings, isModified: false }));
        break;
      case tabsTexts.customizableMenu.name: {
        setFAQ(current => {
          return {
            ...current,
            isLoading: true,
            isOpenModalSuccess: false,
          };
        });

        const oldFAQsResources = resources.filter(resource => {
          if (/[1-5]\.\s\w+/.test(resource.name)) {
            return resource;
          }

          return [];
        });

        const oldFAQs: FAQField[] = (oldFAQsResources as Resource[]).map(oldFAQsResource => {
          return {
            topic: oldFAQsResource.name.slice(3),
            answer: oldFAQsResource.value,
          };
        });

        saveFAQs({
          hasFaq: hasFaq.toString(),
          newFAQs: faqList || [],
          oldFAQs,
          routerAuth: mountKey(routerData.shortName, routerData.accessKey),
          routerData,
          trackConfig: configTrackingSave(),
          setFAQ,
        }).then(() => {
          setFAQ(current => {
            return {
              ...current,
              isLoading: false,
            };
          });

          updateResources(tabsTexts.customizableMenu.name);
        });
        setFAQ(FAQ => ({ ...FAQ, isModified: false }));
      }
    }
  };

  const updateResources = (tab: string) => {
    switch (tab) {
      case tabsTexts.saudation.name:
        setResources([
          {
            name: 'returnMessage',
            value: returnMessage || '',
            type: TEXT_TYPE,
          },
          {
            name: 'firstContactMessage',
            value: firstContactMessage || '',
            type: TEXT_TYPE,
          },
        ]);
        break;
      case tabsTexts.callButton.name:
        setResources([
          {
            name: 'buttonMessage',
            value: buttonMessage || '',
            type: TEXT_TYPE,
          },
        ]);
        break;
      case tabsTexts.customizableMenu.name:
        let newResources: Resource[] = [];

        newResources =
          faqList?.map((faq, i) => {
            const name = `${i + 1}. ${faq.topic}`;
            return {
              name,
              type: TEXT_TYPE,
              value: faq.answer,
            };
          }) || [];

        newResources.push({
          name: 'semFaq',
          value: hasFaq.toString(),
          type: TEXT_TYPE,
        });

        setResources(newResources);
        break;
    }
  };

  return (
    <FooterChanges>
      <LeftContainer>
        <ButtonFlowBot />
      </LeftContainer>

      <RightContainer>
        {isModified && !tabsIsLoading() && (
          <AlertChanges>
            <bds-icon type="icon" name="message-active"></bds-icon>
            <bds-typo variant="fs-14" tag="p" italic>
              &nbsp;Existem alterações a serem salvas.&nbsp;
            </bds-typo>
          </AlertChanges>
        )}

        <SingleButtonContainer>
          {tabsIsLoading() ? (
            <LoadingContainerSubmitButton>
              <bds-loading-spinner color="main" size="small" />
            </LoadingContainerSubmitButton>
          ) : (
            <Button
              text="Salvar alterações"
              isFAQ={false}
              isAdd={false}
              onClick={handleClickButton}
              isSubmit={false}
              isDisabled={tabsIsInvalid()}
              style={style}
              icon="check"
            />
          )}
        </SingleButtonContainer>
      </RightContainer>
    </FooterChanges>
  );
};
