import { FAQField } from '../../types/botMolule/FAQField';
import { Resource } from '../../types/Resource';

interface ResetFAQsProps {
  resources: Resource[];
  setFaqList: React.Dispatch<React.SetStateAction<FAQField[] | undefined>>;
}

export const resetFAQs = ({ resources, setFaqList }: ResetFAQsProps) => {
  setFaqList([]);

  resources.forEach(resource => {
    const { name, value } = resource;

    if (/[1-5]\.\s\w+/.test(name)) {
      setFaqList(current =>
        current
          ? [
              ...current,
              {
                answer: value,
                topic: name.slice(3),
              },
            ]
          : [
              {
                answer: value,
                topic: name.slice(3),
              },
            ],
      );
    }
  });
};
