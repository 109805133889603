export interface IContractField {
  title: string;
  fieldName: string;
}

export const CONTRACT_FIELDS = [
  {
    title: 'Razão Social*',
    fieldName: 'corporateName',
    error: '',
  },
  {
    title: 'Nome Fantasia*',
    fieldName: 'companyName',
    error: '',
  },
  {
    title: 'Endereço da empresa*',
    fieldName: 'companyAddress',
    error: '',
  },

];
export const PROCURATOR = [
  {
    title: 'Nome completo do representante legal*',
    fieldName: 'procuratorName',
    error: '',
  },
  {
    title: 'CPF do representante legal*',
    fieldName: 'procuratorCPF',
    error: '',
  },
  {
    title: 'E-mail do representante legal*',
    fieldName: 'procuratorEmail',
    error: '',
  },
];
