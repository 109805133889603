import { useRef } from 'react';
import { BdsInput } from 'blip-ds/dist/blip-ds-react/components';
import { InputType } from 'blip-ds/dist/types/components/input/input-interface';

type InputTextProps = {
  onChange: (e: Event) => void;
  onFocus?: (e: Event) => void;
  onBlur?: (e: Event) => void;
  value?: any;
  placeholder?: string;
  isTextArea?: boolean;
  rows?: number;
  label?: string;
  name?: string;
  type?: InputType;
  disabled?: boolean;
  pattern?: string;
  maxLength?: number;
  danger?: boolean;
  errorMessage?: string;
  required?: boolean;
  requiredErrorMessage?: string;
  helperMessage?: string;
};

export const InputText = ({
  onChange,
  onFocus,
  onBlur,
  value,
  placeholder,
  isTextArea,
  rows,
  label,
  name,
  type,
  disabled,
  pattern,
  maxLength,
  danger,
  errorMessage,
  required,
  requiredErrorMessage,
  helperMessage,
}: InputTextProps) => {
  const inputRf = useRef<null | HTMLBdsInputElement>(null);

  return (
    <BdsInput
      inputName={name}
      pattern={pattern}
      ref={inputRf}
      value={value}
      placeholder={placeholder}
      is-textarea={isTextArea}
      onBdsChange={event => {
        onChange(event);
      }}
      rows={rows}
      label={label}
      type={type}
      disabled={disabled}
      maxlength={maxLength}
      danger={danger}
      error-message={errorMessage}
      required={required}
      required-error-message={requiredErrorMessage}
      data-testId="input-text"
      onBdsFocus={onFocus}
      onBdsOnBlur={onBlur}
      helper-message={helperMessage}
    />
  );
};
