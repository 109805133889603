import InputText from '../InputText';
import { MainContainer } from './styles';

type InputMessageEditableProps = {
  onChange: (e: Event) => void;
  onClick?: () => void;
  onFocus?: (e: Event) => void;
  onBlur?: (e: Event) => void;
  disabled: boolean;
  message: string;
  label: string;
  rows: number;
  maxLength?: number;
  danger?: boolean;
  errorMessage?: string;
  required?: boolean;
  requiredErrorMessage?: string;
  placeholder?: string;
  name?: string;
  helperMessage?: string;
};

export const InputMessageEditable = ({
  onChange,
  onFocus,
  onBlur,
  message,
  disabled,
  label,
  rows,
  maxLength,
  danger,
  errorMessage,
  required,
  requiredErrorMessage,
  placeholder,
  name,
  helperMessage
}: InputMessageEditableProps) => {
  return (
    <MainContainer>
      <InputText
        name={name}
        value={message}
        onChange={onChange}
        isTextArea
        rows={rows}
        label={label}
        disabled={disabled}
        maxLength={maxLength}
        danger={danger}
        errorMessage={errorMessage}
        required={required}
        requiredErrorMessage={requiredErrorMessage}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        helperMessage={helperMessage}
      />
    </MainContainer>
  );
};
