import { useHumanServiceContext } from 'contexts/HumanServiceContext';

import { TextContainer } from '../styles';

import tabsTexts from 'texts/tabsTexts.json';
import Switch from 'components/Switch';

import { TabsPanelContainer } from 'styles/tabs.styles';
import SubmitButton from '../components/SubmitButton';
import AlertsButtonCancel from '../components/AlertsButtonCancel';
import { useState } from 'react';
import Grid from 'components/Grid';
import TabGridContainer from 'components/TabGridContainer';
import SatisfactionSurveySimulator from '../components/Simulators/SatisfactionSurveySimulator';

export const SatisfactionSurvey = ({ }) => {
  const { satisfactionSurvey, setSatisfactionSurvey } = useHumanServiceContext();
  const [cancelWarning, setCancelWarning] = useState(false);

  const handleSetSatisfactionSurvey = async (event: Event) => {
    const input = event.currentTarget as HTMLBdsSwitchElement | null;
    const value = await input?.getValue();

    setSatisfactionSurvey(value as boolean);
  };

  return (
    <TabGridContainer>
      <Grid xxs="12" md="8" lg="9" flex-wrap="wrap" direction="row">
        <TabsPanelContainer>
          <TextContainer>
            <bds-typo variant="fs-16" bold="semi-bold" tag="p">
              {tabsTexts.satisfactionSurvey.subTitle}
            </bds-typo>

            <bds-typo variant="fs-16" tag="p">
              Inclua uma pesquisa no final do atendimento humano para avaliar a satisfação dos seus clientes e ter dados
              para evoluir a sua oferta de serviço ou produto.
            </bds-typo>
          </TextContainer>

          <Grid xxs="12" md="12" style={{ paddingLeft: 0 }}>
            <Switch
              text={tabsTexts.satisfactionSurvey.button}
              name="satisfactionSurvey"
              isEmojiSwitch={false}
              checked={satisfactionSurvey}
              onClick={handleSetSatisfactionSurvey}
            />
          </Grid>
        </TabsPanelContainer>
        <SubmitButton cancelWarning={cancelWarning} setCancelWarning={setCancelWarning} />
        <AlertsButtonCancel cancelWarning={cancelWarning} setCancelWarning={setCancelWarning} />
      </Grid>

      <Grid md="3" style={{ width: 'auto' }}>
        <SatisfactionSurveySimulator />
      </Grid>
    </TabGridContainer>
  );
};
