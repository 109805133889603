import React from 'react';
import {
    CTAMessage,
    CTABodyMessage,
} from './style';

const NO_OPACITY = 1;
const MEDIUM_OPACITY = 0.5;

type ICTAContentProps = {
    testid?: string;
    message: string;
    disable?: boolean;
};

const CTAContent: React.FC<ICTAContentProps> = ({
    testid,
    message,
    disable = false,
}) => {
    return (
        <CTAMessage data-testid={testid} style={{ opacity: disable ? MEDIUM_OPACITY : NO_OPACITY }}>
            <CTABodyMessage>
                <p>
                    {message}
                </p>
            </CTABodyMessage>
        </CTAMessage>
    );
};

export default CTAContent;