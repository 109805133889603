/* eslint-disable max-len */
/* eslint-disable quotes */
export const WarningConstants = {
  ATTENDANCEHOURSWARNING: 'Preencha os campos início, fim e dias de feriados dos itens que ativou.',
  GREETINGSWARNING: 'Não é possível deixar nenhum campo em branco.',
  ATTENDANCEQUEUEWARNING:
    'Coloque o nome de todas as filas que criou e insira pelo menos 1 e-mail em cada uma delas. Verifique também se o campo da mensagem antes das filas está preenchido.',
  PRESERVICEQUESTIONWARNING: 'Insira uma pergunta de pré-atendimento para cada fila criada.',
  FAQWARNING: 'Preencha os campos "Pergunta" e "Resposta" de cada dúvida frequente que você adicionou.',
};
