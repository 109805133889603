import styled from 'styled-components';
import { BdsTypo } from 'blip-ds/dist/blip-ds-react';

export const Container = styled.div`
  background-color: #141414;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  width: 100vw;
  height: 56px;

  bds-icon {
    color: white;
  }
`;

export const LogoutLink = styled(BdsTypo)`
  color: blue;
  display: inline-block;
  margin-left: 6px;
`;
