import React from 'react';
import TipWppUser from 'assets/TipWppUser.svg';
import TwoStepWpp from 'assets/TwoStepWpp.svg';
import {
    UserBodyMessage,
    ConfirmationMessage,
    InformationsWhatsapp,
    TimeMessage,
    UserBalloonPoint,
    UserMessage,
    Img
} from './style';

const NO_OPACITY = 1;
const MEDIUM_OPACITY = 0.5;

type IUserContentProp = {
    testid?: string;
    time: string;
    message: string;
    disable?: boolean;
};

const UserContent: React.FC<IUserContentProp> = ({
    testid,
    time,
    message,
    disable = false,
}) => {
    return (
        <UserMessage data-testid={testid} style={{ opacity: disable ? MEDIUM_OPACITY : NO_OPACITY }}>
            <UserBalloonPoint src={TipWppUser} alt="User balloon point" />

            <UserBodyMessage>
                <p>{message}</p>

                <InformationsWhatsapp>
                    <TimeMessage>{time}</TimeMessage>
                    <ConfirmationMessage>
                        <Img src={TwoStepWpp} alt="Two step confirmation whatsapp" />
                    </ConfirmationMessage>
                </InformationsWhatsapp>

            </UserBodyMessage>
        </UserMessage>
    );
};

export default UserContent;