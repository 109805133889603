export function getUTMParametersFromLocation(): Record<string, string> | null {
  const urlParams = new URLSearchParams(location.search);
  const utmObject: Record<string, string> = {};

  if (urlParams.keys().next().done) {
    return null;
  }

  urlParams.forEach((value, key) => {
    const normalizedKey = key.toLowerCase();
    if (normalizedKey.includes('utm') || normalizedKey === 'si') {
      utmObject[normalizedKey] = value;
    }
  });

  return utmObject;
}