import { useCallback, useState, useEffect } from 'react';
import { useAppTabsContext } from 'contexts/TabsContext';
import tabsTexts from 'texts/tabsTexts.json';
import { MAX_FAQS } from 'constants/Config';

import { LoadingContainer } from 'pages/BotConfiguration/contents/styles';

import Button from 'components/Button';
import MenuField from 'pages/BotConfiguration/components/MenuField';
import AlertDelete from '../AlertDelete';
import { useAuthContext } from 'contexts/AuthContext';
import { ModuleBotConfigurationTracks } from 'services/trackingService/handleSendTrackings';
import { validateCustomizableMenu } from 'utils/validates/validateCustomizableMenu';

const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();

const MenuList = () => {
  const { faqList, setFaqList, setFAQ } = useAppTabsContext();
  const { userData } = useAuthContext();

  const [indexRemove, setIndexRemove] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isAlert, setIsAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (faqList?.length === 0) {
      setFaqList([{ topic: '', answer: '' }]);
    } else {
      setFaqList(faqList || [{ topic: '', answer: '' }]);
      setLoading(false);
    }
  }, [faqList, setFaqList, loading]);

  const handleAdd = useCallback(async () => {
    setIsModified(true);

    const menuList = [...(faqList || []), { topic: '', answer: '' }];
    setFaqList(menuList);

    await moduleBotConfigurationTracks.sendTrackClickButtonAddFAQ({
      merchantName: userData.Name,
      success: true,
      screenName: 'Menu',
      error: 'none',
    });

    setFAQ(FAQ => ({ ...FAQ, isModified: true }));
  }, [faqList, setFAQ, setFaqList, userData.Name]);

  const handleRemove = useCallback(() => {
    setIsModified(true);

    if (faqList) {
      const listMenu = [...faqList];
      const removedItem = listMenu.filter((a, index) => index !== indexRemove);
      setFaqList(removedItem);
    }

    setIsOpen(!isOpen);
    setFAQ(FAQ => ({ ...FAQ, isModified: true }));
  }, [faqList, indexRemove, isOpen, setFAQ, setFaqList]);

  const handleClickDeleteButton = useCallback(
    async (menuFieldIndex: number) => {
      setIsAlert(true);
      setIsOpen(true);

      setIndexRemove(menuFieldIndex);

      await moduleBotConfigurationTracks.sendTrackClickButtonDelete({
        merchantName: userData.Name,
        success: true,
        screenName: 'Menu',
        error: 'none',
        type: 'menu-topic',
      });
    },
    [userData.Name, setIndexRemove],
  );

  useEffect(() => {
    if (isModified) {
      setFAQ(current => {
        return {
          ...current,
          isModified,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModified]);

  useEffect(() => {
    setFAQ(current => {
      return {
        ...current,
        isInvalid: !validateCustomizableMenu(faqList || []),
      };
    });
  }, [faqList, setFAQ]);

  return (
    <bds-grid xxs="12" md="12" lg="12" flex-wrap="wrap" direction="row" style={{ paddingLeft: 0 }}>
      {loading && (
        <LoadingContainer>
          <bds-loading-spinner color="main" size="small" />
        </LoadingContainer>
      )}

      <bds-grid xxs="12" md="12" lg="12" flex-wrap="wrap" direction="row" style={{ paddingLeft: 0 }}>
        {faqList &&
          faqList.map((menuField, indexMenu) => {
            return (
              <bds-grid xxs="12" md="6" key={indexMenu} style={{ paddingLeft: 0, paddingRight: 16 }}>
                <MenuField
                  menuField={menuField}
                  quantityFields={faqList?.length}
                  faqList={faqList}
                  menuFieldIndex={indexMenu}
                  handleClickDeleteButton={() => handleClickDeleteButton(indexMenu)}
                />
              </bds-grid>
            );
          })}
      </bds-grid>

      {faqList && faqList?.length < MAX_FAQS && (
        <bds-grid xxs="12" sm="12" md="12" justifyContent="flex-start" style={{ paddingLeft: 0, paddingRight: 16 }}>
          <Button isAdd isFAQ text={tabsTexts.customizableMenu.buttonAddOption} onClick={handleAdd} />
        </bds-grid>
      )}

      {isAlert && (
        <AlertDelete
          handleRemove={handleRemove}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          merchantName={userData.Name}
          screenName="Menu"
          type="menu-topic"
        />
      )}
    </bds-grid>
  );
};

export default MenuList;
