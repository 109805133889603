import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Guid from 'guid';

import { LoadingContainer, MainContainer } from 'styles/tabs.styles';
import { BdsTabGroup, BdsTabItem } from 'blip-ds/dist/blip-ds-react/components';

import { useAuthContext } from 'contexts/AuthContext';
import { useAppTabsContext } from 'contexts/TabsContext';

import GridContent from 'components/GridContent';
import { resetFAQs } from 'services/reset/resetFAQs';
import { resetGreetings } from 'services/reset/resetGreetings';
import { resetCallButton } from 'services/reset/resetCallButton';
import { api } from 'services/requests/Api';
import { Requests } from 'services/requests/Requests';
import { getAttendanceQueues } from 'services/getAttendanceQueues';
import { mountKey } from 'utils/mountKey';
import { validateGreetings } from 'utils/validates/validateGreetings';
import { validateCallButton } from 'utils/validates/validateCallButton';
import tabsTexts from 'texts/tabsTexts.json';
import { RESOURCES } from 'constants/ResourcesNames';

import { Menu } from 'pages/BotConfiguration/contents/Menu';
import { CallButton } from 'pages/BotConfiguration/contents/CallButton';
import { Saudation } from 'pages/BotConfiguration/contents/Saudation';

import { ModuleBotConfigurationTracks } from 'services/trackingService/handleSendTrackings';

export const Tabs = () => {
  const { userData, routerData } = useAuthContext();
  const {
    setPreviousTab,
    returnMessage,
    setReturnMessage,
    firstContactMessage,
    setFirstContactMessage,
    setGreetings,
    setQueueList,
    setFaqList,
    setTwentyFourHours,
    buttonMessage,
    setButtonMessage,
    setCallButton,
    setResearch,
    setHasFaq,
    resources,
    setResources,
    faqList,
  } = useAppTabsContext();

  const [request, setRequest] = useState<Requests | undefined>();
  const [requestRouterResources, setRequestWithResources] = useState<Requests | undefined>();
  const [loadingResources, setLoadingResources] = useState(true);
  const [currentTab, setCurrentTab] = useState(tabsTexts.saudation.name);

  useEffect(() => {
    const transhipmentKey =
      routerData.skillTransbordo && routerData.skillTransbordo.accessKey && routerData.skillTransbordo.shortName
        ? mountKey(routerData.skillTransbordo.shortName, routerData.skillTransbordo.accessKey)
        : undefined;

    const routerKey =
      routerData.shortName && routerData.accessKey ? mountKey(routerData.shortName, routerData.accessKey) : undefined;

    const request = transhipmentKey
      ? new Requests(api(transhipmentKey))
      : undefined;
    setRequest(request);

    const requestRouterResources = routerKey ? new Requests(api(routerKey)) : undefined;
    setRequestWithResources(requestRouterResources);
  }, [routerData]);

  useEffect(() => {
    if (currentTab === tabsTexts.saudation.name) {
      setGreetings(current => {
        return {
          ...current,
          isInvalid: !validateGreetings(returnMessage || '', firstContactMessage || ''),
        };
      });
    }
  }, [returnMessage, firstContactMessage, setGreetings, currentTab]);

  useEffect(() => {
    if (currentTab === tabsTexts.callButton.name) {
      setCallButton(current => {
        return {
          ...current,
          isInvalid: !validateCallButton(buttonMessage || ''),
        };
      });
    }
  }, [buttonMessage, setCallButton, currentTab]);

  const searchResources = useCallback(
    async (variablesResource: string[]) => {
      const resourcesFromBot = [];

      for (const resource of variablesResource) {
        const resourceValue = await requestRouterResources?.getSpecificResource(Guid.raw(), resource);
        const newResource = {
          name: decodeURIComponent(resource),
          value: resourceValue.resource,
          type: resourceValue.type,
        };
        resourcesFromBot.push(newResource);
      }
      setResources(resourcesFromBot);
      setLoadingResources(false);
    },
    [requestRouterResources, setResources],
  );

  const getAndSetResources = useCallback(
    async (step: string) => {
      switch (step) {
        case tabsTexts.saudation.name:
          searchResources([
            RESOURCES.MESSAGES.FIRST_CONTACT,
            RESOURCES.MESSAGES.RETURN,
            RESOURCES.TWENTY_FOUR_HOURS,
            RESOURCES.SATISFACTION_SURVEY,
          ]);

          const attendanceQueues = await getAttendanceQueues(request);
          setQueueList(attendanceQueues);
          setCurrentTab(tabsTexts.saudation.name);
          break;
        case tabsTexts.customizableMenu.name:
          const resources = await requestRouterResources?.getAllResources(Guid.raw());
          const faqListNames: string[] = [];

          resources?.resource?.items?.forEach((resource: string) => {
            if (/\d\.\s\w+/.test(resource)) {
              faqListNames.push(resource);
            }
          });

          searchResources([...faqListNames, RESOURCES.WITHOUT_FAQ]);
          setCurrentTab(tabsTexts.customizableMenu.name);
          break;
        case tabsTexts.callButton.name:
          searchResources([RESOURCES.MESSAGES.BUTTON_MESSAGE]);
          setCurrentTab(tabsTexts.callButton.name);
          break;
      }
    },
    [request, requestRouterResources, searchResources, setQueueList],
  );

  const setInitialInputValues = useCallback(() => {
    if (resources.length > 0) {
      if (currentTab === tabsTexts.saudation.name) {
        resetGreetings({
          resources,
          setReturnMessage,
          setFirstContactMessage,
        });
      }

      if (currentTab === tabsTexts.callButton.name) {
        resetCallButton({
          resources,
          setButtonMessage,
        });
      }

      if (currentTab === tabsTexts.customizableMenu.name) {
        resetFAQs({
          resources,
          setFaqList,
        });
      }

      resources.forEach(resource => {
        if (resource.name === 'openHour') {
          setTwentyFourHours(resource.value === 'true');
        }

        if (resource.name === RESOURCES.SATISFACTION_SURVEY) {
          setResearch(resource.value === 'true');
        }

        if (resource.name === 'semFaq') {
          setHasFaq(resource.value === 'true');
        }
      });
    }
  }, [
    currentTab,
    resources,
    setButtonMessage,
    setFaqList,
    setFirstContactMessage,
    setHasFaq,
    setResearch,
    setReturnMessage,
    setTwentyFourHours,
  ]);

  const handleLoadTabContent = useCallback(
    (label: string) => {
      if (label === tabsTexts.saudation.name && !firstContactMessage && !returnMessage) {
        getAndSetResources(label);
      }
      if (label === tabsTexts.customizableMenu.name && faqList === undefined) {
        getAndSetResources(label);
      }
      if (label === tabsTexts.callButton.name && !buttonMessage) {
        getAndSetResources(label);
      }
    },
    [buttonMessage, faqList, firstContactMessage, getAndSetResources, returnMessage],
  );

  useLayoutEffect(() => {
    if (requestRouterResources) {
      getAndSetResources(tabsTexts.saudation.name);
    }
  }, [getAndSetResources, requestRouterResources]);

  const sendTrackTab = async (merchantName: string, screenName: string) => {
    const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();
    await moduleBotConfigurationTracks.sendTrackTab({ merchantName, screenName });
  };

  useEffect(() => {
    if (requestRouterResources && !resources.length) {
      getAndSetResources(tabsTexts.saudation.name);
    }
  }, [getAndSetResources, requestRouterResources, userData, resources]);

  useEffect(() => {
    setInitialInputValues();
  }, [resources, setInitialInputValues]);

  const handleCurrentTab = (label: string, group: string) => {
    setPreviousTab(label);
    setCurrentTab(label);
    getAndSetResources(label);
    handleLoadTabContent(label);

    sendTrackTab(userData.fullName || userData.name, group);
  };

  const TabChangeEvent = (ev: CustomEvent) => {
    const element = ev.detail as HTMLBdsTabItemElement | null;
    const label = element?.label;

    switch (label) {
      case tabsTexts.saudation.name:
        handleCurrentTab(label, 'greetings');
        break;
      case tabsTexts.customizableMenu.name:
        handleCurrentTab(label, 'menu');
        break;
      case tabsTexts.callButton.name:
        handleCurrentTab(label, 'attendance-button');
        break;
    }
  };

  return (
    <MainContainer>
      {loadingResources ? (
        <LoadingContainer style={{ alignSelf: 'center' }}>
          <bds-loading-spinner color="main" size="small" />
        </LoadingContainer>
      ) : (
        <GridContent>
          <BdsTabGroup align="left" onBdsTabChange={ev => TabChangeEvent(ev)}>
            <BdsTabItem label="Saudação" id="greetings">
              <Saudation />
            </BdsTabItem>

            <BdsTabItem label="Menu" id="menu">
              <Menu />
            </BdsTabItem>

            <BdsTabItem label="Botão de atendimento" id="attendance-button">
              <CallButton />
            </BdsTabItem>
          </BdsTabGroup>
        </GridContent>
      )}
    </MainContainer>
  );
};
