import React from 'react';

interface IYoutubePlayer {
  videoUrl: string;
}

const YoutubePlayer = ({ videoUrl }: IYoutubePlayer) => {
  return (
    <iframe
      id="youtube-player"
      data-testid="youtube-player"
      width="100%"
      height="500px"
      src={videoUrl}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; 
              encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  );
};

export default YoutubePlayer;
