import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { HoursContainer, MainContainer } from './styles';

import tabsTexts from 'texts/tabsTexts.json';
import { Turn } from 'types/humanService/Turn';
import { Day } from 'types/humanService/Day';
import SelectHour from '../../SelectHour';

interface TurnHoursProps {
  i: number;
  turn: Turn;
  turns: Turn[];
  setDays: Dispatch<SetStateAction<Day>>;
  isDisabled: boolean;
  handleAddTurn: () => void;
  handleRemoveTurn: (i: number) => void;
}

export const TurnHours = ({ i, setDays, turns, isDisabled, handleAddTurn, handleRemoveTurn, turn }: TurnHoursProps) => {
  const list = useMemo(() => [...turns], [turns]);

  const [start, setStart] = useState(list[i].start);
  const [end, setEnd] = useState(list[i].end);

  useEffect(() => {
    list[i].start = start;
    list[i].end = end;
    setDays((current: Day) => {
      return {
        ...current,
        turns: list,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end, start]);

  const showRemoveIcon = turns.length - 1 === i && i > 0;
  return (
    <MainContainer index={i} showRemoveIcon={showRemoveIcon}>
      <HoursContainer>
        <SelectHour
          value={isDisabled ? '' : turn.start}
          setAttendance={setStart}
          disabled={isDisabled}
          text={tabsTexts.schedules.times.start}
          end={0}
        />

        <SelectHour
          value={isDisabled ? '' : turn.end}
          setAttendance={setEnd}
          disabled={isDisabled}
          text={tabsTexts.schedules.times.end}
          end={1}
        />
      </HoursContainer>

      {turns.length - 1 === i && turns.length < 3 && (
        <bds-tooltip position="left-center" tooltip-text={tabsTexts.schedules.button.add}>
          <bds-icon name="add" size="medium" onClick={handleAddTurn} />
        </bds-tooltip>
      )}

      {showRemoveIcon && (
        <bds-tooltip class="icon-remove" position="left-center" tooltip-text={tabsTexts.schedules.button.remove}>
          <bds-icon name="delete" size="medium" onClick={() => handleRemoveTurn(i)} />
        </bds-tooltip>
      )}
    </MainContainer>
  );
};
