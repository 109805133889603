import { ILogArgs, json, logger } from 'packs-template-baseweb';
import { api } from './Api';
import { INSTALLATION_URL, TENANTS_ID_URL } from './constants/Application';

const logArgs: ILogArgs = {
  className: 'ApplicationService',
};

async function getApplicationInstallation(accountToken: string, botShortName: string) {
  logArgs.methodName = "getApplicationInstallation";
  
  if (botShortName === undefined || botShortName === '' || botShortName === null)
    return { routerShortName: '', tenantId: '', applicationName: '', applicationId: '' };

  const client = api(accountToken);

  try {
    const response = await client.get(`${INSTALLATION_URL}=${botShortName}`);

    if (response.data.status === 'failure') {
      throw new Error(response.data);
    }

    return response.data;
  } catch (error) {
    logger.fatal(`Error on get pack installations: 
    'api': ${json.format(client)} | 
    'error': ${json.format(error)}`, logArgs);

    return { routerShortName: '', tenantId: '', applicationName: '', applicationId: '' };
  }
}

async function getPlan(accountToken: string, botShortName: string) {
  logArgs.methodName = "getPlan";

  try {
    const client = api(accountToken);
    const response = await client.get(`${TENANTS_ID_URL}/${botShortName}`);

    if (response.data.status === 'failure') {
      throw new Error(response.data);
    }

    return response.data;
  } catch (error) {
    logger.fatal(`Error on get application plan: ${json.format(error)}`, logArgs);
  }
}

export const ApplicationService = {
  getApplicationInstallation,
  getPlan,
};
