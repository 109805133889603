import styled, { css } from 'styled-components';

interface MainContainerProps {
    end: number;
}

export const MainContainer = styled.div<MainContainerProps>`
    display: flex;
    align-items: center;

    ${props =>
        props.end === 1 &&
        css`
            margin-left: 10px;
        `}

    bds-typo {
        margin-right: 10px;
    }
`;
