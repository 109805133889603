interface GetAllAttendanceQueuesProps {
    guid: string
}

export const GetAllAttendanceQueues = ({ guid }: GetAllAttendanceQueuesProps) => {
    return {
        id: guid,
        to: 'postmaster@desk.msging.net',
        method: 'get',
        uri: '/attendance-queues?$skip=0&$take=100',
        type: 'application/vnd.iris.desk.attendancequeue+json',
        resource: {},
    }
}
