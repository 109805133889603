import React, { FC, useEffect } from 'react';
import { ChannelsTracks } from 'pages/channels/services/trackings/handleSendTrackings';
import { useRef, useState } from 'react';
import ModalChannel from '../../modal';
import './styles.scss';
import { mountKey } from 'utils/mountKey';
import { useNavigate } from 'react-router-dom';
import { NUMBER_PREPARATION } from 'constants/Routes';
import { checkEmailRegistered } from 'services/experienceCluster/Api';
import { useAuth } from 'oidc-react';
import { createToastError } from 'services/Toast';
import WhatsappModal from '../../whatsappModal';
import { usePackContext } from 'contexts/PackContext';
import { ILogArgs, logger } from 'packs-template-baseweb';
import { ConfigurationService } from 'services/configurationService/ConfigurationService';
import { title } from 'process';

interface IItem {
  item: {
    title: string;
    photo: string;
    linkActive: boolean;
    link: string;
    imageModal: string;
  };
}

const Item: FC<IItem> = ({ item }) => {
  const channelsTracks = new ChannelsTracks();
  const auth = useAuth();
  const navigate = useNavigate();
  const modalRef = useRef<null | HTMLBdsModalElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { pack } = usePackContext();
  const [wppActive, setWppActive] = useState<boolean>();
  const screenTitle = true;

  const logArgs: ILogArgs = {
    className: 'Item',
  };
  async function redirect() {
    let wppFlowDestination = '';
    if (item) {
      const itemsWithModal = ['Instagram', 'Messenger'];
      if (item.title && item.link !== '') {
        if (itemsWithModal.find(ch => ch === item.title)) {
          if (modalRef.current) {
            modalRef.current.open = !modalRef.current.open;
          }
          channelsTracks.sendTrackShowModalActivateChannel({ channel: item.title });

          return;
        }

        if (item.title === 'WhatsApp') {
          if (auth?.userData?.profile?.email?.length) {
            setIsLoading(true);
            await checkEmailRegistered(auth.userData.profile.email).then(res => {
              setIsLoading(false);
              if (res.data.emailRegistered) {
                wppFlowDestination = 'meta-activation';
                navigate(NUMBER_PREPARATION);
              } else {
                if (modalRef.current) {
                  wppFlowDestination = 'blip-go-introduction';
                  modalRef.current.open = !modalRef.current.open;
                }
              }
            });
          }
        } else {
          window.open(item.link, '_blank');
        }
      } else {
        createToastError(`Falha ao obter o link do ${item.title}`, 'Por favor, tente daqui alguns minutos.');
      }

      channelsTracks.sendTrackClickButtonChannelsActivated({
        channel: item.title,
        destination: wppFlowDestination,
      });
    }
  }

  useEffect(() => {
    VerificationActiveWpp();
  }, []);

  function VerificationActiveWpp() {
    const key = mountKey(pack.routerData.shortName, pack.routerData.accessKey);
    ConfigurationService.GetInfoWppActiveAsync(key)
      .then(response => {
        logger.info('check if WhatsApp is active', logArgs);
        if (response.resource) {
          setWppActive(true);
        }
      })
      .catch(error => {
        logger.error(`Error check if WhatsApp is active: ${error}`, logArgs);
        setWppActive(false);
      });
  }

  function CardButton() {
    if (item.title === 'WhatsApp' && wppActive) {
      return (
        <bds-button type="button" variant="primary">
          Conectado
        </bds-button>
      );
    }
    return (
      <bds-button type="button" variant="ghost" onClick={redirect}>
        Ativar canal
      </bds-button>
    );
  }

  function redirectButtonChannelsTest() {
    if (item.link !== '') {
      window.open(item.link, '_blank');
      channelsTracks.sendTrackClickButtonChannelsTested({ channel: item.title });
    } else {
      createToastError(`Falha ao obter o link do ${item.title}`, 'Por favor, tente daqui alguns minutos.');
    }
  }

  function RenderModal() {
    if (item.title === 'Instagram' || item.title === 'Messenger') {
      return (
        <ModalChannel
          channel={item.title}
          activateUrl={item.link}
          renderModal={true}
          imageModal={item.imageModal}
          modalRef={modalRef}
        />
      );
    } else {
      return <></>;
    }
  }

  return (
    <div className="menu-boxes__item">
      <div className="menu-boxes__imagem">
        <img src={item.photo} alt={item.title} />
        <bds-typo>{item.title}</bds-typo>
      </div>

      {item.linkActive ? (
        <div className="menu-boxes__buttom">
          <bds-button type="button" onClick={() => redirectButtonChannelsTest()}>
            Testar canal
          </bds-button>
        </div>
      ) : (
        <div className="menu-boxes__buttom">
          <CardButton />
          {isLoading ? <bds-loading-page /> : ''}
          <WhatsappModal modalRef={modalRef} introductionTitle={screenTitle} />
          <RenderModal />
        </div>
      )}
    </div>
  );
};

export default Item;
