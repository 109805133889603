import styled from 'styled-components';

export const DesktopSidebar = styled.div`
  z-index: 999;
  background-color: #141414;
  height: 100vh;
  width: 64px;
  border-radius: 0px 16px 16px 0px;
  padding: 16px 4px;
  color: white;
  position: fixed;
  text-align: center;
  img {
    width: 40px;
  }
`;

export const ModulesSection = styled.div`
  position: absolute;
  top: 20%;
  left: 24%;
  bds-icon {
    cursor: pointer;
  }
`;

export const BottomConfigSection = styled.div`
  position: absolute;
  text-align: center;
  bottom: 1%;
  margin-left: 8px;
  bds-icon {
    cursor: pointer;
  }
`;
