import ModalSuccess from 'components/ModalSuccess';
import { Dispatch, SetStateAction } from 'react';
import { Tabs } from 'types/Tabs';

type AlertsTabProps = {
  attendanceHours: {
    isOpenAlertModify?: boolean | undefined;
    isOpenModalSuccess?: boolean | undefined;
  };
  attendanceQueue: {
    isOpenAlertModify?: boolean | undefined;
    isOpenModalSuccess?: boolean | undefined;
  };
  preServiceQuestion: {
    isOpenAlertModify?: boolean | undefined;
    isOpenModalSuccess?: boolean | undefined;
  };
  satisfactionSurvey: {
    isOpenAlertModify?: boolean | undefined;
    isOpenModalSuccess?: boolean | undefined;
  };
  setAttendanceHours: Dispatch<SetStateAction<Tabs>>;
  setAttendanceQueue: Dispatch<SetStateAction<Tabs>>;
  setPreServiceQuestion: Dispatch<SetStateAction<Tabs>>;
  setSatisfactionSurvey: Dispatch<SetStateAction<Tabs>>;
  handleReturnToPreviousTab: () => void;
};

export const AlertsTab = ({
  attendanceHours,
  attendanceQueue,
  preServiceQuestion,
  satisfactionSurvey,
  setAttendanceHours,
  setAttendanceQueue,
  setPreServiceQuestion,
  setSatisfactionSurvey,
}: AlertsTabProps) => {
  return (
    <>
      {attendanceHours && attendanceHours.isOpenModalSuccess && (
        <ModalSuccess
          setTabData={setAttendanceHours}
          open={attendanceHours.isOpenModalSuccess}
          text="O horário foi alterado com sucesso."
        />
      )}
      {attendanceQueue && attendanceQueue.isOpenModalSuccess && (
        <ModalSuccess
          setTabData={setAttendanceQueue}
          open={attendanceQueue.isOpenModalSuccess}
          text="Fila de atendimento foi alterada com sucesso.
					Atenção: Lembre-se de revisar também a pergunta pré-atendimento."
        />
      )}
      {preServiceQuestion && preServiceQuestion.isOpenModalSuccess && (
        <ModalSuccess
          setTabData={setPreServiceQuestion}
          open={preServiceQuestion.isOpenModalSuccess}
          text="O pré-atendimento foi alterado com sucesso."
        />
      )}
      {attendanceHours && satisfactionSurvey.isOpenModalSuccess && (
        <ModalSuccess
          setTabData={setSatisfactionSurvey}
          open={satisfactionSurvey.isOpenModalSuccess}
          text="A pesquisa de satisfação foi alterada com sucesso."
        />
      )}
    </>
  );
};
