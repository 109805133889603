import React, { useEffect } from 'react';
import whatsappTrial from '../../../../assets/WhatsappTrial.svg';
import './styles.scss';
import { ChannelsTracks } from 'pages/channels/services/trackings/handleSendTrackings';

export const TrialModal = (props: any) => {
  const channelsTracks = new ChannelsTracks();

  useEffect(() => {
    channelsTracks.sendTrackSevenDaysOpened();
  }, []);

  return (
    <div className="whatsapp-trial-container">
      <img className="whatsapp-trial-logo" src={whatsappTrial} />
      <bds-typo variant="fs-24" margin={false}>
        Ei, antes de sair, queremos oferecer
      </bds-typo>
      <bds-typo variant="fs-40" margin={false} class="free-days">
        7 dias de WhatsApp grátis
      </bds-typo>
      <bds-typo variant="fs-24" margin={false}>
        para testar.
      </bds-typo>
      <div className="whatsapp-trial-btn-container">
        <bds-button variant="ghost" onClick={props?.cancelEvent}>
          Talvez depois
        </bds-button>
        <bds-button arrow={true} onClick={props?.agreementEvent}>
          Legal, eu quero!
        </bds-button>
      </div>
    </div>
  );
};
