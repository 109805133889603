import { AnalyticsService as _analyticsService } from 'services/analyticsService/AnalyticsService';

type SendTrackProps = {
    success?: boolean;
    screenName?: string;
};

export class NumberPreparationTracks {
    sendTrackNumberPreparationOpen = async () => {
        _analyticsService.Track
            ('blippacks-management-general-channels-wpp-postsignature');
    };
    sendTrackHowDeleteNumber = async () => {
        _analyticsService.Track
            ('blippacks-management-general-channels-wpp-howdeleteaccount');
    }
    sendTrackDeletedAccount = async () => {
        _analyticsService.Track
            ('blippacks-management-general-channels-wpp-deleted-account');
    }
    sendTrackNewNumberRegistration = async () => {
        _analyticsService.Track
            ('blippacks-management-general-channels-wpp-newphone');
    }
    sendTrackReportProblem = async ({ screenName }:
        SendTrackProps) => {
        _analyticsService.Track
            ('blippacks-management-general-channels-wpp-reportproblem', {
                screenName
            });
    }

}
