import React, { useEffect, useState } from 'react';
import './styles.scss';
import { MainContainer } from 'styles/tabs.styles';
import Grid from 'components/Grid/Grid';
import { WhatsappActivationFooter } from 'components/WhatsappActivationFooter';
import useGraphApi from 'hooks/useGraphApi';
import ExternalUrls from 'constants/ExternalUrls';
import { LEARN_MORE_YTB_VIDEO } from './constants/urls';
import { MetaConnectiontTracks } from './trackings/MetaConnectionTracks';
import { WabaActivation } from 'pages/MetaConnection/components/WabaActivation';
import { ILogArgs, logger } from 'packs-template-baseweb';

export const MetaConnection = () => {
  const [showRetryPage, setShowRetryPage] = useState(false);
  const [metaUserToken, setMetaUserToken] = useState({});
  const [readyToConnectToWaba, setReadyToConnectToWaba] = useState(false);
  const { launchWhatsAppSignup, initFacebookSdk } = useGraphApi();
  const logArgs: ILogArgs = {
    className: 'MetaConnection',
  };

  useEffect(() => {
    MetaConnectiontTracks.sendTrackMetaOpenPage();
    initFacebookSdk();
  }, []);

  const handleRegisterBtn = () => {
    logArgs.methodName = "handleRegisterBtn";
    MetaConnectiontTracks.sendTrackMetaRegisterNow();
    setShowRetryPage(true);
    MetaConnectiontTracks.sendTrackMetaEmbeddedRedirect();
    openEmbeddedSignUp();
    logger.info('user successfully fullfiled open embedded signup', logArgs);
  };

  const handleReportProblemBtn = () => {
    logArgs.methodName = "handleReportProblemBtn";
    MetaConnectiontTracks.sendTrackMetaReportProblem({
      screenName: 'Como conectar?',
    });
    window.open(
      `${ExternalUrls.whatsappActivationChat}Gostaria de reportar um *problema* no *cadastramento na Meta*`,
      '_blank',
    );
    logger.info('open report problem', logArgs)
  };

  const handleLeanMoreLink = () => {
    logArgs.methodName = "handleLeanMoreLink";
    logger.info('open learn more link', logArgs)
    MetaConnectiontTracks.sendTrackMetaLearnMore();
    window.open(LEARN_MORE_YTB_VIDEO, '_blank');
  };

  const retryOpenEmbedded = () => {
    logArgs.methodName = "retryOpenEmbedded";
    logger.info('user successfully fullfiled open embedded signup', logArgs);
    MetaConnectiontTracks.sendTrackMetaRetryOpenEmbedded();
    openEmbeddedSignUp();
  };

  const openEmbeddedSignUp = () => {
    logArgs.methodName = "openEmbeddedSignUp";
    launchWhatsAppSignup(userToken => {
      setMetaUserToken(userToken);
      setReadyToConnectToWaba(true);
    });
    logger.info('redirecting to  open meta embedded sign up', logArgs);
  };

  const RenderFirstMetaConnection = () => {
    return showRetryPage ? (
      <div>
        <span>Se o facebook não abrir </span>
        <span className="meta-link" onClick={() => retryOpenEmbedded()}>
          clique aqui
        </span>
      </div>
    ) : (
      <Grid direction="column" gap="3" margin="t-1">
        <bds-typo variant="fs-24" margin={false}>
          Como conectar
        </bds-typo>
        <bds-typo variant="fs-14">
          Para utilizar a API do whatsapp você precisa fazer um <b>cadastro na Meta/facebook</b> da sua empresa e do
          número que deseja conectar.
        </bds-typo>
        <bds-button variant="primary" onClick={() => handleRegisterBtn()}>
          Cadastrar agora
        </bds-button>
        <div>
          <span className="meta-link" onClick={() => handleLeanMoreLink()}>
            Saiba mais
          </span>
          <span> sobre o cadastro no facebook</span>
        </div>
        <WhatsappActivationFooter linkPressed={() => handleReportProblemBtn()} linkTitle="Reportar problema" />
      </Grid>
    );
  };

  return (
    <MainContainer>
      {readyToConnectToWaba ? <WabaActivation metaUserToken={metaUserToken} /> : <RenderFirstMetaConnection />}
    </MainContainer>
  );
};
