import React, { useCallback, useEffect } from 'react';
import { Tabs } from 'types/Tabs';

type ModalSuccessProps = {
  text: string;
  open: boolean;
  setTabData: React.Dispatch<React.SetStateAction<Tabs>>;
};

export const ModalSuccess = ({ text, open }: ModalSuccessProps) => {
  const showToast = useCallback(async () => {
    const newtoast = document.createElement('bds-toast');
    document.body.appendChild(newtoast);

    const icon = 'like';
    const variant = 'success';
    const actionType = 'icon';
    const toastTitle = '';
    const toastText = text;
    const buttonText = undefined;
    const duration = 7;
    const buttonAction = undefined;
    const position = undefined;

    await newtoast.create({
      variant,
      actionType,
      toastTitle,
      buttonText,
      buttonAction,
      toastText,
      duration,
      icon,
      position,
    });
  }, [text]);

  useEffect(() => {
    if (open) {
      showToast();
    }
  }, [open, showToast]);

  return (<></>);
};
