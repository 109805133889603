import styled from 'styled-components';
import {
  ConfirmationMessage,
  InformationsWhatsapp,
  TimeMessage,
  Img
} from 'components/Simulator/style';

const UserMessage = styled.div`
  margin-rigth: 5px;
  width: auto;
  max-width: 65%;
  margin-top: 6px;
  margin-left: 94px;
  display: grid;
  justify-content: end;
`;

const UserBodyMessage = styled.div`
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.10);
  background: #E2FFC7;
  color: #040404;
  font-size: 14px;
  padding: 7px 10px;
  border-radius: var(--space-1, 8px);
`;

const UserBalloonPoint = styled.img`
  position: absolute;
  margin-left: 63%;
`;

export {
  UserMessage,
  UserBodyMessage,
  UserBalloonPoint,
  ConfirmationMessage,
  InformationsWhatsapp,
  TimeMessage,
  Img
};