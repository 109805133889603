import React from 'react';
/* eslint-disable @typescript-eslint/no-empty-function */
import { MainContainer } from './styles';

type ButtonProps = {
  onClick?: () => void;
  text: string;
  isSubmit?: boolean;
  isFAQ: boolean;
  isAdd: boolean;
  isCancel?: boolean;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  icon?: string;
};

export const Button = ({
  text,
  onClick,
  isSubmit,
  isFAQ,
  isAdd,
  isCancel,
  isDisabled,
  style,
  icon = '',
}: ButtonProps): JSX.Element => {
  return (
    <MainContainer isAdd={isAdd} isFAQ={isFAQ}>
      <bds-button
        variant={isAdd || isCancel ? 'ghost' : 'primary'}
        icon={isAdd ? 'add' : icon}
        onClick={isDisabled ? () => {} : onClick}
        arrow={isSubmit ? true : false}
        disabled={isDisabled}
        style={style ? style : {}}
      >
        {text}
      </bds-button>
    </MainContainer>
  );
};
