const RETURN_MESSAGE_COMPONENT = 'returnMessage';
const FIRST_CONTACT_MESSAGE = 'firstContactMessage';
const MENU_FIELD_TOPIC = 'menuFieldTopic';
const MENU_FIELD_ANSWER = 'menuFieldAnswer';

export {
    RETURN_MESSAGE_COMPONENT,
    FIRST_CONTACT_MESSAGE,
    MENU_FIELD_TOPIC,
    MENU_FIELD_ANSWER
}