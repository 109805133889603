import React, { useEffect } from 'react';

import { useAuthContext } from 'contexts/AuthContext';
import { ModuleBotConfigurationTracks } from 'services/trackingService/handleSendTrackings';

type WarningProps = {
  isOpen: boolean;
  handleIsOpen: () => void;
  text: string;
  screenName: string;
};

export const Warning = ({ isOpen, handleIsOpen, text, screenName }: WarningProps) => {
  const { userData, activationOption } = useAuthContext();

  const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();

  const handleSendTracking = async () => {
    moduleBotConfigurationTracks.sendTrackWarningOpen({
      activationOption,
      screenName,
      merchantName: userData.Name,
      msgWarning: text,
    });
  };

  useEffect(() => {
    if (isOpen) {
      handleSendTracking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <bds-alert open={isOpen}>
      <bds-alert-header variant="warning" icon="warning">
        <bds-typo variant="fs-16" bold="bold">
          Atenção!
        </bds-typo>
      </bds-alert-header>

      <bds-alert-body>
        <bds-typo variant="fs-14">{text}</bds-typo>
      </bds-alert-body>

      <bds-alert-actions>
        <bds-button onClick={handleIsOpen} variant="secondary">
          <bds-typo variant="fs-14" bold="bold">
            Ok
          </bds-typo>
        </bds-button>
      </bds-alert-actions>
    </bds-alert>
  );
};
