import InputText from 'components/InputText';
import { MainContainer } from './styles';
import { validateHour } from 'utils/validates/humanService/validateHours';
import { useEffect, useRef } from 'react';

type SelectProps = {
  disabled: boolean;
  setAttendance: (obj: string) => void;
  text: string;
  end: number;
  value: string;
};

export const SelectHour = ({ disabled, setAttendance, text, end, value }: SelectProps) => {
  const inputRf = useRef<null | HTMLBdsInputElement>(null);

  const handleChange = (ev: Event) => {
    const input = ev.target as HTMLSelectElement;
    const inputValue = input.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);
    if (inputValue !== null && inputValue !== undefined)
      input.value = !inputValue[2] ? inputValue[1] : `${inputValue[1]}:${inputValue[2]}`;

    setAttendance(input.value as string);

    ev.stopImmediatePropagation();
  };

  useEffect(() => {
    inputRf.current?.addEventListener('bdsChange', event => handleChange(event));
  }, []);

  return (
    <MainContainer end={end}>
      <bds-typo variant="fs-14">{text}</bds-typo>

      <bds-input
        ref={inputRf}
        value={value}
        disabled={disabled}
        required={true}
        danger={value !== '' && !validateHour(value)}
      />
    </MainContainer>
  );
};
