import { useAppTabsContext } from 'contexts/TabsContext';

import { RowSwitchContainer } from './styles';
import { TextContainer } from '../styles';
import { TabsPanelContainer } from 'styles/tabs.styles';

import MenuList from 'pages/BotConfiguration/components/MenuList';
import Switch from 'components/Switch';
import SubmitButton from 'pages/BotConfiguration/components/SubmitButton';
import Grid from 'components/Grid';
import TabGridContainer from 'components/TabGridContainer';
import MenuSimulator from './MenuSimulator';

export const Menu = () => {
  const { faqList, setFAQ, hasFaq, setHasFaq, checkIsModified } = useAppTabsContext();

  const handleSetHasFaq = async (event: Event) => {
    const input = event.currentTarget as HTMLBdsSwitchElement | null;
    const value = await input?.getValue();
    setFAQ(FAQ => ({ ...FAQ, isModified: true, isInvalid: false }));

    setHasFaq(value as boolean);
  };

  return (
    <TabGridContainer>
      <Grid xxs="12" md="8" lg="9" flex-wrap="wrap" direction="row">
        <TabsPanelContainer>
          <TextContainer>
            <bds-typo variant="fs-16" tag="p" line-height="plus">
              Personalize o menu principal com as ações ou perguntas frequentes da sua empresa que podem ser respondidas
              pelo bot automaticamente sem a necessidade de atendimento humano. O seu bot responderá por você.
            </bds-typo>
            <bds-typo variant="fs-16" tag="p" line-height="plus">
              Você também pode optar por não mostrá-lo na conversa, oferecendo atendimento humano mais direto.
            </bds-typo>
          </TextContainer>

          <RowSwitchContainer>
            <Switch
              text="Desabilitar menu"
              name="swicthHasFaq"
              isEmojiSwitch={false}
              checked={hasFaq}
              onClick={handleSetHasFaq}
            />
          </RowSwitchContainer>

          {!hasFaq && faqList && <MenuList />}
        </TabsPanelContainer>

        <SubmitButton isModified={checkIsModified()} style={{ margin: 0 }} />
      </Grid>

      <Grid md="3" style={{ width: 'auto' }}>
        <MenuSimulator />
      </Grid>
    </TabGridContainer>
  );
};
