import { ILogArgs, logger } from "packs-template-baseweb";

const logArgs: ILogArgs = {
  className: "Segment"
};

export const initializeSegment = (writeKey: string) => {
  logArgs.methodName = "initializeSegment";

  try {
    analytics.load(writeKey);
    analytics.page();
  } catch (error) {
    logger.error(`Error on initialize: ${error}`, logArgs);
  }
};

export const identify = (email: string) => {
  logArgs.methodName = "identify"

  try {
    analytics.identify(email);
  } catch (error) {
    logger.error(`Error on identify: ${error}`, logArgs);
  }
};

export const track = (event: string, payload?: any) => {
  logArgs.methodName = "track";

  try {
    analytics.track(event, payload);
  } catch (error) {
    logger.error(`Error on track: ${error}`, logArgs);
  }
};
