interface SetAttendanceQueueProps {
    ownerIdentity: string
    queueName: string
    guid: string
}

export const SetAttendanceQueue = ({
    ownerIdentity,
    queueName,
    guid
}: SetAttendanceQueueProps) => {
    return {
        id: guid,
        to: 'postmaster@desk.msging.net',
        method: 'set',
        uri: '/attendance-queues',
        type: 'application/vnd.iris.desk.attendancequeue+json',
        resource: {
            ownerIdentity: ownerIdentity,
            name: queueName,
            isActive: true,
            Priority: 0,
        },
    }
}
