export const RESOURCES_ROUTE = 'resources';
export const INSTALLATIONS_ROUTE = 'v1/installation';
export const PACK_INSTALLATIONS_ROUTE = 'v1/packs/installation';
export const GET_INSTALLATION_BY_SHORTNAME_ROUTE = 'v1/installations/last';
export const TENANTS_ROUTE = 'v1/tenants';
export const PLUGIN_ROUTE = 'v1/configurations/application';
export const UPDATE_WPP_PROFILE_ROUTE = 'whatsapp/profile';
export const INSTALLATIONS_CHANNEL_ACTIVATED_ROUTE = 'v1/installations/channel-activated';
export const ACTIVATE_WHATSAPP_ROUTE = 'v1/whatsapp/activate-number';
export const APPLICATION_ROUTE = 'v1/applications/application';
export const INSTALLATION_BY_ID = 'v1/installations';
export const BLIP_CHAT_CONFIGURATION = 'v1/configurations/blip-chat';
