import moment from 'moment';
import { Day } from 'types/humanService/Day';

export const validateHours = (days: Day[]) => {
  let isPassed = true;

  days.forEach(day => {
    day.turns.forEach(turn => {
      if (day.active) {
        const startTime = moment(turn.start, 'hh:mm');
        const endTime = moment(turn.end, 'hh:mm');

        if (day.active && !validateHour(turn.start as string)) {
          isPassed = false;
        } else if (day.active && !validateHour(turn.end as string)) {
          isPassed = false;
        } else if (!startTime.isValid()) {
          isPassed = false;
        } else if (!endTime.isValid()) {
          isPassed = false;
        } else if (startTime.isSameOrAfter(endTime)) {
          isPassed = false;
        } else if (day.holidays === '') {
          isPassed = false;
        }
      }
    });
  });
  return isPassed;
};

export const validateHour = (hour: string) => {
  const hoursRegex = /^([01][0-9]|2[0-3]):([0-5][0-9])$/g;
  return !(hour.match(hoursRegex) === null);
};
