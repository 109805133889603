import { useCallback, useState } from 'react';

import { useAppTabsContext } from 'contexts/TabsContext';
import tabsTexts from 'texts/tabsTexts.json';
import InputMessageEditable from 'components/InputMessageEditable';
import SubmitButton from 'pages/BotConfiguration/components/SubmitButton';

import { TextContainer } from '../styles';
import { TabsPanelContainer } from 'styles/tabs.styles';
import Grid from 'components/Grid';
import TabGridContainer from 'components/TabGridContainer';
import GreetingsSimulator from './GreetingsSimulator';
import {
  FIRST_CONTACT_MESSAGE,
  RETURN_MESSAGE_COMPONENT
} from '../../constants';

export const Saudation = () => {
  const {
    returnMessage,
    setReturnMessage,
    firstContactMessage,
    setFirstContactMessage,
    setGreetings,
    checkIsModified,
  } = useAppTabsContext();
  const [returnMessageOriginal, setReturnMessageOriginal] = useState<string>(returnMessage || '');
  const [firstContactMessageOriginal, setFirstContactMessageOriginal] = useState<string>(firstContactMessage || '');
  const [blurComponent, setBlurComponent] = useState<boolean>(true);
  const [focusComponent, setFocusComponent] = useState<string>(FIRST_CONTACT_MESSAGE);

  const handleChangeReturnMessage = useCallback(
    (ev: Event) => {
      const input = ev.target as HTMLBdsInputElement | null;
      setReturnMessage(input?.value as string);

      if (returnMessage === '') {
        setGreetings(greetings => ({ ...greetings, isModified: false }));
      } else if (returnMessage !== undefined) {
        setGreetings(greetings => ({ ...greetings, isModified: returnMessageOriginal !== input?.value }));
      } else {
        setReturnMessageOriginal(input?.value as string);
      }
    },
    [returnMessage, returnMessageOriginal, setGreetings, setReturnMessage],
  );

  const handleChangeFirstContactMessage = useCallback(
    (ev: Event) => {
      const input = ev.target as HTMLBdsInputElement | null;
      setFirstContactMessage(input?.value as string);

      if (firstContactMessage === '') {
        setGreetings(greetings => ({ ...greetings, isModified: false }));
      } else if (firstContactMessage !== undefined) {
        setGreetings(greetings => ({ ...greetings, isModified: firstContactMessageOriginal !== input?.value }));
      } else {
        setFirstContactMessageOriginal(input?.value as string);
      }
    },
    [firstContactMessage, firstContactMessageOriginal, setFirstContactMessage, setGreetings],
  );

  const handleFocusComponent = (component: string) => {
    setFocusComponent(component);
    handleBlurComponent(false);
  };

  const handleBlurComponent = (isBlur: boolean) => {
    setBlurComponent(isBlur);
  };

  const isEmptyValue = (value?: string) => {
    return value === '';
  };

  return (
    <TabGridContainer>
      <Grid xxs="12" md="8" lg="9" flex-wrap="wrap" direction="row">
        <TabsPanelContainer>
          <TextContainer>
            <bds-typo variant="fs-16" bold="semi-bold" tag="p">
              Personalize a mensagem de boas-vindas que seu bot enviará quando entrarem em contato com a sua empresa.
            </bds-typo>
          </TextContainer>
          <Grid xxs="12" flex-wrap="wrap" style={{ padding: 0 }}>
            <Grid xxs="12" md="6">
              <InputMessageEditable
                disabled={false}
                onChange={handleChangeFirstContactMessage}
                label="Saudação ao primeiro contato"
                message={firstContactMessage || ''}
                rows={3}
                required={false}
                requiredErrorMessage={tabsTexts.error.requiredField}
                onFocus={() => handleFocusComponent(FIRST_CONTACT_MESSAGE)}
                onBlur={() => handleBlurComponent(true)}
                danger={isEmptyValue(firstContactMessage)}
                errorMessage={isEmptyValue(firstContactMessage) ? tabsTexts.error.requiredField : ''}
              />
            </Grid>

            <Grid xxs="12" md="6">
              <InputMessageEditable
                disabled={false}
                onChange={handleChangeReturnMessage}
                label="Saudação de retorno"
                message={returnMessage || ''}
                rows={3}
                required={false}
                requiredErrorMessage={tabsTexts.error.requiredField}
                onFocus={() => handleFocusComponent(RETURN_MESSAGE_COMPONENT)}
                onBlur={() => handleBlurComponent(true)}
                danger={isEmptyValue(returnMessage)}
                errorMessage={isEmptyValue(returnMessage) ? tabsTexts.error.requiredField : ''}
              />
            </Grid>
          </Grid>
        </TabsPanelContainer>

        <SubmitButton isModified={checkIsModified()} style={{ margin: 0 }} />
      </Grid>
      <Grid md="3" style={{ width: 'auto' }}>
        <GreetingsSimulator
          isBlur={blurComponent}
          focusComponent={focusComponent}
          returnMessage={returnMessage}
          firstContactMessage={firstContactMessage}
        />
      </Grid>
    </TabGridContainer>
  );
};
