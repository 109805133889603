import styled, { css } from 'styled-components';

interface MainContainerProps {
    isEmojiSwitch: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
    display: flex;
    align-items: center;

    bds-typo {
        margin-left: 10px;
    }

    ${props =>
        props.isEmojiSwitch
            ? css`
                  //border: 1px solid red;
                  height: 26px;
                  padding-left: 16px;
              `
            : css`
                  margin-right: auto;
              `}
`;
