import { useHumanServiceContext } from 'contexts/HumanServiceContext';

import { TextContainer } from '../styles';

import { Dispatch, SetStateAction, useState } from 'react';
import { Tabs } from 'types/Tabs';

import { TabsPanelContainer } from 'styles/tabs.styles';
import InputPreServiceQuestion from '../components/InputPreServiceQuestion';
import SubmitButton from '../components/SubmitButton';
import AlertsButtonCancel from '../components/AlertsButtonCancel';
import Grid from 'components/Grid';
import TabGridContainer from 'components/TabGridContainer';
import PreServiceQuestionSimulator from '../components/Simulators/PreServiceQuestionSimulator';

type PreServiceQuestionProps = {
  setPreServiceQuestionTab: Dispatch<SetStateAction<Tabs>>;
};

export const PreServiceQuestion = ({ setPreServiceQuestionTab }: PreServiceQuestionProps) => {
  const { preServiceQuestionList, setPreServiceQuestionList } = useHumanServiceContext();
  const [cancelWarning, setCancelWarning] = useState(false);
  const [focusComponent, setFocusComponent] = useState<string>('');
  const [blurComponent, setBlurComponent] = useState<boolean>(true);
  const [indexFocusComponent, setIndexFocusComponent] = useState<number>();

  const handleChangeServiceQuestionList = (question: { [x: string]: string }, index: number) => {
    const newQuestionList = [...preServiceQuestionList];
    newQuestionList.splice(index, 1, question);
    setPreServiceQuestionList(newQuestionList);
    setFocusComponent(Object.values(question)[0]);
  };

  const handleFocusComponent = (index: number, preService: string) => {
    setIndexFocusComponent(index + 1);
    setFocusComponent(preService);
    setBlurComponent(false);
  };

  const handleBlurComponent = (isBlur: boolean) => {
    setBlurComponent(isBlur);
  };

  return (
    <TabGridContainer>
      <Grid xxs="12" md="8" lg="9" flex-wrap="wrap" direction="row">
        <TabsPanelContainer>
          <TextContainer>
            <bds-typo variant="fs-16" bold="semi-bold" tag="p">
              Personalize a mensagem que aparecerá para seu cliente após a escolha de cada opção de atendimento humano.
            </bds-typo>

            <bds-typo variant="fs-16" bold="semi-bold" tag="p">
              Utilize o pré atendimento para solicitar ou dar informações que auxiliarão o atendimento humano.
            </bds-typo>
          </TextContainer>
          <Grid xxs="12" flex-wrap="wrap" direction="row" style={{ paddingLeft: 0 }}>
            {preServiceQuestionList &&
              preServiceQuestionList.map((_, i) => {
                return (
                  <Grid xxs="12" md="6" key={i}>
                    <InputPreServiceQuestion
                      index={i}
                      key={i}
                      setTabData={setPreServiceQuestionTab}
                      onChangeServiceQuestionList={handleChangeServiceQuestionList}
                      onFocus={(index: number, preService: string) => handleFocusComponent(index, preService)}
                      onBlur={() => handleBlurComponent(true)}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </TabsPanelContainer>
        <SubmitButton cancelWarning={cancelWarning} setCancelWarning={setCancelWarning} />
        <AlertsButtonCancel cancelWarning={cancelWarning} setCancelWarning={setCancelWarning} />
      </Grid>

      <Grid md="3" style={{ width: 'auto' }}>
        <PreServiceQuestionSimulator
          isBlur={blurComponent}
          focusComponent={focusComponent}
          indexFocusComponent={indexFocusComponent?.toString() || ''}
        />
      </Grid>
    </TabGridContainer>
  );
};
