import { ModuleIds } from 'constants/ModuleIds';
import {
  BOT_ROUTE,
  CHANNELS_ROUTE,
  DESK_ROUTER,
  HUMAN_SERVICE_ROUTE,
  PACK_MANAGER_ROUTE,
  ACTIVE_MESSAGE,
} from 'constants/Routes';
import { usePack } from 'hooks/usePack';
import { ILogArgs, logger } from 'packs-template-baseweb';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createToastError } from 'services/Toast';
import { directionLeadFindByInstallationId } from 'services/experienceCluster/Api';
import { Module } from 'typings/Module';
import { Pack } from 'typings/Pack';

type PackContextData = {
  pack: Pack;
  modules: Module[];
  activeModule: Module | undefined;
  isOwner: boolean;
  setActiveModule: React.Dispatch<React.SetStateAction<Module | undefined>>;
  activateWppChannel: () => void;
};

const logArgs: ILogArgs = {
  className: 'PackContext',
};

export const PackContext = createContext<PackContextData>({} as PackContextData);

export const PackProvider: FC = ({ children }) => {
  const [pack, setPack] = useState({} as Pack);
  const [initialized, setInitialized] = useState(false);
  const { fetchPackData, getModules } = usePack();
  const [isOwner, setIsOwner] = useState(false);
  const [modules, setModules] = useState<Module[]>([]);
  const [activeModule, setActiveModule] = useState<Module | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  const activateWppChannel = () => {
    setPack(pack => ({ ...pack, isChannelActivated: true }));
  };

  useEffect(() => {
    if (modules.length > 0) {
      handleActiveModule();
      setInitialized(true);
    }
  }, [modules]);

  const handleActiveModule = () => {
    if (isCurrentPathName(DESK_ROUTER)) {
      setActiveModule(getModuleByTitle('Central de atendimento'));
    } else if (isCurrentPathName(BOT_ROUTE)) {
      setActiveModule(getModuleByTitle('Bot'));
    } else if (isCurrentPathName(HUMAN_SERVICE_ROUTE)) {
      setActiveModule(getModuleByTitle('Atendimento Humano'));
    } else if (isCurrentPathName(CHANNELS_ROUTE)) {
      setActiveModule(getModuleByTitle('Canais de atendimento'));
    } else if (isCurrentPathName(ACTIVE_MESSAGE)) {
      setActiveModule(getModuleByTitle('Mensagens Ativas'));
    } else {
      setActiveModule(modules[0]);
    }
  };

  const isCurrentPathName = (pathname: string) => {
    return `${PACK_MANAGER_ROUTE}/${pathname}` === location.pathname;
  };

  const getModuleByTitle = (title: string) => {
    return modules.find(module => module.title === title);
  };

  useEffect(() => {
    (async () => {
      try {
        const packData = await fetchPackData();

        if (packData && packData.pack) {
          setIsOwner(packData.isOwner);
          setPack(packData.pack);
          if (packData.pack.isChannelActivated) {
            setModules(getModules(packData.pack, packData.isOwner));
          } else {
            setModules(
              getModules(packData.pack, packData.isOwner).filter(module => module.id != ModuleIds.whatsAppProfile),
            );
          }
          RedirectLeadsToV2();
        }
      } catch (error) {
        createToastError('Falha ao obter o Pack', 'Por favor, tente novamente daqui alguns minutos.');
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const RedirectLeadsToV2 = () => {
    window.location.href = process.env.REACT_APP_PACK_MANAGER_V2 as string;
  };

  return initialized ? (
    <>
      <PackContext.Provider value={{ pack, modules, activeModule, isOwner, setActiveModule, activateWppChannel }}>
        {children}
      </PackContext.Provider>
    </>
  ) : (
    <>{isLoading && <bds-loading-page />}</>
  );
};

export function usePackContext(): PackContextData {
  logArgs.methodName = 'usePackContext';

  const context = useContext(PackContext);

  if (!context) {
    logger.fatal(`Error on create Pack Context`, logArgs);
    throw new Error('Use Context must be used within a Provider');
  }

  return context;
}
