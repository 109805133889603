import Grid from 'components/Grid/Grid';
import React from 'react';

const GridContent: React.FC = ({ children }) => {
  return (
    <Grid
      xxs="12"
      xs="12"
      sm="12"
      md="12"
      lg="12"
      xg="12"
      direction="column"
      align-items="center"
      justify-content="center"
      containerFluid
    >
      {children}
    </Grid>
  );
};

export default GridContent;
