import Guid from 'guid';
import { Requests } from 'services/requests/Requests';
import { Resource } from 'types/Resource';
export const searchResources = async (
  variablesResource: string[],
  requestRouterResources: Requests | undefined,
  setResources: React.Dispatch<React.SetStateAction<Resource[]>>,
  setLoadingResources: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const resourcesFromBot = [];

  for (const resource of variablesResource) {
    const resourceValue = await requestRouterResources?.getSpecificResource(Guid.raw(), resource);
    const newResource = {
      name: decodeURIComponent(resource),
      value: resourceValue.resource,
      type: resourceValue.type,
    };
    resourcesFromBot.push(newResource);
  }
  setResources(resourcesFromBot);
  setLoadingResources(false);
};
