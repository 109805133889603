import { SendTrackProps } from 'types/SendTrackProps';

import { AnalyticsService as _analyticsService } from 'services/analyticsService/AnalyticsService';

export class ChannelsTracks {
  sendTrackStartPlugin = async () => {
    _analyticsService.Track('blippacks-managment-channels-opened');
  };

  sendTrackClickButtonChannelsTested = async ({ channel }: SendTrackProps) => {
    _analyticsService.Track('blippacks-management-general-channels-tested', {
      channel,
    });
  };

  sendTrackClickButtonChannelsActivated = async ({ channel, destination }: SendTrackProps) => {
    _analyticsService.Track('blippacks-management-general-channels-activated', {
      channel,
      destination,
    });
  };

  sendTrackShowModalActivateChannel = async ({ channel }: SendTrackProps) => {
    _analyticsService.Track('blippacks-management-channels-modal-activate', {
      channel,
    });
  };

  sendTrackModalConfirmActivateChannel = async ({ channel }: SendTrackProps) => {
    _analyticsService.Track('blippacks-management-channels-modal-activate-confirm', {
      channel,
    });
  };

  sendTrackModalCloseActivateChannel = async ({ channel, button }: SendTrackProps) => {
    _analyticsService.Track('blippacks-management-channels-modal-activate-close', {
      channel,
      button,
    });
  };

  sendTrackModalClosePaywall = async () => {
    _analyticsService.Track('blippacks-management-general-channels-paywall-close');
  };

  sendTrackModalUpgradePaywall = async () => {
    _analyticsService.Track('blippacks-management-general-channels-paywall-upgrade');
  };

  sendTrackSeePlans = async (payload: any) => {
    _analyticsService.Track('blippacks-management-general-channels-see-plans', payload);
  };

  sendTrackCloseMeetBlipGo = async (payload: any) => {
    _analyticsService.Track('blippacks-management-general-channels-meet-blip-go-close', payload);
  };

  sendTrackTestBlipChat = async () => {
    _analyticsService.Track('blippacks-management-general-channels-test-blip-chat');
  };

  sendTrackOpenMeetBlipGo = async () => {
    _analyticsService.Track('blippacks-management-general-channels-meet-blip-go');
  };

  sendTrackChoosePlan = async () => {
    _analyticsService.Track('blippacks-management-general-channels-paywall-upgrade');
  };

  sendTrackTestBot = async () => {
    _analyticsService.Track('blippacks-management-general-channels-paywall-test-bot');
  };
  sendTrackSevenDaysClosed = async () => {
    _analyticsService.Track('blippacks-management-general-channels-7days-free-closed');
  };
  sendTrackSevenDaysConfirmed = async () => {
    _analyticsService.Track('blippacks-management-general-channels-7days-free-confirmed');
  };
  sendTrackSevenDaysOpened = async () => {
    _analyticsService.Track('blippacks-management-general-channels-7days-free-opened');
  };
}
