import Warning from 'pages/HumanService/components/Warning';
import { WarningConstants } from 'constants/WarningsConstants';

type WarningTabProps = {
  attendanceHours: {
    isInvalid: boolean;
    isOpenWarning: boolean;
    handleCloseWarning: () => void;
  };
  attendanceQueue: {
    isInvalid: boolean;
    isOpenWarning: boolean;
    handleCloseWarning: () => void;
  };
  preServiceQuestion: {
    isInvalid: boolean;
    isOpenWarning: boolean;
    handleCloseWarning: () => void;
  };
};

export const WarningTab = ({ attendanceHours, attendanceQueue, preServiceQuestion }: WarningTabProps) => {
  return (
    <>
      {attendanceHours && attendanceHours.isInvalid && (
        <Warning
          isOpen={attendanceHours.isOpenWarning}
          handleIsOpen={attendanceHours.handleCloseWarning}
          screenName="Horários"
          text={WarningConstants.ATTENDANCEHOURSWARNING}
        />
      )}

      {attendanceQueue && attendanceQueue.isInvalid && (
        <Warning
          isOpen={attendanceQueue.isOpenWarning}
          handleIsOpen={attendanceQueue.handleCloseWarning}
          screenName="Fila de atendimento"
          text={WarningConstants.ATTENDANCEQUEUEWARNING}
        />
      )}

      {preServiceQuestion && preServiceQuestion.isInvalid && (
        <Warning
          isOpen={preServiceQuestion.isOpenWarning}
          handleIsOpen={preServiceQuestion.handleCloseWarning}
          screenName="Pré-atendimento"
          text={WarningConstants.PRESERVICEQUESTIONWARNING}
        />
      )}
    </>
  );
};
