import { RESOURCES } from '../../constants/ResourcesNames';
import { Resource } from '../../types/Resource';

interface ResetGreetingsProps {
  resources: Resource[];
  setReturnMessage: React.Dispatch<React.SetStateAction<undefined | string>>;
  setFirstContactMessage: React.Dispatch<React.SetStateAction<undefined | string>>;
}

export const resetGreetings = ({ resources, setReturnMessage, setFirstContactMessage }: ResetGreetingsProps) => {
  resources.forEach(resource => {
    const { name, value } = resource;

    if (name === RESOURCES.MESSAGES.RETURN) {
      setReturnMessage(value);
    }

    if (name === RESOURCES.MESSAGES.FIRST_CONTACT) {
      setFirstContactMessage(value);
    }
  });
};
