import React, { useEffect, useState } from 'react';
import './styles.scss';

interface ICollapsibleForm {
  title: string,
  label: string,
  onChange: (e: any) => void;
  value: string;
  error: string,
  danger: boolean,

}

export const CollapsibleForm =
  ({ title, label, onChange, error, danger, value }: ICollapsibleForm) => {
    return (

      <div className="collapsible">
        <div className="content">
          <bds-typo
            bold="bold"
            class="activation-form-select-title"
            variant="fs-16">{title}</bds-typo>
          <bds-input value={value} error-message={error} danger={danger}
            onInput={onChange} class="activation-form-input" placeholder={label} />
        </div>
      </div>

    );
  };
