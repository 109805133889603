import React from 'react';
import SimulatorImage from 'assets/Simulador.png';
import {
    Img,
    ContainerMessage
} from 'components/Simulator/style'

type ISimulatorContainer = {
    children: React.ReactNode;
};

const SimulatorContainer: React.FC<ISimulatorContainer> = ({
    children
}) => {
    return (
        <React.Fragment>
            <div>
                <ContainerMessage>
                    {children}
                </ContainerMessage>

                <Img src={SimulatorImage} alt="Simulator" />
            </div>
        </React.Fragment>
    );
};

export default SimulatorContainer;