import { RESOURCES } from 'constants/ResourcesNames';
import { Resource } from 'types/Resource';

interface ResetQueuesProps {
  resources: Resource[];
  setPreQueueMessage: React.Dispatch<React.SetStateAction<string>>;
  setEmojis: React.Dispatch<React.SetStateAction<boolean>>;
}

export const resetQueues = ({ resources, setEmojis, setPreQueueMessage }: ResetQueuesProps) => {
  resources.forEach(resource => {
    const { name, value } = resource;

    if (name === RESOURCES.MESSAGES.PRE_QUEUE) {
      setPreQueueMessage(value);
    }

    if (name === RESOURCES.EMOJI) {
      setEmojis(value.toLowerCase() === 'true');
    }
  });
};
