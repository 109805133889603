import { ILogArgs, logger } from 'packs-template-baseweb';
import React, { FC, createContext, useState } from 'react';
import { Requests } from 'services/requests/Requests';
import { Resource } from 'types/Resource';
import { Tabs } from 'types/Tabs';
import { Day } from 'types/humanService/Day';
import { Queue } from 'types/humanService/Queue';

type HumanServiceContextData = {
  preQueueMessage: string;
  setPreQueueMessage: React.Dispatch<React.SetStateAction<string>>;
  emojis: boolean;
  setEmojis: React.Dispatch<React.SetStateAction<boolean>>;
  queueList: Queue[];
  setQueueList: React.Dispatch<React.SetStateAction<Queue[]>>;

  preServiceQuestionList: { [x: string]: string }[];
  setPreServiceQuestionList: React.Dispatch<React.SetStateAction<{ [x: string]: string }[]>>;

  twentyFourHours: boolean;
  setTwentyFourHours: React.Dispatch<React.SetStateAction<boolean>>;

  satisfactionSurvey: boolean;
  setSatisfactionSurvey: React.Dispatch<React.SetStateAction<boolean>>;

  sunday: Day;
  setSunday: React.Dispatch<React.SetStateAction<Day>>;
  monday: Day;
  setMonday: React.Dispatch<React.SetStateAction<Day>>;
  tuesday: Day;
  setTuesday: React.Dispatch<React.SetStateAction<Day>>;
  wednesday: Day;
  setWednesday: React.Dispatch<React.SetStateAction<Day>>;
  thursday: Day;
  setThursday: React.Dispatch<React.SetStateAction<Day>>;
  friday: Day;
  setFriday: React.Dispatch<React.SetStateAction<Day>>;
  saturday: Day;
  setSaturday: React.Dispatch<React.SetStateAction<Day>>;
  holiday: Day;
  setHoliday: React.Dispatch<React.SetStateAction<Day>>;

  attendanceQueueTab: Tabs;
  setAttendanceQueueTab: React.Dispatch<React.SetStateAction<Tabs>>;
  preServiceQuestionTab: Tabs;
  setPreServiceQuestionTab: React.Dispatch<React.SetStateAction<Tabs>>;
  satisfactionSurveyTab: Tabs;
  setSatisfactionSurveyTab: React.Dispatch<React.SetStateAction<Tabs>>;
  attendanceHoursTab: Tabs;
  setAttendanceHoursTab: React.Dispatch<React.SetStateAction<Tabs>>;

  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;

  resources: Resource[];
  setResources: React.Dispatch<React.SetStateAction<Resource[]>>;

  request: Requests | undefined;
  setRequest: React.Dispatch<React.SetStateAction<Requests | undefined>>;
  keyRouter: string;
  setKeyRouter: React.Dispatch<React.SetStateAction<string>>;
  keyDesk: string;
  setKeyDesk: React.Dispatch<React.SetStateAction<string>>;

  loadingResources: boolean;
  setLoadingResources: React.Dispatch<React.SetStateAction<boolean>>;

  requestRouterResources: Requests | undefined;
  setRequestWithResources: React.Dispatch<React.SetStateAction<Requests | undefined>>;

  isCanceledAction: boolean;
  setIsCanceledAction: React.Dispatch<React.SetStateAction<boolean>>;
};

const logArgs: ILogArgs = {
  className: "HumamServiceContext"
}

export const HumanServiceContext = createContext<HumanServiceContextData>({} as HumanServiceContextData);

export const HumanServiceProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [preQueueMessage, setPreQueueMessage] = useState('');
  const [emojis, setEmojis] = useState(false);
  const [queueList, setQueueList] = useState<Queue[]>([]);

  const [preServiceQuestionList, setPreServiceQuestionList] = useState<{ [x: string]: string }[]>([]);

  const [twentyFourHours, setTwentyFourHours] = useState<boolean>(false);

  const [satisfactionSurvey, setSatisfactionSurvey] = useState<boolean>(false);

  const [sunday, setSunday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  } as Day);

  const [monday, setMonday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  } as Day);

  const [tuesday, setTuesday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  } as Day);

  const [wednesday, setWednesday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  } as Day);

  const [thursday, setThursday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  } as Day);

  const [friday, setFriday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  } as Day);

  const [saturday, setSaturday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  } as Day);

  const [holiday, setHoliday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  } as Day);

  const [attendanceQueueTab, setAttendanceQueueTab] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    handleCloseWarning: () => {
      setAttendanceQueueTab(current => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  const [preServiceQuestionTab, setPreServiceQuestionTab] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    handleCloseWarning: () => {
      setPreServiceQuestionTab(current => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  const [satisfactionSurveyTab, setSatisfactionSurveyTab] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    handleCloseWarning: () => {
      setSatisfactionSurveyTab(current => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  const [attendanceHoursTab, setAttendanceHoursTab] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    handleCloseWarning: () => {
      setAttendanceHoursTab(current => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  const [currentTab, setCurrentTab] = useState('');

  const [resources, setResources] = useState<Resource[]>([]);

  const [request, setRequest] = useState<Requests | undefined>();

  const [keyRouter, setKeyRouter] = useState('');
  const [keyDesk, setKeyDesk] = useState('');

  const [loadingResources, setLoadingResources] = useState(true);

  const [requestRouterResources, setRequestWithResources] = useState<Requests | undefined>();

  const [isCanceledAction, setIsCanceledAction] = useState(false);

  return (
    <HumanServiceContext.Provider
      value={{
        preQueueMessage,
        setPreQueueMessage,
        emojis,
        setEmojis,
        queueList,
        setQueueList,

        preServiceQuestionList,
        setPreServiceQuestionList,

        satisfactionSurvey,
        setSatisfactionSurvey,

        twentyFourHours,
        setTwentyFourHours,

        sunday,
        setSunday,
        monday,
        setMonday,
        tuesday,
        setTuesday,
        wednesday,
        setWednesday,
        thursday,
        setThursday,
        friday,
        setFriday,
        saturday,
        setSaturday,
        holiday,
        setHoliday,

        attendanceQueueTab,
        setAttendanceQueueTab,
        preServiceQuestionTab,
        setPreServiceQuestionTab,
        satisfactionSurveyTab,
        setSatisfactionSurveyTab,
        attendanceHoursTab,
        setAttendanceHoursTab,

        currentTab,
        setCurrentTab,
        resources,
        setResources,
        request,
        setRequest,

        keyDesk,
        setKeyDesk,
        keyRouter,
        setKeyRouter,

        loadingResources,
        setLoadingResources,

        requestRouterResources,
        setRequestWithResources,

        isCanceledAction,
        setIsCanceledAction,
      }}
    >
      {children}
    </HumanServiceContext.Provider>
  );
};

export function useHumanServiceContext(): HumanServiceContextData {
  logArgs.methodName = "useHumanServiceContext";

  const context = React.useContext(HumanServiceContext);

  if (!context) {
    logger.fatal("Error on create HumanContext", logArgs);
    
    throw new Error('use human service context must be used within an HumanServiceProvider');
  }

  return context;
}
