import {
  SUBJECT_ACTIVATION,
  SUBJECT_CANCEL,
  SUBJECT_CONTRACT,
  SUBJECT_VERIFICATION
} from 'constants/MailCredentials';

const subjectActivation = () => {
  return isFromMeta() + SUBJECT_ACTIVATION
};

const subjectVerification = () => {
  return isFromMeta() + SUBJECT_VERIFICATION
};

const subjectContract = () => {
  return isFromMeta() + SUBJECT_CONTRACT
};

const subjectCancel = () => {
  return isFromMeta() + SUBJECT_CANCEL;
};

const isFromMeta = () => {
  const sourceUtm = localStorage.getItem('source');
  if (sourceUtm) {
    if (sourceUtm.match(/lpmeta/)) {
      return '[META]';
    }
  }
  return '';
}

export const MailSubject = {
  subjectActivation,
  subjectVerification,
  subjectContract,
  subjectCancel,
  isFromMeta
};
