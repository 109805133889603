import { BlipCommandBaseRequest } from '../../../base/models/BlipCommandBaseRequest';

export class GetStatesResquest extends BlipCommandBaseRequest {
  metadata: {
    'blip_portal.email': string;
  };

  constructor(userMail: string, skillName: string) {
    super(`/flow/states?shortName=${skillName}`, 'GET', 'BUILDER');
    this.metadata = { 'blip_portal.email': userMail };
  }
}
