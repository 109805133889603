import BotContent from 'components/Simulator/BotContent';
import SimulatorContainer from 'components/Simulator/SimulatorContainer';
import UserContent from 'components/Simulator/UserContent';
import React from 'react';
import { useHumanServiceContext } from 'contexts/HumanServiceContext';
import { Day } from 'types/humanService/Day';
import tabsTexts from 'texts/tabsTexts.json';
import { Turn } from 'types/humanService/Turn';

type InfoDay = {
    name: string;
    day: Day;
};

const OpeningHoursSimulator: React.FC = () => {
    const {
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
    } = useHumanServiceContext();

    const renderOpeningHoursSimulator = () => {
        return (
            <React.Fragment>
                <UserContent
                    key={'OpeningHoursSimulator-user-content-1'}
                    testid="OpeningHoursSimulator-user-content-1"
                    time="22:15"
                    message="Oi"
                    disable
                />
                <BotContent
                    key={'OpeningHoursSimulator-bot-content-2'}
                    testid="OpeningHoursSimulator-bot-content-2"
                    time="22:45"
                    message={<p>Opa, estamos <b>fora</b> do nosso <b>horário de atendimento</b>.</p>}
                    disable
                />
                <BotContent
                    key={'OpeningHoursSimulator-bot-content-3'}
                    testid="OpeningHoursSimulator-bot-content-3"
                    time="22:45"
                    message={handleOpenHoursActive()}
                />
                <BotContent
                    key={'OpeningHoursSimulator-bot-content-4'}
                    testid="OpeningHoursSimulator-bot-content-4"
                    time="22:45"
                    message={'⏳ Assim que nossa equipe retornar atenderemos você.'}
                    disable
                />
            </React.Fragment>
        );
    };

    const handleOpenHoursActive = () => {
        const workDaysActive: InfoDay[] = [];

        mountedWorkDaysActive(tabsTexts.schedules.days.sunday, sunday, workDaysActive);
        mountedWorkDaysActive(tabsTexts.schedules.days.monday, monday, workDaysActive);
        mountedWorkDaysActive(tabsTexts.schedules.days.tuesday, tuesday, workDaysActive);
        mountedWorkDaysActive(tabsTexts.schedules.days.thursday, thursday, workDaysActive);
        mountedWorkDaysActive(tabsTexts.schedules.days.wednesday, wednesday, workDaysActive);
        mountedWorkDaysActive(tabsTexts.schedules.days.friday, friday, workDaysActive);
        mountedWorkDaysActive(tabsTexts.schedules.days.saturday, saturday, workDaysActive);

        return (
            <>
                ⏰ Nossa equipe está disponível nos <b>horários</b> a seguir:<br />
                {workDaysActive.map((workDayActive) =>
                    <React.Fragment key={workDayActive.name}>
                        <br />
                        <b>{workDayActive.name}</b>
                        {mountedTurnsWorkDay(workDayActive.day.turns)}
                    </React.Fragment>
                )}
            </>
        );
    };

    const mountedWorkDaysActive = (name: string, workDay: Day, workDaysActive: InfoDay[]) => {
        if (isWorkDayActive(workDay)) {
            workDaysActive.push({ name, day: workDay });
        }
    };

    const isWorkDayActive = (workDay: Day) => {
        return workDay.active;
    };

    const mountedTurnsWorkDay = (workDayTurn: Turn[]) => {
        return (
            <React.Fragment>
                {workDayTurn.map((turn, index) =>
                    <div key={index}>{turn.start} - {turn.end}</div>
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <SimulatorContainer>
                {renderOpeningHoursSimulator()}
            </SimulatorContainer>
        </React.Fragment>
    );
};

export default OpeningHoursSimulator;