import styled from 'styled-components';

export const InputsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
  width: 100%;
`;

export const RowSwitchContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;
