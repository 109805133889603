import React, { useState } from 'react';
import './styles.scss';
import { CollapsibleForm } from './components/collapsibleForm';
import { CompanyVerificationTracks } from './services/tracking';
import { isCnpjValid, isPhoneValid, isUrlValid, phoneMask, cpfOrCnpjMask } from './services/validator';
import { SITE_OPTIONS, CNPJ_OPTIONS } from 'pages/channels/constants/wppModalInfo';
import { CHANNELS_ROUTE, PACK_MANAGER_ROUTE, CONTRACT_INFORMATION } from 'constants/Routes';
import { MailService as _mailService } from 'services/mailService';
import { MailSubject as _mailsubject } from 'services/MailSubject';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { usePackContext } from 'contexts/PackContext';
import { EMAIL_TEAM } from 'constants/MailCredentials';

export interface ICollapsibleForm {
  cnpj: IFormValues;
  site: IFormValues;
  personalPhone: IFormValues;
  companyPhone: IFormValues;
  selectCNPJ: IFormValues;
  selectSite: IFormValues;
}

interface IFormValues {
  value: string;
  error: string;
  isValid: boolean;
}

interface ActivationFormModal {
  modalRef: React.MutableRefObject<HTMLBdsModalElement | null>;
  activateTrial?: boolean;
}

export const ActivationForm = ({ modalRef, activateTrial }: ActivationFormModal) => {
  const [hasCNPJ, sethasCNPJ] = useState(false);
  const [hasSite, sethasSite] = useState(false);
  const { userData } = useAuthContext();
  const { pack } = usePackContext();
  const navigate = useNavigate();
  const companyTracks = new CompanyVerificationTracks();
  const valuesForm = { value: '', error: 'Você precisa preencher este campo para poder seguir.', isValid: true };
  const [form, setForm] = useState<ICollapsibleForm>({
    cnpj: valuesForm,
    site: valuesForm,
    personalPhone: valuesForm,
    companyPhone: valuesForm,
    selectCNPJ: valuesForm,
    selectSite: valuesForm,
  });

  const handleOptionSelected = (ev: any, field: string) => {
    const value = ev.target.textContent;
    let error = '';
    let isValid = true;
    if (value === 'Sim') {
      if (field === 'selectSite') {
        sethasSite(true);
      } else if (field === 'selectCNPJ') {
        sethasCNPJ(true);
      }
    } else if (value !== 'Sim') {
      if (field === 'selectCNPJ') {
        sethasCNPJ(false);
      }
      if (field === 'selectSite') {
        sethasSite(false);
      }
    } else {
      error = 'Você precisa preencher este campo para poder seguir.';
      isValid = false;
    }
    setForm({
      ...form,
      [field]: { value: value, error: error, isValid: isValid },
    });
  };

  const handleFormChange = (field: string, value: string) => {
    let isValid = false;
    if (field === 'cnpj') {
      value = cpfOrCnpjMask(value);
      isValid = isCnpjValid(value);
    } else if (field === 'companyPhone' || field === 'personalPhone') {
      value = phoneMask(value);
      isValid = isPhoneValid(value);
    } else if (field === 'site' && isUrlValid(value)) {
      isValid = true;
    }
    setForm(prevState => {
      return {
        ...prevState,
        [field]: { ...prevState[field as keyof ICollapsibleForm], value, isValid },
      };
    });
    return isValid;
  };

  const isFormValid = () => {
    const selectCnpjOption = form.selectCNPJ.value ? true : false;
    const selectSiteOption = form.selectSite.value ? true : false;
    setForm({
      ...form,
      selectCNPJ: { ...form.selectCNPJ, isValid: selectCnpjOption },
      selectSite: { ...form.selectSite, isValid: selectSiteOption },
    });
    const companyPhone = handleFormChange('companyPhone', form.companyPhone.value);
    const personalPhone = handleFormChange('personalPhone', form.personalPhone.value);
    const cnpj = hasCNPJ ? handleFormChange('cnpj', form.cnpj.value) : true;
    const site = hasSite ? handleFormChange('site', form.site.value) : true;
    return selectCnpjOption && selectSiteOption && companyPhone && site && personalPhone && cnpj;
  };

  const handleFinishButton = () => {
    if (isFormValid()) {
      sendVerificationMail();
      companyTracks.sendTrackCompanyVerificationButton({
        personalPhone: form.personalPhone.value,
        companyPhone: form.companyPhone.value,
        hasCnpj: form.selectCNPJ.value,
        hasWebsite: form.selectSite.value,
        cnpj: hasCNPJ ? form.cnpj.value : '',
        site: hasSite ? form.site.value : '',
      });
      if (modalRef.current) {
        modalRef.current.open = !modalRef.current.open;
      }
      navigate(`${PACK_MANAGER_ROUTE}\\${CHANNELS_ROUTE}\\${CONTRACT_INFORMATION}`,
        {
          replace: true,
          state: {
            trial: activateTrial,
          }
        });
    }
  };

  const sendVerificationMail = () => {
    const tos = { toName: 'Experience Cluster', toMailAddress: EMAIL_TEAM };
    const trial = activateTrial ? 'Solicitou teste de 7 dias' : 'Sem período de teste';
    const html = _mailService.formatWAMail(
      userData.FullName,
      userData.Email,
      form.companyPhone.value,
      form.personalPhone.value,
      pack.companyName ? pack.companyName : '',
      form.cnpj.value ? form.cnpj.value : form.selectCNPJ.value,
      form.site.value ? form.site.value : form.selectSite.value,
      trial,
    );
    _mailService.sendEmail(userData.FullName, tos, _mailsubject.subjectVerification(), html, true);
  };
  return (
    <div className="activation-form-container">
      <div className="activation-form-header">
        <bds-typo bold="bold" variant="fs-24" color="blue" class="activation-form-title">
          Você está a um passo de ter seu WhatsApp empresarial com automação de conversas!
        </bds-typo>
        <bds-typo variant="fs-16" class="activation-form-subtitle">
          No entanto, para garantir a confiabilidade da empresa, existem alguns requisitos a serem considerados. Vamos
          lá? 😊
        </bds-typo>
      </div>
      <div>
        <bds-typo bold="bold" class="activation-form-select-title" variant="fs-16">
          Você possui CNPJ?
        </bds-typo>
        <bds-select
          class="activation-form-select"
          danger={!form?.selectCNPJ?.isValid}
          error-message={form.selectCNPJ.error}
        >
          {CNPJ_OPTIONS.map(cnpj => {
            return (
              <bds-select-option
                data-testid="select-cnpj"
                key={cnpj.value}
                value={cnpj.label}
                onClick={event => handleOptionSelected(event, 'selectCNPJ')}
              >
                {cnpj.label}
              </bds-select-option>
            );
          })}
        </bds-select>
        {hasCNPJ ? (
          <CollapsibleForm
            title="Qual é o CNPJ?"
            label="00.000.000/0000-00"
            value={form.cnpj.value}
            onChange={(ev: any) => handleFormChange('cnpj', ev.target.value)}
            error={form?.cnpj?.error}
            danger={!form?.cnpj?.isValid}
          />
        ) : (
          ''
        )}

        <bds-typo bold="bold" class="activation-form-select-title" variant="fs-16">
          Você possui endereço de site próprio?
        </bds-typo>
        <bds-typo variant="fs-14" class="activation-form-select-title">
          (ex.: minhaempresa.com.br)?
        </bds-typo>
        <bds-select
          class="activation-form-select"
          danger={!form?.selectSite?.isValid}
          error-message={form.selectSite.error}
        >
          {SITE_OPTIONS.map(website => {
            return (
              <bds-select-option
                key={website.value}
                value={website.label}
                onClick={event => handleOptionSelected(event, 'selectSite')}
              >
                {website.label}
              </bds-select-option>
            );
          })}
        </bds-select>
        {hasSite ? (
          <CollapsibleForm
            title="Qual é o seu site?"
            label="ex.: minhaempresa.com.br"
            value=""
            onChange={(ev: any) => handleFormChange('site', ev.target.value)}
            error={form?.site?.error}
            danger={!form?.site?.isValid}
          />
        ) : (
          ''
        )}

        <bds-typo bold="bold" class="activation-form-select-title" variant="fs-16">
          Qual o telefone que podemos entrar em contato contigo?
        </bds-typo>
        <bds-input
          class="activation-form-input"
          placeholder="(xx) xxxxx-xxxx"
          value={form.personalPhone.value}
          error-message={form?.personalPhone?.error}
          danger={!form?.personalPhone?.isValid}
          onInput={(ev: any) => handleFormChange('personalPhone', ev.target.value)}
        />
        <bds-typo bold="bold" class="activation-form-select-title" variant="fs-16">
          Qual o número de telefone que você pretende conectar o seu bot ao WhatsApp?
        </bds-typo>
        <bds-input
          class="activation-form-input"
          placeholder="(xx) xxxxx-xxxx"
          type="phonenumber"
          value={form.companyPhone.value}
          error-message={form?.companyPhone?.error}
          danger={!form?.companyPhone?.isValid}
          onInput={(ev: any) => handleFormChange('companyPhone', ev.target.value)}
        />
        <div className="activation-form-button">
          <bds-button
            variant="primary"
            onClick={() => {
              handleFinishButton();
            }}
          >
            Enviar contato
          </bds-button>
        </div>
      </div>
    </div>
  );
};
