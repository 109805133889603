import { usePackContext } from 'contexts/PackContext';
import { FC } from 'react';
import { Module } from 'typings/Module';
import { ModuleComponents } from 'typings/ModuleComponents';
import { Container } from './style';

const IframeModules: FC<ModuleComponents> = ({ activeModule }) => {
  const { modules } = usePackContext();

  const hideOrShowInCss = (module: Module) => (module.id == activeModule.id ? 'block' : 'none');
  const IframeModules = () => {
    return modules.map((module, index) => (
      <Container key={index} display={hideOrShowInCss(module)}>
        <iframe src={module.url} title={module.title} width="100%" height="100%" />
      </Container>
    ));
  };

  return <>{IframeModules()}</>;
};

export default IframeModules;
