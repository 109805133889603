import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
      body {
        height: 100%;
        width: 100%;
        margin: 0;
        font-family: 'Nunito Sans';
      }

      * {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
      }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const HideOrShowContainer = styled.div<{ display: string }>`
  display: ${props => props.display};
`;

export const Padding = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;
