export const CHANNELS_LIST = [
  {
    title: 'Blip chat',
    photo: '/assets/channels/take.png',
    linkActive: true,
    link: '',
    imageModal: '',
  },
  {
    title: 'Instagram',
    photo: '/assets/channels/instagram.png',
    linkActive: false,
    link: '',
    imageModal: '/assets/images/instagramActivationModal.svg',
  },
  {
    title: 'Messenger',
    photo: '/assets/channels/messenger.png',
    linkActive: false,
    link: '',
    imageModal: '/assets/images/messengerActivationModal.svg',
  },
  {
    title: 'WhatsApp',
    photo: '/assets/channels/whatsapp.png',
    linkActive: false,
    link: 'https://api.whatsapp.com/send/?phone=5511938055138&text=Olá, quero fazer o *upgrade* para o *plano Pro* do BlipGo',
    imageModal: '',
  },
];
