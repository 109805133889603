import { useEffect } from 'react';
import './styles.scss';
import { NumberPreparationTracks } from './services/tracking';
import { HOW_DELETE_NUMBER, REPORT_PROBLEM } from './constants/whatsappExternalUrls';
import { useNavigate } from 'react-router-dom';
import { CHANNELS_ROUTE, META_CONNECTION, PACK_MANAGER_ROUTE } from 'constants/Routes';
import Grid from 'components/Grid/Grid';
import { ILogArgs, logger } from 'packs-template-baseweb';

export const NumberPreparation = () => {
  const numberPreparationTracks = new NumberPreparationTracks();
  const navigate = useNavigate();

  const logArgs: ILogArgs = {
    className: 'NumberPreparation',
  };

  useEffect(() => {
    numberPreparationTracks.sendTrackNumberPreparationOpen();
  }, []);

  const reportProblem = () => {
    const screenName = 'pós pedido de contrato - preparo do número';
    window.open(REPORT_PROBLEM, ' _blank');
    numberPreparationTracks.sendTrackReportProblem({ screenName });
  };

  const howDeleteNumber = () => {
    window.open(HOW_DELETE_NUMBER, ' _blank');
    numberPreparationTracks.sendTrackHowDeleteNumber();
  };

  const deletedNumber = () => {
    numberPreparationTracks.sendTrackDeletedAccount();
    navigate(`${PACK_MANAGER_ROUTE}/${CHANNELS_ROUTE}/${META_CONNECTION}`);
  };

  const newNumberRegistration = () => {
    logArgs.methodName = "newNumberRegistration";
    logger.info(`redirecting to meta conection page (${META_CONNECTION})`, logArgs);
    numberPreparationTracks.sendTrackNewNumberRegistration();
    navigate(`${PACK_MANAGER_ROUTE}/${CHANNELS_ROUTE}/${META_CONNECTION}`);
  };

  return (
    <bds-grid direction="column" gap="4" container-fluid padding="half" class="preparation-container">
      <Grid gap="2" direction="column">
        <bds-typo variant="fs-24" margin={false}>
          Seu número está preparado para a API?
        </bds-typo>
        <bds-typo variant="fs-14" data-testid="prepare-connect-text">
          Para conectar à API você precisa ter um <b>número totalmente disponível.</b>
        </bds-typo>
        <bds-typo variant="fs-14">
          A <b>Meta/facebook não permite</b> que um mesmo número utilize <b>app e API ao mesmo tempo.</b>
        </bds-typo>
        <bds-typo variant="fs-14">Você precisa...</bds-typo>
        <bds-typo variant="fs-14">
          • <b> ou apagar a conta do app do whatsapp</b> do número que deseja conectar à API (
          <span className="delete-account-button" data-testid="how-deleted-btn" onClick={() => howDeleteNumber()}>
            veja como apagar sua conta aqui
          </span>
          )
        </bds-typo>
        <bds-typo variant="fs-14">
          • <b>ou usar um número novo sem o app</b> do whatsapp (pode ser um número fixo com linha)
        </bds-typo>
        <div className="preparation-number-button-group">
          <bds-button
            onClick={() => deletedNumber()}
            class="preparation-number-button"
            data-testid="number-deleted-btn"
            variant="primary"
          >
            Já apaguei minha conta
          </bds-button>
          <bds-button onClick={() => newNumberRegistration()} data-testid="new-phone-btn" variant="primary">
            Vou usar um número novo
          </bds-button>
        </div>
        <div className="report-btn">
          <bds-button onClick={() => reportProblem()} variant="secondary">
            Reportar problema
          </bds-button>
        </div>
      </Grid>
    </bds-grid>
  );
};
