import { useHumanServiceContext } from 'contexts/HumanServiceContext';

import { TextContainer } from '../styles';

import tabsTexts from 'texts/tabsTexts.json';
import Switch from 'components/Switch';
import Attendance from '../components/Attendance';
import { TabsPanelContainer } from 'styles/tabs.styles';
import SubmitButton from '../components/SubmitButton';
import AlertsButtonCancel from '../components/AlertsButtonCancel';
import { useState } from 'react';
import Grid from 'components/Grid';
import TabGridContainer from 'components/TabGridContainer';
import OpeningHoursSimulator from '../components/Simulators/OpeningHoursSimulator';

export const OpeningHours = () => {
  const {
    twentyFourHours,
    setTwentyFourHours,
    sunday,
    setSunday,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thursday,
    setThursday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    holiday,
    setHoliday,
  } = useHumanServiceContext();

  const [cancelWarning, setCancelWarning] = useState(false);

  const handleSetTwentyFourHours = async (event: Event) => {
    const input = event.currentTarget as HTMLBdsSwitchElement | null;
    const value = await input?.getValue();

    setTwentyFourHours(value as boolean);
  };

  return (
    <TabGridContainer>
      <Grid xxs="12" md="9" flex-wrap="wrap" direction="row">
        <TabsPanelContainer>
          <Grid xxs="12" md="12" style={{ paddingLeft: 0 }}>
            <TextContainer>
              <bds-typo variant="fs-16" tag="p" bold="semi-bold">
                Personalize os dias, horários e feriados que o seu atendimento humano estará disponível.
              </bds-typo>
            </TextContainer>
          </Grid>
          <Grid xxs="12" md="12" style={{ paddingLeft: 0 }}>
            <Switch
              text={tabsTexts.schedules.twentyFourHours}
              name="twentyFourHours"
              isEmojiSwitch={false}
              checked={twentyFourHours}
              onClick={handleSetTwentyFourHours}
            />
          </Grid>
          <Grid flex-wrap="wrap" direction="row" style={{ paddingLeft: 0 }}>
            {!twentyFourHours && (
              <>
                <Grid xxs="12" md="6" flex-wrap="wrap" style={{ paddingLeft: 0, paddingRight: 16 }}>
                  <Attendance
                    lastInput={false}
                    isHolidays={false}
                    name="Segunda"
                    text={tabsTexts.schedules.days.monday}
                    setDays={setMonday}
                    day={monday}
                  />

                  <Attendance
                    lastInput={false}
                    isHolidays={false}
                    name="Terça"
                    text={tabsTexts.schedules.days.tuesday}
                    setDays={setTuesday}
                    day={tuesday}
                  />

                  <Attendance
                    lastInput={false}
                    isHolidays={false}
                    name="Quarta"
                    text={tabsTexts.schedules.days.wednesday}
                    setDays={setWednesday}
                    day={wednesday}
                  />

                  <Attendance
                    lastInput={false}
                    isHolidays={false}
                    name="Quinta"
                    text={tabsTexts.schedules.days.thursday}
                    setDays={setThursday}
                    day={thursday}
                  />

                  <Attendance
                    lastInput={false}
                    isHolidays={false}
                    name="Sexta"
                    text={tabsTexts.schedules.days.friday}
                    setDays={setFriday}
                    day={friday}
                  />

                  <Attendance
                    lastInput={false}
                    isHolidays={false}
                    name="Sábado"
                    text={tabsTexts.schedules.days.saturday}
                    setDays={setSaturday}
                    day={saturday}
                  />

                  <Attendance
                    lastInput={true}
                    isHolidays={false}
                    name="Domingo"
                    text={tabsTexts.schedules.days.sunday}
                    setDays={setSunday}
                    day={sunday}
                  />
                </Grid>

                <Grid xxs="12" md="6" flex-wrap="wrap" style={{ alignContent: 'flex-start', paddingLeft: 0 }}>
                  <Attendance
                    name="Feriados"
                    text={tabsTexts.schedules.days.holiday}
                    lastInput={true}
                    setDays={setHoliday}
                    isHolidays={true}
                    day={holiday}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </TabsPanelContainer>
        <SubmitButton cancelWarning={cancelWarning} setCancelWarning={setCancelWarning} />
        <AlertsButtonCancel cancelWarning={cancelWarning} setCancelWarning={setCancelWarning} />
      </Grid>
      <Grid md="3" style={{ width: 'auto' }}>
        <OpeningHoursSimulator
        />
      </Grid>
    </TabGridContainer>
  );
};
