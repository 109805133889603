import BotContent from 'components/Simulator/BotContent';
import CTAContent from 'components/Simulator/CTAContent';
import SimulatorContainer from 'components/Simulator/SimulatorContainer';
import UserContent from 'components/Simulator/UserContent';
import React from 'react';

const SatisfactionSurveySimulator: React.FC = () => {

    const renderSatisfactionSurveySimulator = () => {
        return (
            <React.Fragment>
                <BotContent
                    key={'satisfactionSurveySimulator-bot-content-1'}
                    testid="satisfactionSurveySimulator-bot-content-1"
                    time="22:45"
                    message={
                        <p>Antes de finalizar, eu gostaria de entender como foi sua <b>experiência</b>.</p>
                    }
                />
                <BotContent
                    key={'satisfactionSurveySimulator-bot-content-2'}
                    testid="satisfactionSurveySimulator-bot-content-2"
                    time="22:45"
                    message={
                        <p>
                            Numa escala de <b>1 a 5</b>, qual <b>nota</b> você daria ao nosso atendimento?
                            <br /><br />
                            <b>Dica:</b> Considere 5 se foi ótimo e 1 se foi muito ruim.
                        </p>
                    }
                />
                <UserContent
                    key={'satisfactionSurveySimulator-user-content-1'}
                    testid="satisfactionSurveySimulator-user-content-1"
                    time="22:15"
                    message="5"
                />
                <BotContent
                    key={'satisfactionSurveySimulator-bot-content-3'}
                    testid="satisfactionSurveySimulator-bot-content-3"
                    time="22:45"
                    message={
                        <p>
                            Ah que ótimo! Para replicarmos esse atendimento,
                            por favor, diga qual é o <b>principal motivo</b> da sua satisfação.
                        </p>
                    }
                />
                <CTAContent
                    key={'satisfactionSurveySimulator-cta-content-1'}
                    testid="satisfactionSurveySimulator-cta-content-1"
                    message={'Resolveu o problema'}
                />
                <CTAContent
                    key={'satisfactionSurveySimulator-cta-content-2'}
                    testid="satisfactionSurveySimulator-cta-content-2"
                    message={'Me atendeu bem'}
                />
                <CTAContent
                    key={'satisfactionSurveySimulator-cta-content-3'}
                    testid="satisfactionSurveySimulator-cta-content-3"
                    message={'Outro motivo'}
                />
                <UserContent
                    key={'satisfactionSurveySimulator-user-content-2'}
                    testid="satisfactionSurveySimulator-user-content-2"
                    time="22:15"
                    message="Resolveu o problema"
                />
                <BotContent
                    key={'satisfactionSurveySimulator-bot-content-4'}
                    testid="satisfactionSurveySimulator-bot-content-4"
                    time="22:45"
                    message={
                        'Agradeço sua avaliação! Sua opinião é muito importante para que possamos sempre melhorar! 😊'
                    }
                />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <SimulatorContainer>
                {renderSatisfactionSurveySimulator()}
            </SimulatorContainer>
        </React.Fragment>
    );
};

export default SatisfactionSurveySimulator;