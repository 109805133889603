import styled from 'styled-components';

export const ImageContainer = styled.div`
  padding: 80px 80px 80px 0px;
`;

export const InputContainer = styled.div`
  display: flex;
  margin-top: 5px;
`;

export const ContentContainer = styled.div`
  width: 100%;
`;
