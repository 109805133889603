import { FAQField } from '../../types/botMolule/FAQField';

export const validateFAQ = (FAQList: FAQField[]) => {
  let isPassed = true;

  FAQList.forEach(faq => {
    if (faq.topic !== undefined && faq.answer !== undefined) {
      if (faq.topic === '' || faq.answer === '') {
        isPassed = false;
      }
    } else {
      isPassed = false;
    }
  });

  return isPassed;
};
