import { RESOURCES } from 'constants/ResourcesNames';
import { Resource } from 'types/Resource';

interface ResetSatisfactionSurveyProps {
  resources: Resource[];
  setSatisfactionSurvey: React.Dispatch<React.SetStateAction<boolean>>;
}

export const resetSatisfactionSurvey = ({ setSatisfactionSurvey, resources }: ResetSatisfactionSurveyProps) => {
  resources.forEach(resource => {
    const { name, value } = resource;
    if (name === RESOURCES.SATISFACTION_SURVEY) {
      setSatisfactionSurvey(value.toLowerCase() === 'true');
    }
  });
};
