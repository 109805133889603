import BotContent from 'components/Simulator/BotContent';
import SimulatorContainer from 'components/Simulator/SimulatorContainer';
import UserContent from 'components/Simulator/UserContent';
import { useHumanServiceContext } from 'contexts/HumanServiceContext';
import { Container, Icon, TextIcon } from './styles/PreServiceQuestionSimulatorStyles';
import React from 'react';

type IPreServiceQuestionSimulatorProps = {
    isBlur: boolean;
    focusComponent: string;
    indexFocusComponent: string;
};

const PreServiceQuestionSimulator: React.FC<IPreServiceQuestionSimulatorProps> = ({
    isBlur,
    focusComponent,
    indexFocusComponent,
}: IPreServiceQuestionSimulatorProps) => {
    const { preServiceQuestionList } = useHumanServiceContext();

    const renderPreServiceQuestionSimulator = () => {
        return (
            <React.Fragment>
                <UserContent
                    key={'preServiceQuestionSimulator-user-content-1'}
                    testid="preServiceQuestionSimulator-user-content-1"
                    time="22:15"
                    disable
                    message="Falar com atendente"
                />
                <BotContent
                    key={'preServiceQuestionSimulator-bot-content-1'}
                    testid="preServiceQuestionSimulator-bot-content-1"
                    time="22:45"
                    disable
                    message={
                        'Para direcionar o seu atendimento corretamente, escolha uma das opções a seguir:'
                    }
                />
                <BotContent
                    key={'preServiceQuestionSimulator-bot-content-2'}
                    testid="preServiceQuestionSimulator-bot-content-2"
                    time="22:45"
                    disable
                    message={handleMountedPreServiceQuestionList()}
                />
                <UserContent
                    key={'preServiceQuestionSimulator-user-content-2'}
                    testid="preServiceQuestionSimulator-user-content-2"
                    time="22:15"
                    disable
                    message={indexFocusComponent}
                />
                <BotContent
                    key={'preServiceQuestionSimulator-bot-content-3'}
                    testid="preServiceQuestionSimulator-bot-content-3"
                    time="22:45"
                    disable={isBlur}
                    message={focusComponent}
                />
                <BotContent
                    key={'preServiceQuestionSimulator-bot-content-4'}
                    testid="preServiceQuestionSimulator-bot-content-4"
                    time="22:45"
                    disable
                    message={
                        <p>💡 <b>Dica:</b> Por favor, responda em uma <b>única mensagem</b>. 😉</p>
                    }
                />
            </React.Fragment>
        );
    };

    const handleMountedPreServiceQuestionList = () => {
        return preServiceQuestionList.map((preServiceQuestion, index) => {
            return (
                <React.Fragment key={index}>
                    <b key={index}>{`${index + 1}. ${Object.keys(preServiceQuestion)}`}</b>
                    <br />
                </React.Fragment>
            );
        });
    };

    const renderWhenPreServiceWasNotSelected = () => {
        return (
            <Container>
                <Icon>
                    <bds-icon
                        name="button"
                        size="large"
                        style={{
                            width: '48px',
                            height: '48px',
                            maxHeight: '48px',
                            color: '#8C8C8C'
                        }}
                    />
                </Icon>
                <TextIcon>
                    Clique em algum campo para visualizar
                </TextIcon>
            </Container>
        );
    };

    const isPreServiceQuestionWasSelected = () => {
        return focusComponent === '' && isBlur;
    };

    return (
        <React.Fragment>
            <SimulatorContainer>
                {isPreServiceQuestionWasSelected()
                    ? renderWhenPreServiceWasNotSelected()
                    : renderPreServiceQuestionSimulator()}
            </SimulatorContainer>
        </React.Fragment>
    );
};

export default PreServiceQuestionSimulator;