import BotContent from 'components/Simulator/BotContent';
import CTAContent from 'components/Simulator/CTAContent';
import SimulatorContainer from 'components/Simulator/SimulatorContainer';
import UserContent from 'components/Simulator/UserContent';
import React from 'react';

type ICallButtonSimulatorProps = {
  isBlur: boolean;
  buttonMessage: string;
};

const CallButtonSimulator: React.FC<ICallButtonSimulatorProps> = ({
  isBlur,
  buttonMessage,
}: ICallButtonSimulatorProps) => {
  const renderCallButtonSimulator = () => {
    return (
      <React.Fragment>
        <UserContent
          key={'callButton-user-content-1'}
          testid="callButton-user-content-1"
          time="22:15"
          disable
          message="Oi"
        />
        <BotContent
          key={'callButton-bot-content-1'}
          testid="callButton-bot-content-1"
          time="22:45"
          disable
          message={'Olá 👋 Eu sou o assistente virtual de {{empresa}}, e estou aqui para agilizar seu atendimento.'}
        />
        <BotContent
          key={'callButton-bot-content-2'}
          testid="callButton-bot-content-2"
          time="22:45"
          disable
          message={'Digite o número da opção do menu a seguir para continuarmos 👇'}
        />
        <BotContent
          key={'callButton-bot-content-3'}
          testid="callButton-bot-content-3"
          time="22:45"
          disable
          message={
            <p>
              <b>1. Nossos produtos/serviços</b>
              <br />
              <b>2. Quero comprar/contratar</b>
              <br />
              <b>3. Onde nos encontrar</b>
              <br />
              <b>4. Falar no WhatsApp</b>
            </p>
          }
        />
        <BotContent
          key={'callButton-bot-content-4'}
          testid="callButton-bot-content-4"
          time="22:45"
          disable
          message={
            <p>
              Ou então, caso queira <b>conversar com a nossa equipe</b>, selecione o botão abaixo.
            </p>
          }
        />
        <CTAContent
          key={'callButton-cta-content-1'}
          testid="callButton-cta-content-1"
          disable={isBlur}
          message={buttonMessage}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <SimulatorContainer>{renderCallButtonSimulator()}</SimulatorContainer>
    </React.Fragment>
  );
};

export default CallButtonSimulator;
