import { LocalStorageService as _localStorageService } from './../localStorageService/LocalStorageService';
import { ModuleName, PackName } from './../../configs/Environment';
import { DefaultUserTraits } from './models/DefaultUserTraits';
import { ConfigurationService as _configurationService } from './../configurationService/ConfigurationService';
import { ApplicationService as _applicationService } from './../applicationService/ApplicationService';
import { Environments } from './../../constants/Environments';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { Json } from '../../utils/Json';
import { ANALYTICS_KEY_HMG, ANALYTICS_KEY_PRD } from 'constants/TrackingEvents';
import { ILogArgs, json, logger } from 'packs-template-baseweb';

const logArgs: ILogArgs = {
  className: 'AnalyticsService',
};
const analytics = GetAnalyticsBrowser();
const _userTraitsKey = 'userTraits';

function CheckProductionEnvironment(): boolean {
  logArgs.methodName = "CheckProductionEnvironment";
  if (!Environments.IsProduction()) return false;

  return true;
}

/**
 * Get the analytics browser from segment key.
 * For development environment, undefined will be returned
 * @returns AnalyticsBrowser | undefined
 */
function GetAnalyticsBrowser(): AnalyticsBrowser | undefined {
  const ANALYTICS_KEY = CheckProductionEnvironment() ? ANALYTICS_KEY_PRD : ANALYTICS_KEY_HMG;
  return AnalyticsBrowser.load({ writeKey: ANALYTICS_KEY });
}

/**
 * The Track method lets you record actions your users perform.
 * @returns void
 *
 * @exemple ```typescript
 * _analyticsService.Track('Article Completed', {
        title: 'How to Create a Tracking Plan',
        course: 'Intro to Analytics',
    });
    ```
 */
function Track(eventName: string, traits?: any | undefined, includeDefaultTraits = true): void {
  logArgs.methodName = "Track";

  if (includeDefaultTraits) {
    const userTraits = _localStorageService.Get(_userTraitsKey);
    traits = {
      ...traits,
      ...userTraits,
    };
  }

  try {
    analytics!.track(eventName, traits);
  } catch (error) {
    logger.error(`Error on set track '${eventName}': ${error}`, logArgs)
  }
}

function Page(
  category?: string | undefined,
  name?: string | undefined,
  properties?: any | undefined,
  includeDefaultProperties = true,
): void {
  logArgs.methodName = "Page";

  if (includeDefaultProperties) {
    const userTraits = _localStorageService.Get(_userTraitsKey);
    properties = {
      ...properties,
      ...userTraits,
    };
  }

  analytics!.page(category, name, properties);
}

function Identify(id?: string | undefined, traits?: any | undefined, includeDefaultTraits = true): void {
  logArgs.methodName = "Identify";

  if (includeDefaultTraits) {
    const userTraits = _localStorageService.Get(_userTraitsKey);
    traits = {
      ...traits,
      ...userTraits,
    };
  }

  analytics!.identify(id, traits);
}

/**
 * Prepare the default traits for every track
 * @param accountToken The bot key
 * @param userName The user name
 * @param userMail The user email
 * @returns DefaultUserTraits
 */
async function GetUserTraitsAsync(
  botKey: string,
  accountToken: string,
  shortName: string,
  userName: string,
  userMail: string,
): Promise<DefaultUserTraits | undefined> {
  logArgs.methodName = "GetUserTraitsAsync";

  try {
    const application = await GetApplicationInstallation(accountToken, shortName);
    const tenantId = await GetTenantId(botKey, userMail);
    const plan = await GetPlan(accountToken, tenantId);

    const request = new DefaultUserTraits(
      ModuleName,
      application.routerShortName,
      tenantId,
      userName,
      userMail,
      PackName,
      application.applicationId,
      plan,
    );

    return request;
  } catch (error) {
    logger.error(`Error on get user traits | 'error': ${json.format(error)}`, logArgs);
  }
}

async function GetApplicationInstallation(accountToken: string, shortName: string) {

    const applicationInstallation = await _applicationService.getApplicationInstallation(accountToken, shortName);
     return applicationInstallation;
}

async function GetTenantId(routerKey: string, mail: string) {
  const configurations = await _configurationService.GetAcountInformation(routerKey, mail);
  let tenantId = '';

  if (configurations !== undefined) {
    tenantId = configurations.resource.extras.tenantId;
  }

  return tenantId;
}

async function GetPlan(accountToken: string, tenant: string) {
  const tenantsPlan = await _applicationService.getPlan(accountToken, tenant);
  let planName = '';

  if (tenantsPlan !== undefined) {
    planName = tenantsPlan.plan.name;
  }

  return planName;
}

export const AnalyticsService = {
  Track,
  Page,
  Identify,
  GetUserTraitsAsync,
  CheckProductionEnvironment,
};
