import { RESOURCES } from '../../constants/ResourcesNames';
import { Resource } from '../../types/Resource';

interface resetCallButtonProps {
  resources: Resource[];
  setButtonMessage: React.Dispatch<React.SetStateAction<undefined | string>>;
}

export const resetCallButton = ({ resources, setButtonMessage }: resetCallButtonProps) => {
  resources.forEach(resource => {
    const { name, value } = resource;

    if (name === RESOURCES.MESSAGES.BUTTON_MESSAGE) {
      setButtonMessage(value);
    }
  });
};
