import React, { FC, createContext, useState, useContext, useCallback } from 'react';

import { Tabs } from '../types/Tabs';
import { FAQField } from '../types/botMolule/FAQField';
import { Queue } from '../types/botMolule/Queue';
import { Resource } from '../types/Resource';
import tabsTexts from '../texts/tabsTexts.json';
import { MENU_FIELD_TOPIC } from 'pages/BotConfiguration/constants';

export type AppTabsContextData = {
  previousTab: string;
  setPreviousTab: React.Dispatch<React.SetStateAction<string>>;
  returnMessage: undefined | string;
  setReturnMessage: React.Dispatch<React.SetStateAction<undefined | string>>;
  firstContactMessage: undefined | string;
  setFirstContactMessage: React.Dispatch<React.SetStateAction<undefined | string>>;
  buttonMessage: undefined | string;
  setButtonMessage: React.Dispatch<React.SetStateAction<undefined | string>>;
  queueList: Queue[];
  setQueueList: React.Dispatch<React.SetStateAction<Queue[]>>;
  greetings: Tabs;
  setGreetings: React.Dispatch<React.SetStateAction<Tabs>>;
  hasFaq: boolean;
  setHasFaq: React.Dispatch<React.SetStateAction<boolean>>;
  faqList: FAQField[] | undefined;
  setFaqList: React.Dispatch<React.SetStateAction<FAQField[] | undefined>>;
  FAQ: Tabs;
  setFAQ: React.Dispatch<React.SetStateAction<Tabs>>;
  twentyFourHours: boolean;
  setTwentyFourHours: React.Dispatch<React.SetStateAction<boolean>>;
  research: boolean;
  setResearch: React.Dispatch<React.SetStateAction<boolean>>;
  callButton: Tabs;
  setCallButton: React.Dispatch<React.SetStateAction<Tabs>>;
  resources: Resource[];
  setResources: React.Dispatch<React.SetStateAction<Resource[]>>;
  checkIsModified: () => boolean | undefined;
  blurMenuComponent: boolean,
  setBlurMenuComponent: React.Dispatch<React.SetStateAction<boolean>>,
  focusMenuComponent: string,
  setFocusMenuComponent: React.Dispatch<React.SetStateAction<string>>,
  focusTopicValueComponent: string,
  setFocusTopicValueComponent: React.Dispatch<React.SetStateAction<string>>,
  focusMenuValueComponent: string,
  setFocusMenuValueComponent: React.Dispatch<React.SetStateAction<string>>
};

export const AppTabsContext = createContext<AppTabsContextData>({} as AppTabsContextData);

export const AppTabsProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [previousTab, setPreviousTab] = useState(tabsTexts.saudation.name);

  // Saudacao
  const [returnMessage, setReturnMessage] = useState<undefined | string>(undefined);
  const [firstContactMessage, setFirstContactMessage] = useState<undefined | string>(undefined);
  const [greetings, setGreetings] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    handleCloseWarning: () => {
      setGreetings(current => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  // CALL BUTTON
  const [buttonMessage, setButtonMessage] = useState<undefined | string>(undefined);
  const [callButton, setCallButton] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    handleCloseWarning: () => {
      setCallButton(current => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  // FAQ
  const [hasFaq, setHasFaq] = useState(false);
  const [faqList, setFaqList] = useState<FAQField[] | undefined>(undefined);
  const [FAQ, setFAQ] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    handleCloseWarning: () => {
      setFAQ(current => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  // Fila de atendimento
  const [queueList, setQueueList] = useState<Queue[]>([]);

  // Horario
  const [twentyFourHours, setTwentyFourHours] = useState<boolean>(false);

  const [research, setResearch] = useState<boolean>(false);
  const [resources, setResources] = useState<Resource[]>([]);

  const checkIsModified = useCallback(() => {
    switch (previousTab) {
      case tabsTexts.callButton.name:
        return callButton.isModified;
      case tabsTexts.saudation.name:
        return greetings.isModified;
      case tabsTexts.customizableMenu.name:
        return FAQ.isModified;
    }
  }, [FAQ.isModified, callButton.isModified, greetings.isModified, previousTab]);

  // Simulator Menu
  const [blurMenuComponent, setBlurMenuComponent] = useState<boolean>(true);
  const [focusMenuComponent, setFocusMenuComponent] = useState<string>(MENU_FIELD_TOPIC);
  const [focusTopicValueComponent, setFocusTopicValueComponent] = useState<string>('');
  const [focusMenuValueComponent, setFocusMenuValueComponent] = useState<string>('');

  return (
    <AppTabsContext.Provider
      value={{
        previousTab,
        setPreviousTab,
        returnMessage,
        setReturnMessage,
        firstContactMessage,
        setFirstContactMessage,
        buttonMessage,
        setButtonMessage,
        queueList,
        setQueueList,
        greetings,
        setGreetings,
        hasFaq,
        setHasFaq,
        faqList,
        setFaqList,
        FAQ,
        setFAQ,
        twentyFourHours,
        setTwentyFourHours,
        callButton,
        setCallButton,
        research,
        setResearch,
        resources,
        setResources,
        checkIsModified,
        blurMenuComponent,
        setBlurMenuComponent,
        focusMenuComponent,
        setFocusMenuComponent,
        focusTopicValueComponent,
        setFocusTopicValueComponent,
        focusMenuValueComponent,
        setFocusMenuValueComponent
      }}
    >
      {children}
    </AppTabsContext.Provider>
  );
};

export function useAppTabsContext(): AppTabsContextData {
  const context = useContext(AppTabsContext);

  if (!context) {
    throw new Error('use app context must be used within an AppProvider');
  }

  return context;
}
