import { RESOURCES } from 'constants/ResourcesNames';
import { TEXT_TYPE } from 'constants/Application';
import { api } from 'services/requests/Api';
import { Requests } from 'services/requests/Requests';
import { RouterData } from 'types/PluginProps';
import { Tabs } from 'types/Tabs';
import saveResourceData from './saveResourceData';
import { SendTrackProps } from 'types/humanService/SendTrackProps';
import { LoggedAreaTracks } from 'services/humanService/trackings/handleSendTrackings';

interface saveTwentyFourHourServiceProps {
  setAttendanceHours: React.Dispatch<React.SetStateAction<Tabs>>;
  twentyFourHours: boolean;
  trackConfig: SendTrackProps;
  routerData: RouterData;
  keyRouter: string;
}

export const saveTwentyFourHourService = async ({
  setAttendanceHours,
  twentyFourHours,
  trackConfig,
  routerData,
  keyRouter,
}: saveTwentyFourHourServiceProps) => {
  const loggedAreaTracks = new LoggedAreaTracks();

  const request = new Requests(api(keyRouter));

  const responses = await saveResourceData(request, [
    {
      name: RESOURCES.TWENTY_FOUR_HOURS,
      value: twentyFourHours.toString(),
      type: TEXT_TYPE,
    },
  ]);

  setAttendanceHours(current => {
    return {
      ...current,
      isOpenModalSuccess: true,
    };
  });

  await loggedAreaTracks.sendTrackClickButtonSave({
    screenName: 'Horários',
    service24h: true,
    msgWarning: 'none',
    ...trackConfig,
    success: true,
    error: responses.length === 0 ? 'The data was not saved' : 'none',
  });

  return responses;
};
