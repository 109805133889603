import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  padding-top: 32px;
  padding-bottom: 32px;
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 32px;
  margin-bottom: 16px;

  iframe {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const TipsContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  background-color: #1e6bf1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  margin-bottom: 16px;
  position: relative;
  justify-content: space-between;
`;

export const Link = styled.a`
  color: white;
  text-decoration: none;
  margin-top: 16px;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin-bottom: 32px;
`;

export const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  margin-top: 16px;
`;
