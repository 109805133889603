import styled, { css } from 'styled-components';

interface InputTextContainerProps {
  queueListLength: number;
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InputTextContainer = styled.div<InputTextContainerProps>`
  width: 100%;

  ${props =>
    props.queueListLength > 1 &&
    css`
      padding-right: 12px;
    `}
`;

export const ButtonIconContainer = styled.div`
  margin-left: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin: 20px 0 30px 0;
`;
