import { AnalyticsService as _analyticsService } from 'services/analyticsService/AnalyticsService';

type SendTrackProps = {
    error?: string,
    status?: string,
    corporateName?: string,
    tradingName?: string,
    companyAddress?: string,
    legalRepName?: string,
    legalRepCPF?: string,
    legalRepEmail?: string;
};

export class ContractInformationTracks {
    sendTrackContractButton = async ({ corporateName, tradingName, companyAddress,
        legalRepName, legalRepCPF, legalRepEmail, status, error }: SendTrackProps) => {
        _analyticsService.Track('blippacks-management-general-channels-whatsapp-hired-plan', {
            corporateName,
            tradingName,
            companyAddress,
            legalRepName,
            legalRepCPF,
            legalRepEmail,
            status,
            error
        });
    };
}
