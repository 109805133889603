import styled from 'styled-components';

const InformationsWhatsapp = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TimeMessage = styled.span`
  padding-top: 2px;
  opacity: 0.5;
  display: flex;
  font-size: 9px;
  width: 34px;
  height: 11px;
  color: var(--color-content-ghost, #8C8C8C);
  justify-content: end;
`;

const ConfirmationMessage = styled.div`
  display: flex;
  width: 16px;
`;

const Img = styled.img`
  width: 360px;
  border-radius: 16px;
`;

const ContainerMessage = styled.div`
  position: absolute;
  margin-top: 40px;
  width: 360px;
  font-family: 'Roboto', sans-serif;
  height: 640px;
  overflow-y: auto;
`;

export {
  InformationsWhatsapp,
  TimeMessage,
  ConfirmationMessage,
  Img,
  ContainerMessage
};