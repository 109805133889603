import { useEffect, useState, useRef } from 'react';
import MobileHeader from 'components/MobileHeader';
import Sidebar from 'components/Sidebar';
import { LogoutEvent } from 'constants/MicrofrontEndEvents';
import {
  BLIPPACKS_MANAGEMENT_GENERAL_HOME_SIGN_PRO,
  BLIPPACKS_MANAGEMENT_GENERAL_HOME_TEST_BOT,
  PG_LOGOUT,
  PG_OPENED
} from 'constants/TrackingEvents';
import { usePackContext } from 'contexts/PackContext';
import useTrack from 'hooks/useTrack';
import { BrowserView, MobileView } from 'react-device-detect';
import { PublishEvent } from 'services/EventPublisher';
import { Flex, Padding } from 'styles';
import { DesktopContainer, MobileContainer, TitleContainer } from './style';
import { usePackManagerContext } from 'contexts/PackManagerContext';
import { withAuth } from 'contexts/AuthContext';
import RoutersModule from 'pages/ModuleRouters';
import { BLIP_CHAT_CONFIGURATION } from 'constants/ApiRoutes';
import { BlipChatConfiguration } from 'typings/BlipChat';
import { createToastError } from 'services/Toast';
import WhatsappModal from 'pages/channels/components/whatsappModal';
import { useAuth } from 'oidc-react';
import { checkEmailRegistered, directionLeadFindByEmail } from 'services/experienceCluster/Api';
import { PACK_MANAGER_ROUTE, CHANNELS_ROUTE, NUMBER_PREPARATION } from 'constants/Routes';
import { useNavigate } from 'react-router-dom';
import { getUTMParametersFromLocation } from 'utils/queryParams';

const NavigationBars = () => {
  const { profile } = usePackManagerContext();
  const { activeModule } = usePackContext();
  const { track } = useTrack();
  const [blipChatConfig, setBlipChatConfig] = useState<BlipChatConfiguration>();
  const { settings, apiService } = usePackManagerContext();
  const auth = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { pack } = usePackContext();
  let origin = '';
  const logout = () => {
    track(PG_LOGOUT);
    PublishEvent(LogoutEvent);
  };

  const modalRef = useRef<null | HTMLBdsModalElement>(null);

  useEffect(() => {
    track(PG_OPENED);
    lpLinkOpened();
    getBlipChat();
  }, []);

  const BView = BrowserView as any;
  const MView = MobileView as any;

  const fetchBlipChatConfiguration = async () => {
    try {
      const url = `${BLIP_CHAT_CONFIGURATION}/${pack.routerData.shortName}`;
      const configuration = await apiService.get<BlipChatConfiguration>(url);
      setBlipChatConfig(configuration);
      return configuration;
    } catch (error) {
      createToastError('Falha ao obter as configurações do Blip Chat', 'Por favor, tente daqui alguns minutos.');
    }
  };

  const lpLinkOpened = async () => {
    const sourceUtm = getUTMParametersFromLocation();
    if (sourceUtm && !localStorage.getItem('source')) {
      localStorage.setItem('source', JSON.stringify(sourceUtm));
    }

  }

  const getBlipChat = async () => {
    if (auth?.userData?.profile?.email?.length) {
      const response = await directionLeadFindByEmail(auth.userData.profile.email);
      if (response?.data?.leadSource === 'LPMeta') {
        origin = 'Meta'
      } else {
        origin = 'Other'
      }
    }
    new (window as any).BlipChat()
      .withAppKey(process.env.REACT_APP_BLIP_CHAT_APP_KEY)
      .withButton({ 'color': '#0096fa', 'icon': '' })
      .withCustomCommonUrl(process.env.REACT_APP_CUSTOM_COMMON_URL)
      .withAuth({
        authType: (window as any).BlipChat.DEV_AUTH,
        userIdentity: profile?.FullName + 'PackManager',
        userPassword: auth?.userData?.profile?.email,
      })
      .withAccount({
        fullName: profile?.FullName,
        extras: {
          LandingPage: origin,
        }
      })
      .build();
  };

  const openBlipChat = async () => {
    track(BLIPPACKS_MANAGEMENT_GENERAL_HOME_TEST_BOT);
    const blipChatConfiguration = blipChatConfig ?? (await fetchBlipChatConfiguration());
    const urlbase = `${settings.Environment == 'HMG' ? 'hmg-' : ''}chat.blip.ai/`;
    const link = `https://${blipChatConfiguration?.tenantId}.${urlbase}?appKey=${blipChatConfiguration?.appKey}`;

    window.open(link, '_blank');
  };

  async function openModelWpp() {
    track(BLIPPACKS_MANAGEMENT_GENERAL_HOME_SIGN_PRO);
    if (auth?.userData?.profile?.email?.length) {
      setIsLoading(true);
      await checkEmailRegistered(auth.userData.profile.email).then(res => {
        setIsLoading(false);
        if (res.data.emailRegistered) {
          navigate(`${PACK_MANAGER_ROUTE}\\${CHANNELS_ROUTE}\\${NUMBER_PREPARATION}`, { replace: true });
        } else {
          if (modalRef.current) {
            modalRef.current.open = !modalRef.current.open;
          }
        }
      });
    }
  };

  return (
    <>
      <BView>
        <Sidebar />
        {activeModule && (
          <DesktopContainer>
            <TitleContainer>
              <h1 id="title" color="#0747A6">
                {activeModule.title}
              </h1>
              <Flex>
                <div>
                  <bds-button
                    variant="tertiary"
                    icon="builder-publish-bot"
                    onClick={() => {
                      openModelWpp();
                    }}
                  >
                    Assine o Plano PRO
                  </bds-button>
                  {isLoading ? <bds-loading-page /> : ''}
                  <WhatsappModal modalRef={modalRef} />
                </div>
                <Padding>
                  <bds-button
                    variant="primary"
                    icon="builder-test-bot"
                    onClick={() => {
                      openBlipChat();
                    }}
                  >
                    Testar o bot
                  </bds-button>
                </Padding>
                <bds-typo>Olá, {profile?.FullName}&nbsp;</bds-typo>
                <bds-typo onClick={logout} id="logout">
                  (Sair)
                </bds-typo>
              </Flex>
            </TitleContainer>
            {<RoutersModule activeModule={activeModule} />}
          </DesktopContainer>
        )}
      </BView>

      <MView>
        <MobileHeader />
        <MobileContainer>{activeModule && <RoutersModule activeModule={activeModule} />}</MobileContainer>
      </MView>
    </>
  );
};

export default withAuth(NavigationBars);
