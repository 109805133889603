import { useState } from 'react';
import { useAppTabsContext } from 'contexts/TabsContext';
import { FLOWCHAT_BOT_SAW } from 'constants/TrackingEvents';
import useTrack from 'hooks/useTrack';
import ModalIframe from 'components/ModalIframe';

const ButtonFlowBot = () => {
  const linkButtonFlowBot =
    'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FOqSJVH2DuKhbunmoU5o8vR%2FBlip-Go!-Fluxo%3Ftype%3Ddesign%26node-id%3D6542%253A16276%26mode%3Ddesign%26t%3DaRJXIWX0F0okn7ov-1';
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { track } = useTrack();
  const { previousTab } = useAppTabsContext();

  const openButtonFlowBot = () => {
    setOpenModal(true);

    track(FLOWCHAT_BOT_SAW, {
      screenName: previousTab,
    });
  };

  return (
    <>
      <bds-button variant="ghost" icon="robot" onClick={() => openButtonFlowBot()}>
        Veja fluxo completo do bot
      </bds-button>

      {openModal && <ModalIframe src={linkButtonFlowBot} isOpen={openModal} setOpenModal={setOpenModal} />}
    </>
  );
};

export default ButtonFlowBot;
