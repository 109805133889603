import axios from 'axios';
import { CurrentEnvironment } from '../../configs/Environment';

export const api = (auth: string) => {
  const url = CurrentEnvironment === 'production' ? 'https://http.msging.net' : 'https://hmg-http.msging.net';
  return axios.create({
    baseURL: url,
    headers: {
      Authorization: auth,
    },
  });
};
