const ExternalUrls = {
  deskPrd: 'https://desk.blip.ai',
  deskHmg: 'https://hmg-desk.blip.ai',
  blipGoConfigModuleHMG: 'https://take-blip-attendance-pack-plugin-config.hmg-packs.blip.ai/?appData=',
  blipGoConfigModulePRD: 'https://take-blip-attendance-pack-plugin-config.cs.blip.ai/?appData=',
  blipGoHelp: 'https://blipgo.take.net/central-de-ajuda',
  blipGoAppHelp: 'https://play.google.com/store/apps/details?id=com.desk&hl=pt_BR&gl=US',
  blipGoActivateWhatsapp: 'https://blipgo.take.net/central-de-ajuda/canais/ativar-o-whatsapp-business-api',
  blipGoSupport: 'https://blipgo.take.net/central-de-ajuda/relat%C3%B3rios-e-dados/painel-de-monitoramento',
  youtubeHomeVideo: 'https://www.youtube.com/embed/c_HWxWv17tA',
  termsOfUseBlipGo: 'https://www.blip.ai/docs/termos-de-uso-blip-go/',
  whatsappActivationChat: 'https://api.whatsapp.com/send/?phone=5511938055138&text=',
  activeCampaignProd: 'https://packs-blipgo-activecampanign.cs.blip.ai?appData=',
  activeCampaignHmg: 'https://packs-blipgo-activecampanign.hmg-packs.blip.ai?appData=',
};

export default ExternalUrls;
