import styled, { css } from 'styled-components';

interface InputTextContainerProps {
  listLength: number;
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;

  @media screen and (max-width: 900px) {
    flex: 1 3 100%;
  }
`;

interface LabelContainerProps {
  topicIsEmpty: boolean;
}

export const LabelContainer = styled.div<LabelContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;

  ${props =>
    props.topicIsEmpty &&
    css`
      align-items: flex-start;
    `}
`;

export const InputTextContainer = styled.div<InputTextContainerProps>`
  ${props =>
    props.listLength == 1 &&
    css`
      width: 100%;
    `}
  ${props =>
    props.listLength > 1 &&
    css`
      width: 90%;
    `}
`;

export const ButtonIconContainer = styled.div`
  margin-left: 5%;
`;

export const InputContainer = styled.div`
  margin: 20px 0 30px 0;
`;
