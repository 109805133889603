import { getOidcBaseConfig } from 'constants/Oidc';
import { ANALYTICS_KEY_HMG, ANALYTICS_KEY_PRD } from 'constants/TrackingEvents';
import { AuthProvider } from 'oidc-react';
import EmptyRoute from 'pages/EmptyRoute';
import MainContainer from 'pages/MainContainer';
import PackManager from 'pages/PackManager';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { initializeSegment } from 'services/Segment';
import './font_face.scss';
import { PACK_MANAGER_ROUTE } from 'constants/Routes';
import { environment, logger } from 'packs-template-baseweb';


logger.configure();
logger.info(`Current environment: ${environment.currentEnvironment}`);
logger.info(`Current log level: ${logger.currentLevel}`);

const App: React.FC = () => {
  const urlRedirect = window.location.origin + PACK_MANAGER_ROUTE;
  const oidc = getOidcBaseConfig(urlRedirect);

  const isNonAuthAccess = ['/'].includes(window.location.pathname);
  useEffect(() => {
    process.env.REACT_APP_ENV === 'production'
      ? initializeSegment(ANALYTICS_KEY_PRD)
      : initializeSegment(ANALYTICS_KEY_HMG);
  }, []);


  const routesWithAuthProvider = () => (
    <AuthProvider {...oidc}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainContainer />}>
            <Route index element={<EmptyRoute />} />
            <Route path="pack-manager/*" element={<PackManager />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
  return (
    <bds-theme-provider theme={'light'}>
      {isNonAuthAccess ? (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<EmptyRoute />}></Route>
          </Routes>
        </BrowserRouter>
      ) : (
        routesWithAuthProvider()
      )}
    </bds-theme-provider>
  );
};

export default App;
