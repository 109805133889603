import { RESOURCES } from 'constants/ResourcesNames';
import { TEXT_TYPE } from 'constants/Application';
import { api } from 'services/requests/Api';
import { Requests } from 'services/requests/Requests';
import { RouterData } from 'types/PluginProps';
import { Tabs } from 'types/Tabs';
import saveResourceData from './saveResourceData';
import { SendTrackProps } from 'types/humanService/SendTrackProps';
import { LoggedAreaTracks } from 'services/humanService/trackings/handleSendTrackings';

import tabsTexts from 'texts/tabsTexts.json';

interface saveSatisfactionSurveyProps {
  setSatisfactionSurveyTab: React.Dispatch<React.SetStateAction<Tabs>>;
  satisfactionSurvey: boolean;
  trackConfig: SendTrackProps;
  routerData: RouterData;
  keyRouter: string;
}

export const saveSatisfactionSurvey = async ({
  setSatisfactionSurveyTab,
  satisfactionSurvey,
  trackConfig,
  routerData,
  keyRouter,
}: saveSatisfactionSurveyProps) => {
  const loggedAreaTracks = new LoggedAreaTracks();

  const request = new Requests(api(keyRouter));

  const responses = await saveResourceData(request, [
    {
      name: RESOURCES.SATISFACTION_SURVEY,
      value: satisfactionSurvey.toString(),
      type: TEXT_TYPE,
    },
  ]);

  setSatisfactionSurveyTab(current => {
    return {
      ...current,
      isOpenModalSuccess: true,
    };
  });

  await loggedAreaTracks.sendTrackClickButtonSave({
    screenName: tabsTexts.satisfactionSurvey.title,
    msgWarning: 'none',
    ...trackConfig,
    success: true,
    error: responses.length === 0 ? 'The data was not saved' : 'none',
  });

  return responses;
};
