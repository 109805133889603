interface DeleteAttendanceQueueProps {
    queueName: string
    guid: string
}

export const DeleteAttendanceQueue = ({ queueName, guid }: DeleteAttendanceQueueProps) => {
    return {
        id: guid,
        to: 'postmaster@desk.msging.net',
        method: 'delete',
        uri: `/attendance-queues/${queueName}`
    }
}
