import { useCallback, useEffect, useState } from 'react';

import { LoadingContainer, MainContainer } from 'styles/tabs.styles';

import { Queue } from 'types/Queue';

import { useHumanServiceContext } from 'contexts/HumanServiceContext';

import { RESOURCES } from 'constants/ResourcesNames';
import tabsTexts from 'texts/tabsTexts.json';
import { mountKey } from 'utils/mountKey';

import { api } from 'services/requests/Api';
import { Requests } from 'services/requests/Requests';

import { PreServiceQuestion } from './tabs/PreServiceQuestion';
import { AttendanceQueue } from './tabs/AttendanceQueue';
import { OpeningHours } from './tabs/OpeningHours';
import { SatisfactionSurvey } from './tabs/SatisfactionSurvey';

import { Day } from 'types/humanService/Day';

import { resetDays } from 'services/reset/humanService/resetDays';
import { resetQueues } from 'services/reset/humanService/resetQueues';
import { resetPreServiceQuestions } from 'services/reset/humanService/resetPreServiceQuestions';
import { resetSatisfactionSurvey } from 'services/reset/humanService/resetSatisfactionSurvey';

import { LoggedAreaTracks } from 'services/humanService/trackings/handleSendTrackings';

import { validateHours } from 'utils/validates/humanService/validateHours';
import { validateTextInput } from 'utils/validates/validateTextInput';
import { getAttendanceQueues } from 'services/getAttendanceQueues';
import { validateDates } from 'utils/validates/humanService/validateDates';

import { useAuthContext } from 'contexts/AuthContext';
import { BdsTabGroup, BdsTabItem } from 'blip-ds/dist/blip-ds-react/components';
import { WarningTab } from './components/Alerts/warningTab';
import { AlertsTab } from './components/Alerts/AlertsTab';
import GridContent from 'components/GridContent/GridContent';
import { searchResources } from 'services/humanService/searchResources';
import { validateEmail } from 'utils/validates/validateEmail';
import { validateInputWithoutSpecialCharacter } from 'utils/validates/validateInputWithoutSpecialCharacter';

export const HumanService = () => {
  const { userData, routerData } = useAuthContext();

  const {
    preQueueMessage,
    setPreQueueMessage,
    setEmojis,
    queueList,
    setQueueList,

    setPreServiceQuestionList,

    setSatisfactionSurvey,

    twentyFourHours,
    setTwentyFourHours,

    sunday,
    setSunday,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thursday,
    setThursday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    holiday,
    setHoliday,

    attendanceQueueTab,
    setAttendanceQueueTab,
    preServiceQuestionTab,
    setPreServiceQuestionTab,
    attendanceHoursTab,
    setAttendanceHoursTab,
    satisfactionSurveyTab,
    setSatisfactionSurveyTab,

    currentTab,
    setCurrentTab,

    resources,
    setResources,

    request,
    setRequest,

    setKeyDesk,
    setKeyRouter,

    loadingResources,
    setLoadingResources,

    requestRouterResources,
    setRequestWithResources,

    isCanceledAction,
    setIsCanceledAction,
  } = useHumanServiceContext();

  useEffect(() => {
    const transhipmentKey =
      routerData.skillTransbordo && routerData.skillTransbordo.accessKey && routerData.skillTransbordo.shortName
        ? mountKey(routerData.skillTransbordo.shortName, routerData.skillTransbordo.accessKey)
        : undefined;
    setKeyDesk(transhipmentKey || '');

    const routerKey =
      routerData.shortName && routerData.accessKey ? mountKey(routerData.shortName, routerData.accessKey) : undefined;
    setKeyRouter(routerKey || '');

    const request = transhipmentKey
      ? new Requests(api(transhipmentKey))
      : undefined;
    setRequest(request);

    const requestRouterResources = routerKey ? new Requests(api(routerKey)) : undefined;
    setRequestWithResources(requestRouterResources);
  }, [routerData, setKeyDesk, setKeyRouter, setRequest, setRequestWithResources]);

  const [idxRemoved, setIdxRemoved] = useState(0);

  const getAndSetResources = useCallback(
    async (step: string) => {
      switch (step) {
        case 'Pré-atendimento':
          searchResources([RESOURCES.PRE_SERVICE_QUESTIONS], requestRouterResources, setResources, setLoadingResources);
          break;
        case 'Fila de atendimento':
          searchResources(
            [RESOURCES.MESSAGES.PRE_QUEUE, RESOURCES.EMOJI, RESOURCES.PRE_SERVICE_QUESTIONS],
            requestRouterResources,
            setResources,
            setLoadingResources,
          );
          const teams = await getAttendanceQueues(request);

          setQueueList(teams);
          break;
        case tabsTexts.satisfactionSurvey.title:
          searchResources([RESOURCES.SATISFACTION_SURVEY], requestRouterResources, setResources, setLoadingResources);
          break;
        case 'Horários':
          searchResources(
            [
              RESOURCES.DAYS.SATURDAY.END,
              RESOURCES.DAYS.SATURDAY.START,
              RESOURCES.MESSAGES.WORKDAY_WITH_SATURDAY,
              RESOURCES.DAYS.SUNDAY.END,
              RESOURCES.DAYS.SUNDAY.START,
              RESOURCES.DAYS.HOLIDAY.START,
              RESOURCES.DAYS.HOLIDAY.END,
              RESOURCES.HOLIDAY_ATTENDANCE,
              RESOURCES.HOLIDAYS,
              RESOURCES.WORK_SCHEDULE,
              RESOURCES.WORK_DAYS,
              RESOURCES.TWENTY_FOUR_HOURS,
            ],
            requestRouterResources,
            setResources,
            setLoadingResources,
          );
          break;
      }
    },
    [request, setQueueList, requestRouterResources, setResources, setLoadingResources],
  );

  const setInitialInputValues = async () => {
    setQueueList([]);
    if (resources.length > 0) {
      const teams = await getAttendanceQueues(request);
      setQueueList(teams);
      resetDays({
        resources,
        setSunday,
        setMonday,
        setTuesday,
        setWednesday,
        setThursday,
        setFriday,
        setSaturday,
        setHoliday,
        setTwentyFourHours,
      });

      resetQueues({
        resources,
        setEmojis,
        setPreQueueMessage,
      });

      resetPreServiceQuestions({
        setPreServiceQuestionList,
        resources,
      });

      resetSatisfactionSurvey({ resources, setSatisfactionSurvey });
    }
  };

  const handleCurrentTab = (label: string, group: string) => {
    setCurrentTab(label);
    getAndSetResources(label);

    sendTrackTab(userData.fullName || userData.name, group);
  };

  const sendTrackTab = async (merchantName: string, screenName: string) => {
    const loggedAreaTracks = new LoggedAreaTracks();
    await loggedAreaTracks.sendTrackTab({ merchantName, screenName });
  };

  const handleReturnToCurrentTab = () => {
    const tabs = document.getElementsByTagName('bds-tab');

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].label === currentTab) {
        tabs[i].click();
      }
    }
  };

  useEffect(() => {
    if (requestRouterResources && userData) {
      getAndSetResources(tabsTexts.attendanceQueue.title);
      setCurrentTab(tabsTexts.attendanceQueue.title);
      sendStartTrack(userData.fullName || userData.name);
    }
  }, [getAndSetResources, requestRouterResources, userData, setCurrentTab]);

  const sendStartTrack = async (merchantName: string) => {
    const loggedAreaTracks = new LoggedAreaTracks();
    await loggedAreaTracks.sendTrackStartPlugin({ merchantName });
  };

  useEffect(() => {
    setInitialInputValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  const validateQueues = (queues: Queue[]) => {
    let isValid = true;
    if (queues.length === 0) {
      isValid = false;
    } else {
      queues.forEach(queue => {
        if (
          !validateTextInput(queue.name) ||
          !validateEmail(queue.emails) ||
          queue.emails.length === 0 ||
          !validateInputWithoutSpecialCharacter(queue.name)
        ) {
          isValid = false;
        }
      });
    }
    return isValid;
  };

  useEffect(() => {
    if (currentTab === 'Fila de atendimento') {
      setAttendanceQueueTab(current => {
        return {
          ...current,
          isInvalid: !validateQueues(queueList) || !validateTextInput(preQueueMessage),
        };
      });
    }
  }, [queueList, preQueueMessage, currentTab, setAttendanceQueueTab]);

  const validateDays = (days: Day[], twentyFourHours: boolean) => {
    let isValid = true;

    if (!twentyFourHours) {
      isValid = isValid && validateHours(days);

      if (days[7].active) {
        if (days[7].holidays !== undefined) {
          isValid = isValid && validateDates(days[7].holidays);
        } else {
          return false;
        }
      }
    }
    return isValid;
  };

  useEffect(() => {
    if (currentTab === 'Horários') {
      const days = [sunday, monday, tuesday, wednesday, thursday, friday, saturday, holiday];
      setAttendanceHoursTab(current => {
        return {
          ...current,
          isInvalid: !validateDays(days, twentyFourHours),
        };
      });
    }
  }, [
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    holiday,
    twentyFourHours,
    currentTab,
    setAttendanceHoursTab,
  ]);

  const TabChangeEvent = (ev: CustomEvent) => {
    const element = ev.detail as HTMLBdsTabItemElement | null;

    switch (element?.label) {
      case tabsTexts.attendanceQueue.title:
        handleCurrentTab(element?.label, 'attendance-queue');
        break;
      case tabsTexts.preService.title:
        handleCurrentTab(element?.label, 'attendance-pre-attendance');
        break;
      case tabsTexts.satisfactionSurvey.title:
        handleCurrentTab(element?.label, 'attendance-satisfaction-survey');
        break;
      case tabsTexts.schedules.title:
        handleCurrentTab(element?.label, 'attendance-opening-hours');
        break;
    }
  };
  return (
    <MainContainer>
      {loadingResources ? (
        <LoadingContainer style={{ alignSelf: 'center' }}>
          <bds-loading-spinner color="main" size="small" />
        </LoadingContainer>
      ) : (
        <>
          <GridContent>
            <BdsTabGroup align="left" onBdsTabChange={ev => TabChangeEvent(ev)}>
              <BdsTabItem label={tabsTexts.attendanceQueue.title}>
                <AttendanceQueue
                  idxRemoved={idxRemoved}
                  setIdxRemoved={setIdxRemoved}
                  setAttendanceQueue={setAttendanceQueueTab}
                  isCanceledAction={isCanceledAction}
                  setIsCanceledAction={setIsCanceledAction}
                />
              </BdsTabItem>

              <BdsTabItem label={tabsTexts.preService.title}>
                <PreServiceQuestion setPreServiceQuestionTab={setPreServiceQuestionTab} />
              </BdsTabItem>

              <BdsTabItem label={tabsTexts.satisfactionSurvey.title}>
                <SatisfactionSurvey />
              </BdsTabItem>

              <BdsTabItem label={tabsTexts.schedules.title}>
                <OpeningHours />
              </BdsTabItem>
            </BdsTabGroup>
          </GridContent>
          <WarningTab
            attendanceHours={attendanceHoursTab}
            attendanceQueue={attendanceQueueTab}
            preServiceQuestion={preServiceQuestionTab}
          ></WarningTab>

          <AlertsTab
            attendanceHours={attendanceHoursTab}
            attendanceQueue={attendanceQueueTab}
            preServiceQuestion={preServiceQuestionTab}
            satisfactionSurvey={satisfactionSurveyTab}
            setAttendanceHours={setAttendanceHoursTab}
            setAttendanceQueue={setAttendanceQueueTab}
            setPreServiceQuestion={setPreServiceQuestionTab}
            setSatisfactionSurvey={setSatisfactionSurveyTab}
            handleReturnToPreviousTab={handleReturnToCurrentTab}
          ></AlertsTab>
        </>
      )}
    </MainContainer>
  );
};
