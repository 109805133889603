import React, { useEffect, useState } from 'react';
import './styles.scss';
import ChannelsController from 'controller/ChannelsController';
import { useAuthContext } from 'contexts/AuthContext';
import { USER_NEEDS } from 'pages/channels/constants/wppModalInfo';
import { ChannelsTracks } from 'pages/channels/services/trackings/handleSendTrackings';

interface IntentionCardProps {
  isActive: boolean;
  title: string;
  index: number;
  icon: string;
}

interface SelectedOptions {
  [field: string]: any;
}

interface BlipGoIntroductionProps {
  finishBtnPressed: () => void;
  screenTitle?: boolean;

}

export const BlipGoIntroduction = (props: BlipGoIntroductionProps) => {
  const { routerData, accountToken } = useAuthContext();
  const [blipChatLink, setBlipChatLink] = useState('');
  const [userNeeds, setUserNeeds] = useState(USER_NEEDS);
  const channelsTracks = new ChannelsTracks();

  useEffect(() => {
    const channelsController = new ChannelsController(routerData, accountToken || '');
    channelsController.channelsUrl().then(data => {
      setBlipChatLink(data.blipChat);
    });
  }, []);

  const handleUserNeedToggle = (selectedIndex: number) => {
    const updatedItems = userNeeds.map((item, itemIndex) => {
      if (itemIndex === selectedIndex) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setUserNeeds(updatedItems);
  };

  const isUserNeedsChecked = () => {
    return userNeeds.some(item => item.selected);
  };

  const openBlipChat = () => {
    window.open(blipChatLink, '_blank');
    channelsTracks.sendTrackTestBlipChat();
  };

  const IntentionCard = (props: IntentionCardProps) => {
    return (
      <bds-paper
        onClick={() => handleUserNeedToggle(props?.index)}
        class={props?.isActive ? 'intention-card-wrapper active-item' : 'intention-card-wrapper'}
      >
        <bds-grid direction="column" gap="1" style={{ alignItems: 'center' }}>
          <bds-icon size="xxx-large" name={props?.icon} />
          <bds-typo variant="fs-16">{props?.title}</bds-typo>
        </bds-grid>
      </bds-paper>
    );
  };

  const handleFinishButton = () => {
    if (isUserNeedsChecked()) {
      const selectedOptions: SelectedOptions = userNeeds.map(item => {
        return {
          [item.id]: item.selected,
        };
      });
      channelsTracks.sendTrackSeePlans(selectedOptions);
      props.finishBtnPressed();
    }
  };

  return (
    <bds-grid class="blip-go-introduction-container" direction="column" gap="2">
      <bds-typo bold="bold" class="feature-not-included-warning">
        {props?.screenTitle ? 'Este recurso esta disponível apenas no plano PRO' : ''}
      </bds-typo>
      <div className="blip-go-introduction-section-container">
        <div className="blip-go-introduction-section">
          <bds-grid direction="column" gap="2">
            <bds-typo class="color-surface-4" margin={false} bold="bold" variant="fs-32">
              Continue usando gratuitamente
            </bds-typo>
            <bds-typo variant="fs-16">
              Teste já o seu assistente de respostas automáticas para conhecer a proposta do Blip Go!
            </bds-typo>
            <div className="intention-action-container">
              <bds-button data-testid="start-test-btn" arrow={true} variant="ghost" onClick={openBlipChat}>
                Iniciar teste
              </bds-button>
            </div>
          </bds-grid>
        </div>

        <div className="blip-go-introduction-section">
          <bds-grid direction="column" gap="2">
            <bds-typo class="primary-color" margin={false} bold="bold" variant="fs-32">
              Conheça o plano ideal
            </bds-typo>
            <bds-typo variant="fs-16">
              Escolha as opções abaixo de acordo com a necessidade de atendimento da sua empresa:
            </bds-typo>
            <div className="intention-card-container">
              {userNeeds?.map((item: any, index: number) => (
                <IntentionCard
                  isActive={item?.selected}
                  title={item?.title}
                  key={item.title}
                  index={index}
                  icon={item?.icon}
                />
              ))}
            </div>
            <div className="intention-action-container">
              <bds-button
                data-testid="show-plans-btn"
                disabled={!isUserNeedsChecked()}
                onClick={() => handleFinishButton()}
                variant="primary"
              >
                Ver planos
              </bds-button>
            </div>
          </bds-grid>
        </div>
      </div>
    </bds-grid>
  );
};
