interface DeleteAttendantProps {
    attendant: string
    guid: string
}

export const DeleteAttendant = ({ attendant, guid }: DeleteAttendantProps) => {
    return {
        id: guid,
        to: 'postmaster@desk.msging.net',
        method: 'delete',
        uri: `/attendants/${attendant}`
    }
}
