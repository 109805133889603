import styled from 'styled-components';

export const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  position: relative;
  width: 100%;
  padding: 24px 32px 32px 32px;
  margin-bottom: 70px;

  @media screen and (max-width: 900px) {
    padding: 5px;
  }

  @media screen and (min-width: 993px) and (max-width: 1061px) {
    padding: 10px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  margin-top: 32px;
  width: 140px;
`;

export const LoadingContainerSubmitButton = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-right: 50px;
`;

export const TabsPanelContainer = styled.div`
  padding: 32px 32px 32px 32px;
  border: 1px solid #d3dfe6;
  border-radius: 9px;
  width: 100%;
  height: auto;
`;
