import { SendTrackProps } from 'types/humanService/SendTrackProps';
import { AnalyticsService as _analyticsService } from 'services/analyticsService/AnalyticsService';

export class LoggedAreaTracks {
  private status = {
    success: 'success',
    failure: 'failure',
  };

  sendTrackStartPlugin = async ({ merchantName }: SendTrackProps) => {
    _analyticsService.Track('blippacks-loggedarea-settings-pa-opened', {
      merchantName,
    });
  };

  sendTrackClickButtonAddQueue = async ({ success, screenName, error, merchantName }: SendTrackProps) => {
    _analyticsService.Track('blippacks-loggedarea-settings-pa-addqueue', {
      merchantName,
      screenName,
      status: success ? this.status.success : this.status.failure,
      error,
    });
  };

  sendTrackWarningOpen = async ({ screenName, msgWarning, merchantName, activationOption }: SendTrackProps) => {
    _analyticsService.Track('blippacks-loggedarea-settings-pa-warning', {
      activationOption,
      merchantName,
      screenName,
      msgWarning,
    });
  };

  sendTrackClickButtonSave = async ({
    screenName,
    msgWarning,
    merchantName,
    success,
    error,
    numQuestions,
    numQueue,
    statusskill,
    service24h,
  }: SendTrackProps) => {
    _analyticsService.Track('blippacks-loggedarea-settings-pa-saved', {
      merchantName,
      screenName,
      msgWarning,
      status: success ? this.status.success : this.status.failure,
      error,
      numQuestions,
      numQueue,
      statusskill,
      service24h,
    });
  };

  sendTrackClickButtonDelete = async ({ success, screenName, type, error, merchantName }: SendTrackProps) => {
    _analyticsService.Track('blippacks-loggedarea-settings-pa-delete', {
      merchantName,
      screenName,
      status: success ? this.status.success : this.status.failure,
      error,
      type,
    });
  };

  sendTrackClickButtonConfirmDelete = async ({
    success,
    screenName,
    type,
    error,
    merchantName,
    activationOption,
  }: SendTrackProps) => {
    _analyticsService.Track('blippacks-loggedarea-settings-pa-deleteconfirm', {
      activationOption,
      merchantName,
      screenName,
      status: success ? this.status.success : this.status.failure,
      error,
      type,
    });
  };

  sendTrackAlertOpen = async ({ merchantName, activationOption, screenName, msgWarning }: SendTrackProps) => {
    _analyticsService.Track('blippacks-loggedarea-settings-pa-alert', {
      activationOption,
      merchantName,
      screenName,
      msgWarning,
    });
  };

  sendTrackClickButtonEdit = async ({ screenName, activationOption, merchantName }: SendTrackProps) => {
    _analyticsService.Track('blippacks-loggedarea-settings-pa-canceled', {
      merchantName,
      screenName,
      activationOption,
    });
  };

  sendTrackTab = async ({ merchantName, screenName }: SendTrackProps) => {
    _analyticsService.Track(`blippacks-loggedarea-pa-human-${screenName}`, {
      merchantName,
    });
  };
}
