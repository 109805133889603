import React, { useEffect, useState } from 'react';
import { ChannelsTracks } from 'pages/channels/services/trackings/handleSendTrackings';
import { useAuthContext } from 'contexts/AuthContext';
import { usePackContext } from 'contexts/PackContext';
import { EMAIL_TEAM } from 'constants/MailCredentials';
import { MailService as _mailService } from 'services/mailService';
import { MailSubject as _mailsubject } from 'services/MailSubject';
import './styles.scss';
import { WPP_PLANS_INFO } from 'pages/channels/constants/wppModalInfo';
import { ButtonVariant } from 'blip-ds/dist/types/components/button/button';
import Logos from 'assets/logos';
import parse from 'html-react-parser';
import ChannelsController from 'controller/ChannelsController';

interface IWhatsappModal {
  modalRef: React.MutableRefObject<HTMLBdsModalElement | null>;
  finishBtnPressed: () => void;
}

const WhatsappPaywall = ({ finishBtnPressed }: IWhatsappModal) => {
  const { routerData, accountToken, userData } = useAuthContext();
  const { pack } = usePackContext();
  const [blipChatLink, setBlipChatLink] = useState('');
  const channelsTracks = new ChannelsTracks();

  useEffect(() => {
    const channelsController = new ChannelsController(routerData, accountToken || '');
    channelsController.channelsUrl().then(data => {
      setBlipChatLink(data.blipChat);
    });
  }, []);

  interface IModalText {
    [field: string]: any;
  }

  interface IPlanCard {
    plan: IModalText;
    highlight: boolean;
    btnClickFunc: () => void;
  }

  interface IChannelsSection {
    planName: string;
  }

  const openBlipChat = () => {
    window.open(blipChatLink, '_blank');
    channelsTracks.sendTrackTestBot();
  };

  function upgradePlan() {
    (window as any).gtag('event', 'conversion', { send_to: 'AW-874108198/km2kCJXJn5wYEKaq56AD' });
    channelsTracks.sendTrackModalUpgradePaywall();
    const tos = { toName: 'Experience Cluster', toMailAddress: EMAIL_TEAM };
    const html = _mailService.formatUpgradeWAMail(
      userData.FullName,
      userData.Email,
      pack.companyName ? pack.companyName : '',
    );
    _mailService.sendEmail(userData.FullName, tos, _mailsubject.subjectActivation(), html, true);
    finishBtnPressed();
  }

  const RenderChannels = ({ planName }: IChannelsSection) => {
    if (planName === WPP_PLANS_INFO.free.name) {
      return (
        <div className="plan-card-subitem-container">
          <bds-typo variant="fs-16" bold="bold">
            Canais inclusos
          </bds-typo>
          <div className="channels-items-container">
            <div className="side-by-side-content-wrapper ">
              <bds-icon size="x-small" name="instagram" />
              <bds-typo variant="fs-14" bold="bold">
                Instagram
              </bds-typo>
            </div>
            <div className="side-by-side-content-wrapper ">
              <bds-icon size="x-small" name="messenger" />
              <bds-typo variant="fs-14" bold="bold">
                Messenger
              </bds-typo>
            </div>
            <div className="side-by-side-content-wrapper ">
              <bds-icon size="x-small" name="message-ballon" />
              <bds-typo variant="fs-14" bold="bold">
                Chat para site
              </bds-typo>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="plan-card-subitem-container">
          <bds-typo variant="fs-16" bold="bold">
            Canais inclusos
          </bds-typo>
          <bds-typo variant="fs-14">Todos os canais do FREE +</bds-typo>
          <div className="side-by-side-content-wrapper ">
            <img src={Logos.WhatsAppLogoExtended} />
            <span>*</span>
          </div>
        </div>
      );
    }
  };

  const PlanCard = ({ plan, highlight, btnClickFunc }: IPlanCard) => {
    const containerCSSClass = highlight ? 'plan-card-container highlight' : 'plan-card-container';
    const note = parse(plan?.note);

    return (
      <div className={containerCSSClass}>
        <div className="plan-card-subitem-container">
          <div className="side-by-side-content-wrapper ">
            <bds-typo
              class={plan?.name === WPP_PLANS_INFO.pro.name ? 'color-primary' : ''}
              variant="fs-24"
              bold="bold"
              margin={false}
            >
              {plan?.name}
            </bds-typo>
            <bds-typo variant="fs-16">{plan?.status}</bds-typo>
          </div>

          <bds-typo variant="fs-40" margin={false}>
            {plan?.price}
          </bds-typo>
        </div>
        <RenderChannels planName={plan?.name} />
        <div className="plan-card-subitem-container">
          <bds-button
            onClick={() => btnClickFunc()}
            variant={plan?.actionBtn?.variant as ButtonVariant}
            arrow={true}
            data-testid={plan?.actionBtn?.testId}
          >
            {plan?.actionBtn?.title}
          </bds-button>
        </div>

        <div className="plan-card-subitem-container">
          <bds-typo variant="fs-14">{plan?.introduction}</bds-typo>
        </div>
        <hr className="plan-card-separator" />
        <div className="plan-card-subitem-container">
          <bds-typo variant="fs-16" bold="bold">
            {plan?.features?.title}
          </bds-typo>
        </div>

        {plan?.features?.items.map((feature: string) => {
          return (
            <div className="plan-card-subitem-container" key={feature}>
              <bds-typo variant="fs-14">{feature}</bds-typo>
            </div>
          );
        })}
        <div className="plan-card-subitem-container">
          <bds-typo variant="fs-14">{note}</bds-typo>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="header-info">
        <span className="header-title">Escolha o plano de acordo com a necessidade de atendimento da sua empresa!</span>
      </div>
      <div className="modal-container">
        <PlanCard plan={WPP_PLANS_INFO?.free} highlight={false} btnClickFunc={openBlipChat} />
        <PlanCard plan={WPP_PLANS_INFO?.pro} highlight={true} btnClickFunc={upgradePlan} />
      </div>
    </>
  );
};
export default WhatsappPaywall;
