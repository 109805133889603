import { useEffect, useState } from 'react';
import './styles.scss';
import { MainContainer } from 'styles/tabs.styles';
import { ContractInformationInput } from './components/ContractInformationInput';
import { isCPFValid, isNotEmpty, isEmailValid } from './services/validator';
import { CONTRACT_FIELDS, IContractField, PROCURATOR } from './constants/contractFields';
import { ProPlan } from './components/ProPlan';
import { useNavigate } from 'react-router-dom';
import { CHANNELS_ROUTE, PACK_MANAGER_ROUTE, PROCESSING_UPGRADE } from 'constants/Routes';
import { MailService as _mailService } from 'services/mailService';
import { MailSubject as _mailsubject } from 'services/MailSubject';
import { useAuthContext } from 'contexts/AuthContext';
import { EMAIL_TEAM } from 'constants/MailCredentials';
import { ContractInformationTracks } from './services/trackings';
import { UpdateContactHubspot } from 'constants/UpdateContactHubspot';
import { handleSetIsProPlanRequestedResource, handleUpdateContactHubspot } from 'services/HubspotIntegration';
import { mountKey } from 'utils/mountKey';
import { ILogArgs, logger } from 'packs-template-baseweb';
import { useLocation } from "react-router-dom";

export interface IContractInformationInput {
    corporateName: IFormValues;
    companyName: IFormValues;
    companyAddress: IFormValues;
    procuratorName: IFormValues;
    procuratorCPF: IFormValues;
    procuratorEmail: IFormValues;
}

interface IFormValues {
    value: string;
    error: string;
    isValid: boolean;
}

export const ContractInformation = () => {
    const logArgs: ILogArgs = {
        className: 'ContractInformation',
    };

    useEffect(() => {
        pageScroll();
    }, []);

    const { userData, accountToken, routerData } = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();
    const trial = location.state.trial;
    const contractTracks = new ContractInformationTracks();
    const [form, setForm] = useState<IContractInformationInput>({
        corporateName: { value: '', error: 'Este campo é obrigatório', isValid: true },
        companyName: { value: '', error: 'Este campo é obrigatório', isValid: true },
        companyAddress: { value: '', error: 'Este campo é obrigatório', isValid: true },
        procuratorName: { value: '', error: 'Este campo é obrigatório', isValid: true },
        procuratorCPF: { value: '', error: 'Este campo é obrigatório', isValid: true },
        procuratorEmail: { value: '', error: 'Este campo é obrigatório', isValid: true },
    });

    const pageScroll = (timedelay = 0) => {
        let scrollId: any;
        let height = 0;
        const minScrollHeight = 10;
        // eslint-disable-next-line prefer-const
        scrollId = setInterval(() => {
            if (height <= document.body.scrollHeight) {
                window.scrollBy(0, minScrollHeight);
            }
            else {
                clearInterval(scrollId);
            }
            height += minScrollHeight;
        }, timedelay);
    };

    const handleContractChange = (field: string, ev: any) => {
        let isValid = false;
        const { value } = ev.target as HTMLInputElement;
        if (field === 'procuratorCPF' && isCPFValid(value)) {
            isValid = true;
        } else if ((field === 'corporateName' || field === 'companyAddress' ||
            field === 'procuratorName' || field === 'companyName') && isNotEmpty(value)) {
            isValid = true;
        } else if ((field === 'procuratorEmail') && isEmailValid(value)) {
            isValid = true;
        }

        setForm({ ...form, [field]: { ...form[field as keyof IContractInformationInput], value, isValid } });
    };

    const handleFinishContractButton = () => {
        logArgs.methodName = 'handleFinishContractButton';
        let fieldsError = 'Campo  obrigatório: ';
        if (isFormValid()) {
            logger.info('user successfully fullfiled contract form', logArgs);
            sendVerificationMail();
            handleUpdateContactHubspot(UpdateContactHubspot.PlanoPROSolicitado, accountToken ?? '');
            handleSetIsProPlanRequestedResource(mountKey(routerData.shortName, routerData.accessKey));
            contractTracks.sendTrackContractButton({
                corporateName: form.corporateName.value,
                tradingName: form.companyName.value,
                companyAddress: form.companyAddress.value,
                legalRepName: form.procuratorName.value,
                legalRepCPF: form.procuratorCPF.value,
                legalRepEmail: form.procuratorEmail.value,
                status: 'Sucesso'
            });
            navigate(`${PACK_MANAGER_ROUTE}\\${CHANNELS_ROUTE}\\${PROCESSING_UPGRADE}`, { replace: true });
        } else {
            Object.keys(form).forEach(function (key) {
                if (form[key as keyof IContractInformationInput].value === '') {
                    fieldsError += key + ',';
                }
            });
            contractTracks.sendTrackContractButton({
                error: fieldsError,
                status: 'Falha'
            });
            logger.error(`Forms field errors: ${fieldsError}`, logArgs);
        }
    };

    const isFormValid = () => {
        let formValid = true;
        Object.keys(form).forEach(function (key) {
            if (form[key as keyof IContractInformationInput].value === '') {
                formValid = false;
                setForm(prevState => {
                    return {
                        ...prevState, [key]: {
                            ...prevState[key as keyof IContractInformationInput],
                            value: prevState[key as keyof IContractInformationInput].value,
                            isValid: false
                        }
                    };
                });
            }
        });
        return formValid;
    };

    const sendVerificationMail = () => {
        const tos = { toName: 'Experience Cluster', toMailAddress: EMAIL_TEAM };
        const html = _mailService.formatContractMail(
            userData.FullName,
            userData.Email,
            form.corporateName.value,
            form.companyName.value,
            form.companyAddress.value,
            form.procuratorName.value,
            form.procuratorCPF.value,
            form.procuratorEmail.value
        );
        _mailService.sendEmail(userData.FullName, tos, _mailsubject.subjectContract(), html, true);
    };

    return (
        <MainContainer>
            <div className="contract-information-container">
                <ProPlan activateTrial={trial} />
                <bds-grid gap="4" direction="column" padding="half" container-fluid >
                    <div className="contract-information">
                        <bds-typo variant="fs-20" margin={false} bold="bold">Dados da empresa</bds-typo>
                    </div>
                    {CONTRACT_FIELDS.map((field: IContractField) => (
                        <div key={field?.title}>
                            <ContractInformationInput
                                title={field?.title}
                                value={form[field?.fieldName as keyof IContractInformationInput].value}
                                error={form[field?.fieldName as keyof IContractInformationInput].error}
                                danger={!form[field?.fieldName as keyof IContractInformationInput].isValid}
                                onChange={ev => handleContractChange(field?.fieldName, ev)}
                            />
                        </div>
                    ))}
                    <div>
                        <bds-typo variant="fs-20" margin={false} bold="bold">Dados do representante legal</bds-typo>
                    </div>
                    {PROCURATOR.map((field: IContractField) => (
                        <div key={field?.title}>
                            <ContractInformationInput
                                title={field?.title}
                                value={form[field?.fieldName as keyof IContractInformationInput].value}
                                error={form[field?.fieldName as keyof IContractInformationInput].error}
                                danger={!form[field?.fieldName as keyof IContractInformationInput].isValid}
                                onChange={ev => handleContractChange(field?.fieldName, ev)}
                            />
                        </div>
                    ))}
                    <div className="contract-button">
                        <bds-button data-testid="contract-button" variant="primary"
                            onClick={() => handleFinishContractButton()}>
                            CONTRATAR PLANO
                        </bds-button>
                    </div>
                </bds-grid>
            </div>
        </MainContainer>
    );
};
