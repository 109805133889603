import { useAuth } from 'oidc-react';
import React, { createContext, useEffect, useState } from 'react';
import { Profile } from 'types/OIDCProfile';
import { IAppData, RouterData } from 'types/PluginProps';
import { usePackContext } from './PackContext';
import { usePackManagerContext } from './PackManagerContext';
import { AnalyticsService as _analyticsService } from 'services/analyticsService/AnalyticsService';
import { mountKey } from 'utils/mountKey';
import { LocalStorageService as _localStorageService } from 'services/localStorageService/LocalStorageService';
import { ILogArgs, logger } from 'packs-template-baseweb';

const logArgs: ILogArgs = {
  className: 'AuthContext'
}

type AppContextData = {
  userData: Profile;
  activationOption?: string | undefined;
  routerData: RouterData;
  accountToken?: string;
};

type ContextProps = {
  appData: IAppData;
};

export const AuthContext = createContext<AppContextData>({} as AppContextData);

export const AuthProvider: React.FC<{ children: React.ReactNode } & ContextProps> = ({ children, appData }) => {
  const [accountToken, setAccountToken] = useState<string>('');
  const [userData, setUserData] = useState<Profile>({} as Profile);
  const [routerData, setRouterData] = useState<RouterData>({} as RouterData);
  const [activationOption, setActivationOption] = useState('');

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) {
      setAccountToken(appData?.accountToken || '');
      setUserData(appData?.profile as Profile);
      setRouterData({
        accessKey: appData?.routerAccessKey || '',
        shortName: appData?.routerShortName || '',
        skillTransbordo: {
          accessKey: appData?.transhipmentAccessKey || '',
          shortName: appData?.transhipmentShortName || '',
        },
      });
      setActivationOption('');
    }

    return () => {
      isMounted = true;
    };
  }, [appData]);

  return (
    <AuthContext.Provider
      value={{
        userData,
        activationOption,
        routerData,
        accountToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthContext(): AppContextData {
  logArgs.methodName = "useAuthContext";

  const context = React.useContext(AuthContext);

  if (!context) {
    logger.fatal(`Error on create AuthContext`, logArgs);
    throw new Error('use app context must be used within an AppProvider');
  }

  return context;
}

type IAnalyticsAppData = {
  profile?: Profile;
  routerAccessKey: string;
  routerShortName: string;
  transhipmentAccessKey: string;
  transhipmentShortName: string;
  accountToken: string;
};

function analyticsServiceUserTraits(appData: IAnalyticsAppData) {
  logArgs.methodName = "analyticsServiceUserTraits";
  const _userTraits = 'userTraits';

  const routerKey =
    appData.routerShortName && appData.routerAccessKey
      ? mountKey(appData.routerShortName, appData.routerAccessKey)
      : undefined;

  _analyticsService
    .GetUserTraitsAsync(
      routerKey || '',
      appData.accountToken,
      appData.routerShortName,
      appData.profile?.fullName || appData.profile?.name,
      appData.profile?.email || '',
    )
    .then(resp => {
      logger.info('Adding user traits to local storage', logArgs);
      _localStorageService.Add(_userTraits, resp);
    })
    .catch( err => logger.error(`Error on get user traits: ${err}`, logArgs));
}

export function withAuth<T extends object>(Component: React.ComponentType<T>) {
  return function HOC(props: T) {
    const { pack } = usePackContext();
    const auth = useAuth();
    const { accessToken } = usePackManagerContext();
    const appData: IAnalyticsAppData = {
      profile: auth.userData?.profile,
      routerAccessKey: pack.routerData.accessKey,
      routerShortName: pack.routerData.shortName,
      transhipmentAccessKey: pack.routerData.skillTransbordo?.accessKey,
      transhipmentShortName: pack.routerData.skillTransbordo?.shortName,
      accountToken: accessToken,
    };
    analyticsServiceUserTraits(appData);
    return (
      <AuthProvider appData={appData}>
        <Component {...props} />
      </AuthProvider>
    );
  };
}
