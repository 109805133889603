export const ModuleIds = {
  desk: '1',
  blipGoHome: '2',
  configuration: '5',
  whatsAppProfile: '6',
  channels: '7',
  blipGoConfig: '8',
  blipGoChannels: '9',
  blipGoHelp: '10',
  blipGoBot: '11',
  blipGoActiveMessage: '12',
};
