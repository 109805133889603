import Grid from 'components/Grid/Grid';
import React, { useEffect, useState } from 'react';
import { PhoneNumber, WhatsAppInfo } from 'typings/WhatsAppActivation';
import './styles.scss';
import { createToastError, createToastSuccess } from 'services/Toast';
import { ACTIVATE_WHATSAPP_ROUTE } from 'constants/ApiRoutes';
import useGraphApi from 'hooks/useGraphApi';
import { usePackManagerContext } from 'contexts/PackManagerContext';
import { usePackContext } from 'contexts/PackContext';
import { HUBSPOT_ERROR_CODE } from 'pages/MetaConnection/constants/whatsAppActivationErrorCodes';
import { MetaConnectiontTracks } from '../../trackings/MetaConnectionTracks';
import { WhatsappActivationFooter } from 'components/WhatsappActivationFooter';
import ExternalUrls from 'constants/ExternalUrls';
import { useNavigate } from 'react-router-dom';
import { CHANNELS_ROUTE, PACK_MANAGER_ROUTE } from 'constants/Routes';
import { ILogArgs, logger } from 'packs-template-baseweb';

type Props = {
  metaUserToken: any;
};

export const WabaActivation = ({ metaUserToken }: Props) => {
  const [whatsAppInfo, setWhatsAppInfo] = useState<WhatsAppInfo>();
  const [connectBtnLoading, setConnectBtnLoading] = useState(false);
  const [isChannelActive, setIsChannelActive] = useState(false);
  const [pin, setPin] = useState<string>('');
  const { getWabas } = useGraphApi();
  const { apiService } = usePackManagerContext();
  const { pack, activateWppChannel } = usePackContext();
  const pinMinLength = 6;
  const onlyNumberRegex = /^\d+$/;
  const navigate = useNavigate();

  const logArgs: ILogArgs = {
    className: 'WabaActivation',
  };

  useEffect(() => {
    logger.info('waba Activation page loaded', logArgs);
    fetchUserInformations(metaUserToken);
  }, [metaUserToken]);

  const handlePinInput = (event: any) => {
    const { value } = event.target as HTMLInputElement;
    if (onlyNumberRegex.test(value) && value.length <= pinMinLength) {
      setPin(value);
    }
  };

  const handleSelectedNumber = (phoneNumber: PhoneNumber) => {
    setWhatsAppInfo((prev: any) => ({
      ...prev,
      selectedNumber: phoneNumber,
    }));
  };

  const fetchUserInformations = async (token: string) => {
    logArgs.methodName = "fetchUserInformations";
    const { wabas, applicationName } = await getWabas(token);
    const phoneNumbers = wabas.map(waba => {
      const phoneInfo = waba.phoneNumbers.reduce((a: any, b: any) => a.concat(b));
      return {
        ...phoneInfo,
        wabaId: waba.id,
      };
    });
    const uniquePhoneNumbers = phoneNumbers
      .filter((value: any, index: any, array: any) => array.indexOf(value) === index)
      .sort();

    setWhatsAppInfo({
      userPhoneNumbers: uniquePhoneNumbers,
      applicationName: applicationName,
      wabas: wabas,
    });
  };

  const activateWhatsApp = async () => {
    logArgs.methodName = "activateWhatsApp";
    if (isWabaInfoValid()) {
      setConnectBtnLoading(true);
      apiService
        .post(ACTIVATE_WHATSAPP_ROUTE, {
          shortName: pack.routerData.shortName,
          phoneNumberId: whatsAppInfo?.selectedNumber?.id,
          wabaId: whatsAppInfo?.selectedNumber?.wabaId,
          pin: pin,
        })
        .then(() => {
          activateWppChannel();
          setIsChannelActive(true);
          logger.info('WhatsApp number activated successfully', logArgs);
          MetaConnectiontTracks.sendTrackMetaConnectApiClick({
            status: 'Sucesso',
            phoneNumber: whatsAppInfo?.selectedNumber?.displayPhoneNumber,
          });
          createToastSuccess('Sucesso!', 'O canal foi ativado :-)');
          navigate(`${PACK_MANAGER_ROUTE}\\${CHANNELS_ROUTE}`);
        })
        .catch((error: any) => {
          const errorPayload = {
            phoneNumber: whatsAppInfo?.selectedNumber?.displayPhoneNumber,
            status: 'Falha',
            error: '',
          };
          error.response?.data?.Error;
          if (error.response?.data?.Error?.errorCode === HUBSPOT_ERROR_CODE) {
            errorPayload.error = 'Erro causado pelo ticket do Hubspot';
            MetaConnectiontTracks.sendTrackMetaConnectApiClick(errorPayload);
          } else {
            errorPayload.error = 'Erro causado pela Api do WhatsApp';
            MetaConnectiontTracks.sendTrackMetaConnectApiClick(errorPayload);
          }
          setConnectBtnLoading(false);
          createToastError(
            'Erro na ativação do WhatsApp',
            error.response?.data?.Error?.Message || 'Tente novamente mais tarde',
          );
          logger.error(`Error activating WhatsApp.(${error.response?.data?.Error?.Message})`, logArgs)
        });
    } else {
      createToastError('Erro na ativação do WhatsApp', 'Verifique se os campos estão preenchidos corretamente.');
      logger.error('Error activating WhatsApp, check the fields.', logArgs)
    }
  };

  const sendReportTracking = () => {
    MetaConnectiontTracks.sendTrackMetaReportProblem({
      screenName: 'Conexão com whatsapp api',
    });
    window.open(
      `${ExternalUrls.whatsappActivationChat}Gostaria de reportar um *problema* no *cadastramento na Meta*`,
      '_blank',
    );
  };

  const isWabaInfoValid = () => {
    const isPinValid = pin?.length === 6;
    const isPhoneSelected = whatsAppInfo?.selectedNumber?.id !== '';
    return isPhoneSelected && isPinValid;
  };

  return (
    <Grid direction="column" gap="3" margin="t-1">
      <bds-typo variant="fs-24" margin={false}>
        Conexão com o Whatsapp API
      </bds-typo>
      {isChannelActive ? (
        <>
          <bds-typo variant="fs-14">
            Número de telefone conectado: {whatsAppInfo?.selectedNumber?.displayPhoneNumber}
          </bds-typo>
        </>
      ) : (
        <>
          <bds-typo variant="fs-14">Escolha o número de telefone*</bds-typo>
          <div className="meta-input-container">
            <bds-select
              danger={!whatsAppInfo?.selectedNumber?.displayPhoneNumber}
              error-message="Este campo é obrigatório"
              data-testid="meta-phone-dropdown"
            >
              {whatsAppInfo?.userPhoneNumbers?.map((number: PhoneNumber) => {
                return (
                  <bds-select-option
                    placeholder="Selecionar número"
                    key={number?.id}
                    value={number?.displayPhoneNumber}
                    onClick={() => handleSelectedNumber(number)}
                  >
                    {number?.displayPhoneNumber}
                  </bds-select-option>
                );
              })}
            </bds-select>
          </div>
          <bds-typo variant="fs-14">Crie um código PIN para sua segurança*</bds-typo>
          <div className="meta-input-container">
            <bds-input
              data-testid="meta-phone-pin"
              value={pin}
              type="number"
              min="0"
              danger={pin?.length < 6}
              error-message="Este campo é obrigatório"
              onInput={ev => handlePinInput(ev)}
              placeholder="Crie um código númerico de 6 digitos"
            ></bds-input>
          </div>
        </>
      )}

      <bds-button disabled={connectBtnLoading} onClick={() => activateWhatsApp()}>
        Conectar ao Whatsapp Api
      </bds-button>
      <WhatsappActivationFooter linkPressed={() => sendReportTracking()} linkTitle="Reportar problema" />
    </Grid>
  );
};
