import styled from 'styled-components';

const CTAMessage = styled.div`
  margin-rigth: 5px;
  width: 190px;
  margin-top: 2px;
  margin-left: 32px;
`;

const CTABodyMessage = styled.div`
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.10);
  color: #00A5F4;
  font-size: 14px;
  padding: 7px 10px;
  border-radius: var(--space-1, 8px);
  background: var(--color-bright, #FFF);
  display: flex;
  justify-content: center;
`;

export {
  CTAMessage,
  CTABodyMessage,
};