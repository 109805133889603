import React from 'react';
import './styles.scss';

interface IContractInformationInput {
  title: string;
  value: string;
  onChange: (e: any) => void;
  error: string;
  danger: boolean;
}

export const ContractInformationInput = ({ title, value, onChange, error, danger }: IContractInformationInput) => {
  return (
    <div>
      <bds-typo variant="fs-14">
        {title}
      </bds-typo>
      <div className="contract-input-container">
        <bds-input value={value} error-message={error} danger={danger} onInput={onChange} />
      </div>
    </div>
  );
};
