import React, { FC } from 'react';
import { Module } from 'typings/Module';

import { Row } from './style';
type DrawerItemProps = {
  module: Module;
  onClick: (module: Module) => void;
};

const DrawerItem: FC<DrawerItemProps> = ({ module, onClick }) => {
  return (
    <>
      <Row onClick={() => onClick(module)}>
        <bds-icon name={module.iconName} />
        {module.title}
      </Row>
    </>
  );
};

export default DrawerItem;
