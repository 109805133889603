import styled from 'styled-components';
import {
  InformationsWhatsapp,
  TimeMessage
} from 'components/Simulator/style';

const BotMessage = styled.div`
  display: flex;
  margin-rigth: 5px;
  width: auto;
  max-width: 70%;
  margin-top: 6px;
  margin-left: 32px;
`;

const BotBodyMessage = styled.div`
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.10);
  color: #040404;
  font-size: 14px;
  padding: 7px 10px;
  border-radius: var(--space-1, 8px);
  background: var(--color-bright, #FFF);
  word-wrap: break-word;
`;

const BotBalloonPoint = styled.img`
  position: absolute;
  margin-left: -6px;
`;

export {
  BotMessage,
  BotBodyMessage,
  BotBalloonPoint,
  InformationsWhatsapp,
  TimeMessage
};