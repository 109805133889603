import BotContent from 'components/Simulator/BotContent';
import CTAContent from 'components/Simulator/CTAContent';
import SimulatorContainer from 'components/Simulator/SimulatorContainer';
import UserContent from 'components/Simulator/UserContent';
import React from 'react';
import {
    MENU_FIELD_TOPIC
} from '../../constants';
import { useAppTabsContext } from 'contexts/TabsContext';

const MenuSimulator: React.FC = () => {
    const {
        faqList,
        blurMenuComponent,
        focusMenuComponent,
        focusTopicValueComponent,
        focusMenuValueComponent
    } = useAppTabsContext();

    const renderMenuFieldAnswerComponent = () => {
        return (
            <React.Fragment>
                {renderMenuFieldTopicComponent(true)}
                <UserContent
                    key={'menuFieldAnswer-user-content-1'}
                    testid="menuFieldAnswer-user-content-1"
                    time="22:15"
                    disable
                    message={focusTopicValueComponent}
                />
                <BotContent
                    key={'menuFieldAnswer-bot-content-1'}
                    testid="menuFieldAnswer-bot-content-1"
                    time="22:45"
                    disable={blurMenuComponent}
                    message={
                        <p>{focusMenuValueComponent}</p>
                    }
                />
            </React.Fragment>
        );
    };

    const renderMenuFieldTopicComponent = (isBlur: boolean) => {
        return (
            <React.Fragment>
                <UserContent
                    key={'menuFieldTopic-user-content-1'}
                    testid="menuFieldTopic-user-content-1"
                    time="22:15"
                    disable
                    message="Oi"
                />
                <BotContent
                    key={'menuFieldTopic-bot-content-2'}
                    testid="menuFieldTopic-bot-content-2"
                    time="22:45"
                    disable
                    message={
                        'Olá 👋 Eu sou o assistente virtual de {{empresa}}, e estou aqui para agilizar seu atendimento.'
                    }
                />
                <BotContent
                    key={'menuFieldTopic-bot-content-3'}
                    testid="menuFieldTopic-bot-content-3"
                    time="22:45"
                    disable
                    message={
                        'Digite o número da opção do menu a seguir para continuarmos 👇'
                    }
                />
                <BotContent
                    key={'menuFieldTopic-bot-content-4'}
                    testid="menuFieldTopic-bot-content-4"
                    time="22:45"
                    disable={isBlur}
                    message={
                        <p>{handleMountedFaqList()}</p>
                    }
                />
                <BotContent
                    key={'menuFieldTopic-bot-content-5'}
                    testid="menuFieldTopic-bot-content-5"
                    time="22:45"
                    disable
                    message={
                        <p>Ou então, caso queira <b>conversar com a nossa equipe</b>, selecione o botão abaixo.</p>
                    }
                />
                <CTAContent
                    key={'menuFieldTopic-cta-content-1'}
                    testid="menuFieldTopic-cta-content-1"
                    disable
                    message="Falar com Atendente"
                />
            </React.Fragment>
        );
    };

    const handleMountedFaqList = () => {
        return faqList?.map((faq, index) => {
            return (
                <React.Fragment key={index}>
                    <b key={index}>{`${index + 1}. ${faq.topic}`}</b>
                    <br />
                </React.Fragment>
            );
        });
    };

    return (
        <React.Fragment>
            <SimulatorContainer>
                {focusMenuComponent === MENU_FIELD_TOPIC
                    ? renderMenuFieldTopicComponent(blurMenuComponent)
                    : renderMenuFieldAnswerComponent()}
            </SimulatorContainer>
        </React.Fragment>
    );
};

export default MenuSimulator;