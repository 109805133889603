import Logos from 'assets/logos';
import SidebarItem from 'components/SiderbarItem';
import { PG_OPEN_MODULE } from 'constants/TrackingEvents';
import { usePackContext } from 'contexts/PackContext';
import useTrack from 'hooks/useTrack';
import { Module } from 'typings/Module';
import { BottomConfigSection, DesktopSidebar, ModulesSection } from './style';
import { useNavigate } from 'react-router-dom';
import {
  BOT_ROUTE,
  CHANNELS_ROUTE,
  DESK_ROUTER,
  HUMAN_SERVICE_ROUTE,
  PACK_MANAGER_ROUTE,
  ACTIVE_MESSAGE,
} from 'constants/Routes';

const Sidebar = () => {
  const { setActiveModule } = usePackContext();
  const { modules } = usePackContext();
  const { track } = useTrack();

  const navigate = useNavigate();

  const handleOnClick = (packMenuItem: Module) => {
    track(PG_OPEN_MODULE, {
      moduleName: packMenuItem.title,
      moduleInternal: packMenuItem.isInternalModule ?? false,
    });

    if (packMenuItem.isExternalLink) {
      window.open(packMenuItem.url, '_blank');
    } else {
      setActiveModule(packMenuItem);
      if (packMenuItem.title == 'Atendimento Humano') {
        navigate(`${PACK_MANAGER_ROUTE}\\${HUMAN_SERVICE_ROUTE}`);
      } else if (packMenuItem.title == 'Bot') {
        navigate(`${PACK_MANAGER_ROUTE}\\${BOT_ROUTE}`);
      } else if (packMenuItem.title == 'Canais de atendimento') {
        navigate(`${PACK_MANAGER_ROUTE}\\${CHANNELS_ROUTE}`);
      } else if (packMenuItem.title == 'Central de atendimento') {
        navigate(`${PACK_MANAGER_ROUTE}\\${DESK_ROUTER}`);
      } else if (packMenuItem.title == 'Começo') {
        navigate(`${PACK_MANAGER_ROUTE}`);
      } else if (packMenuItem.title == 'Mensagens Ativas') {
        navigate(`${PACK_MANAGER_ROUTE}\\${ACTIVE_MESSAGE}`);
      }
    }
  };

  const SideMenuItems = () => {
    return (
      <>
        {modules.map((module, index) => (
          <SidebarItem key={index} module={module} onClick={() => handleOnClick(module)} />
        ))}
      </>
    );
  };

  return (
    <DesktopSidebar>
      <img src={Logos.BlipGoLogo} alt="Blip Go!" />
      <ModulesSection>
        <SideMenuItems />
      </ModulesSection>
      <BottomConfigSection>
        <bds-typo variant="fs-12" style={{ color: 'white' }}>
          Versão
        </bds-typo>
        <bds-typo variant="fs-12" style={{ color: 'white' }}>
          0.3.0
        </bds-typo>
      </BottomConfigSection>
    </DesktopSidebar>
  );
};

export default Sidebar;
