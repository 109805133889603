import { PHONE_REGEX, URL_REGEX } from '../constant/regex';

export const isCnpjValid = (cnpj: string) => {
  const CNPJ_LENGTH = 14;
  const cnpjClean = cnpj.replace(/[^\d]+/g, '');

  if (cnpjClean.length !== CNPJ_LENGTH) {
    return false;
  }

  const invalidCnpjs = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ];

  if (invalidCnpjs.includes(cnpjClean)) {
    return false;
  }

  let sum = 0;
  let weight = 2;
  let digit;

  for (let i = 11; i >= 0; i--) {
    sum += parseInt(cnpjClean.charAt(i)) * weight;
    weight = weight === 9 ? 2 : weight + 1;
  }

  const mod = sum % 11;
  digit = mod < 2 ? 0 : 11 - mod;

  if (parseInt(cnpjClean.charAt(12)) !== digit) {
    return false;
  }

  sum = 0;
  weight = 2;

  for (let i = 12; i >= 0; i--) {
    sum += parseInt(cnpjClean.charAt(i)) * weight;
    weight = weight === 9 ? 2 : weight + 1;
  }

  const mod2 = sum % 11;
  digit = mod2 < 2 ? 0 : 11 - mod2;

  if (parseInt(cnpjClean.charAt(13)) !== digit) {
    return false;
  }

  return true;
};

export const isPhoneValid = (phone: string) => {
  phone = phone.replace(/[^0-9]/g, '');
  return PHONE_REGEX.test(phone);
};

export const isUrlValid = (url: string) => {
  return URL_REGEX.test(url);
};

export const phoneMask = (value: any) => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};
export const cpfOrCnpjMask = (value: string) => {
  value = value.replace(/\D/g, '');

  if (value.length <= 11) {
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    value = value.replace(/^(\d{2})(\d)/, '$1.$2');
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return value;
};