import React from 'react';
import { ChannelsTracks } from 'pages/channels/services/trackings/handleSendTrackings';
import { useCallback, useEffect } from 'react';
import { MODAL_TEXTS } from 'pages/channels/constants/modalTexts';
import './styles.scss';

interface IModalChannel {
  channel: string;
  activateUrl: string;
  renderModal: boolean;
  imageModal: string;
  modalRef: React.MutableRefObject<HTMLBdsModalElement | null>;
}
const ModalChannel = ({ activateUrl, renderModal, channel, imageModal, modalRef }: IModalChannel) => {
  const channelsTracks = new ChannelsTracks();

  const handleCloseModal = useCallback(
    (pressedButton?: string) => {
      if (modalRef.current) {
        modalRef.current.open = !modalRef.current.open;
      }

      if (pressedButton) {
        channelsTracks.sendTrackModalCloseActivateChannel({
          channel,
          button: pressedButton,
        });
      }
    },
    [channelsTracks, channel, modalRef],
  );

  function redirect() {
    window.open(activateUrl, '_blank');
    channelsTracks.sendTrackModalConfirmActivateChannel({ channel });

    handleCloseModal();
  }

  useEffect(() => {
    modalRef.current?.addEventListener('bdsModalChanged', (event: any) => {
      if (event.detail.modalStatus === 'closed') {
        channelsTracks.sendTrackModalCloseActivateChannel({
          channel,
          button: 'X',
        });
      }
    });
  }, []);

  return renderModal ? (
    <bds-modal ref={modalRef} closeButton={true} size="dynamic">
      <div className="modal">
        <div className="modal-image">
          <img src={imageModal} alt={channel} />
        </div>
        <div className="modal-text">
          <bds-typo variant="fs-20" bold="bold" margin>
            {MODAL_TEXTS[channel].title}
          </bds-typo>
          <bds-typo variant="fs-14" bold="regular" margin>
            {MODAL_TEXTS[channel].info1a} <strong>{MODAL_TEXTS[channel].info1b}</strong>
          </bds-typo>
          <br />
          <bds-typo variant="fs-14" bold="regular" margin>
            {MODAL_TEXTS[channel].info2}
          </bds-typo>
          <br />
          <bds-typo variant="fs-14" bold="regular" margin>
            {MODAL_TEXTS[channel].info3}
          </bds-typo>
        </div>
      </div>

      <bds-modal-action>
        <div className="modal-button">
          <bds-button variant="secondary" onClick={() => handleCloseModal('Voltar')}>
            Cancelar
          </bds-button>
          <bds-button style={{ marginLeft: 16 }} onClick={() => redirect()}>
            {MODAL_TEXTS.Action.button.redirect} {channel}
          </bds-button>
        </div>
      </bds-modal-action>
    </bds-modal>
  ) : (
    <></>
  );
};

export default ModalChannel;
