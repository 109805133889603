import { RESOURCES } from 'constants/ResourcesNames';
import { Resource } from 'types/Resource';
import { Day } from 'types/humanService/Day';

interface ResetDaysProps {
  resources: Resource[];
  setSunday: React.Dispatch<React.SetStateAction<Day>>;
  setMonday: React.Dispatch<React.SetStateAction<Day>>;
  setTuesday: React.Dispatch<React.SetStateAction<Day>>;
  setWednesday: React.Dispatch<React.SetStateAction<Day>>;
  setThursday: React.Dispatch<React.SetStateAction<Day>>;
  setFriday: React.Dispatch<React.SetStateAction<Day>>;
  setSaturday: React.Dispatch<React.SetStateAction<Day>>;
  setHoliday: React.Dispatch<React.SetStateAction<Day>>;
  setTwentyFourHours: React.Dispatch<React.SetStateAction<boolean>>;
}

export const resetDays = ({
  setSunday,
  setMonday,
  setTuesday,
  setWednesday,
  setThursday,
  setFriday,
  setSaturday,
  setHoliday,
  setTwentyFourHours,
  resources,
}: ResetDaysProps) => {
  resources.forEach(resource => {
    const { name, value } = resource;

    if (name === RESOURCES.WORK_SCHEDULE) {
      const workScheduleParsed = JSON.parse(value);

      workScheduleParsed.forEach((workday: any) => {
        switch (workday.name) {
          case 'Sunday':
            setSunday(current => {
              return {
                ...current,
                active: true,
                turns: workday.workTime,
              };
            });
            break;
          case 'Monday':
            setMonday(current => {
              return {
                ...current,
                active: true,
                turns: workday.workTime,
              };
            });
            break;
          case 'Tuesday':
            setTuesday(current => {
              return {
                ...current,
                active: true,
                turns: workday.workTime,
              };
            });
            break;
          case 'Wednesday':
            setWednesday(current => {
              return {
                ...current,
                active: true,
                turns: workday.workTime,
              };
            });
            break;
          case 'Thursday':
            setThursday(current => {
              return {
                ...current,
                active: true,
                turns: workday.workTime,
              };
            });
            break;
          case 'Friday':
            setFriday(current => {
              return {
                ...current,
                active: true,
                turns: workday.workTime,
              };
            });
            break;
          case 'Saturday':
            setSaturday(current => {
              return {
                ...current,
                active: true,
                turns: workday.workTime,
              };
            });
            break;
          case 'holiday':
            setHoliday(current => {
              return {
                ...current,
                active: true,
                turns: workday.workTime,
              };
            });
            break;
        }
      });
    } else if (name === RESOURCES.HOLIDAYS && value !== '') {
      setHoliday(current => {
        return {
          ...current,
          holidays: value,
        };
      });
    } else if (name === RESOURCES.TWENTY_FOUR_HOURS) {
      setTwentyFourHours(value.toLowerCase() === 'true');
    }
  });
};
