import { AnalyticsService as _analyticsService } from 'services/analyticsService/AnalyticsService';

const sendTrackConnectWaApi = async () => {
  _analyticsService.Track('blippacks-management-general-channels-whatsapp-connect-api');
};
const sendTrackCancelWaActivation = async () => {
  _analyticsService.Track('blippacks-management-general-channels-whatsapp-cancel-plan-hiring');
};

export const UpgradeWATracks = {
  sendTrackConnectWaApi,
  sendTrackCancelWaActivation,
};
