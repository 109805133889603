import { FC } from 'react';
import Item from './item';

interface IMenu {
  itens: {
    title: string;
    photo: string;
    linkActive: boolean;
    link: string;
    imageModal: string;
  }[];
}

const Menu: FC<IMenu> = ({ itens: channelItems }) => {
  return (
    <bds-grid xxs="12" direction="column" justify-content="center">
      <bds-grid container direction="row" flex-wrap="wrap" justify-content="center">
        {channelItems.map((item, index) => {
          return (
            <bds-grid margin="y-2" key={index}>
              <bds-paper>
                <Item item={item} key={index}></Item>
              </bds-paper>
            </bds-grid>
          );
        })}
      </bds-grid>
    </bds-grid>
  );
};

export default Menu;
