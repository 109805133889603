import Logos from 'assets/logos';
import DrawerItem from 'components/DrawerItem';
import { LogoutEvent } from 'constants/MicrofrontEndEvents';
import { PG_LOGOUT, PG_OPEN_MOBILE_MENU, PG_OPEN_MODULE } from 'constants/TrackingEvents';
import { usePackContext } from 'contexts/PackContext';
import useTrack from 'hooks/useTrack';
import React, { FC } from 'react';
import { PublishEvent } from 'services/EventPublisher';
import { FlexAlignCenter } from 'styles';
import { Module } from 'typings/Module';
import { Container, LogoutLink } from './style';
import { usePackManagerContext } from 'contexts/PackManagerContext';
import { useNavigate } from 'react-router-dom';
import { BOT_ROUTE, CHANNELS_ROUTE, DESK_ROUTER, HUMAN_SERVICE_ROUTE, PACK_MANAGER_ROUTE } from 'constants/Routes';

const MobileHeader: FC = () => {
  const { profile } = usePackManagerContext();
  const { modules, setActiveModule } = usePackContext();
  const { track } = useTrack();
  const sidebarRef = React.useRef<HTMLBdsSidebarElement>(null);

  const navigate = useNavigate();

  const logout = async () => {
    track(PG_LOGOUT);
    await PublishEvent(LogoutEvent);
  };

  const handleOnClick = (packMenuItem: Module) => {
    track(PG_OPEN_MODULE, {
      moduleName: packMenuItem.title,
      moduleInternal: packMenuItem.isInternalModule ?? false,
    });

    if (packMenuItem.isExternalLink) {
      window.open(packMenuItem.url, '_blank');
    } else {
      setActiveModule(packMenuItem);

      if (packMenuItem.title == 'Atendimento Humano') {
        navigate(`${PACK_MANAGER_ROUTE}\\${HUMAN_SERVICE_ROUTE}`);
      } else if (packMenuItem.title == 'Bot') {
        navigate(`${PACK_MANAGER_ROUTE}\\${BOT_ROUTE}`);
      } else if (packMenuItem.title == 'Canais de atendimento') {
        navigate(`${PACK_MANAGER_ROUTE}\\${CHANNELS_ROUTE}`);
      } else if (packMenuItem.title == 'Central de atendimento') {
        navigate(`${PACK_MANAGER_ROUTE}\\${DESK_ROUTER}`);
      } else if (packMenuItem.title == 'Começo') {
        navigate(`${PACK_MANAGER_ROUTE}`);
      }
    }

    sidebarRef.current?.toggle();
  };

  const handleOpenMenu = () => {
    track(PG_OPEN_MOBILE_MENU);
    sidebarRef.current?.toggle();
  };

  const SideMenuItems = () => {
    return (
      <>
        {modules.map((module, index) => (
          <DrawerItem key={index} module={module} onClick={() => handleOnClick(module)} />
        ))}
      </>
    );
  };

  return (
    <>
      <Container>
        <img src={Logos.BlipGoLogoExtended} alt="Ícone padrão" />
        <bds-icon name={'menu-hamburger'} size="x-large" onClick={handleOpenMenu} />
      </Container>
      <bds-sidebar ref={sidebarRef} sidebar-position="left">
        <FlexAlignCenter slot="header">
          <bds-typo variant="fs-16" bold="semi-bold">
            Olá, {profile?.FullName}
            <LogoutLink variant="fs-14" onClick={logout}>
              (Sair)
            </LogoutLink>
          </bds-typo>
        </FlexAlignCenter>
        <div slot="body">
          <SideMenuItems />
        </div>
      </bds-sidebar>
    </>
  );
};

export default MobileHeader;
