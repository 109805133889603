import { useCallback, useState } from 'react';

import { useAppTabsContext } from 'contexts/TabsContext';
import tabsTexts from 'texts/tabsTexts.json';

import SubmitButton from 'pages/BotConfiguration/components/SubmitButton';

import { TextContainer } from '../styles';
import { TabsPanelContainer } from 'styles/tabs.styles';
import InputMessageEditable from 'components/InputMessageEditable';
import Grid from 'components/Grid';
import TabGridContainer from 'components/TabGridContainer';
import CallButtonSimulator from './CallButtonSimulator';

export const CallButton = () => {
  const [blurComponent, setBlurComponent] = useState<boolean>(true);
  const { buttonMessage, setButtonMessage, setCallButton, checkIsModified } = useAppTabsContext();
  const [buttonMessageOriginalValue, setbuttonMessageOriginalValue] = useState('');

  const handleChangeButtonMessage = useCallback(
    (ev: Event) => {
      const input = ev.target as HTMLBdsInputElement | null;
      setButtonMessage(input?.value as string);

      if (buttonMessage === '') {
        setCallButton(callButton => ({ ...callButton, isModified: false }));
      } else if (buttonMessage !== undefined) {
        setCallButton(callButton => ({ ...callButton, isModified: buttonMessageOriginalValue !== input?.value }));
      } else {
        setbuttonMessageOriginalValue(input?.value as string);
      }
    },
    [setButtonMessage, buttonMessage, setCallButton, buttonMessageOriginalValue],
  );

  const handleBlurComponent = (isBlur: boolean) => {
    setBlurComponent(isBlur);
  };

  return (
    <TabGridContainer>
      <Grid xxs="12" md="8" lg="9" flex-wrap="wrap" direction="row">
        <TabsPanelContainer>
          <TextContainer>
            <bds-typo variant="fs-16" bold="bold">
              Botão de atendimento
            </bds-typo>
            <bds-typo variant="fs-16" bold="semi-bold" tag="p">
              Personalize o texto do botão que iniciará o fluxo de atendimento humano.
            </bds-typo>
          </TextContainer>

          <Grid xxs="12" flex-wrap="wrap" style={{ padding: 0 }}>
            <Grid xxs="12" md="6">
              <InputMessageEditable
                disabled={false}
                label="Nome do botão"
                message={buttonMessage || ''}
                rows={1}
                maxLength={20}
                onChange={handleChangeButtonMessage}
                required={false}
                helperMessage={buttonMessage != undefined ? `${buttonMessage.length}/20` : ''}
                requiredErrorMessage={tabsTexts.error.requiredField}
                onFocus={() => handleBlurComponent(false)}
                onBlur={() => handleBlurComponent(true)}
                danger={buttonMessage === ''}
                errorMessage={buttonMessage === '' ? tabsTexts.error.requiredField : ''}
              />
            </Grid>
          </Grid>
        </TabsPanelContainer>

        <SubmitButton isModified={checkIsModified()} style={{ margin: 0 }} />
      </Grid>

      <Grid md="3" style={{ width: 'auto' }}>
        <CallButtonSimulator
          isBlur={blurComponent}
          buttonMessage={buttonMessage || ''}
        />
      </Grid>
    </TabGridContainer>
  );
};
