import React from 'react';
import { Link } from '../styles';
import { BOT_ROUTE, CHANNELS_ROUTE, HUMAN_SERVICE_ROUTE } from 'constants/Routes';
import {
  BLIPPACKS_MANAGEMENT_GENERAL_HOME_ASSIST_CELLPHONE,
  BLIPPACKS_MANAGEMENT_GENERAL_HOME_STEPS_MEET_THE_BOT,
  HOME_CONNECT_CHANNEL,
  HOME_CUSTOMIZE_BOT_MENU,
  HOME_HUMAN_SERVICE_LINK,
} from 'constants/TrackingEvents';
import { TipText } from './styles';

interface ITips {
  redirectToBlipChat: () => void;
  handleNavigate: (route: string, moduleName?: string) => void;
  track: (event: string) => void;
}

const Tips = ({ redirectToBlipChat, handleNavigate, track }: ITips) => {
  return (
    <div>
      <bds-typo variant="fs-32" bold="bold" style={{ color: 'white' }}>
        Comece por aqui
      </bds-typo>
      <bds-typo variant="fs-16" style={{ color: 'white' }}>
        Personalize seu bot em 5 passos!
      </bds-typo>
      <bds-typo variant="fs-16" style={{ color: 'white' }}>
        Clique nos itens abaixo e você será redirecionado.
      </bds-typo>
      <bds-list style={{ marginTop: 16 }}>
        <Link
          onClick={() => {
            handleNavigate(HUMAN_SERVICE_ROUTE);
            track(HOME_HUMAN_SERVICE_LINK);
          }}
        >
          <TipText>1. Configure o atendimento em equipe</TipText>
        </Link>
        <Link
          onClick={() => {
            handleNavigate(BOT_ROUTE);
            track(HOME_CUSTOMIZE_BOT_MENU);
          }}
        >
          <TipText>2. Personalize seu bot</TipText>
        </Link>
        <Link
          onClick={() => {
            track(BLIPPACKS_MANAGEMENT_GENERAL_HOME_STEPS_MEET_THE_BOT);
            redirectToBlipChat();
          }}
          data-testid="test-bot-button"
        >
          <TipText>3. Teste o seu bot</TipText>
        </Link>
        <Link
          onClick={() => {
            handleNavigate(CHANNELS_ROUTE);
            track(HOME_CONNECT_CHANNEL);
          }}
        >
          <TipText>4. Conecte um canal</TipText>
        </Link>
        <Link
          href="https://play.google.com/store/apps/details?id=com.desk&hl=pt_BR&gl=US"
          target="_balnk"
          onClick={() => {
            track(BLIPPACKS_MANAGEMENT_GENERAL_HOME_ASSIST_CELLPHONE);
          }}
        >
          <TipText>5. Atenda no celular</TipText>
        </Link>
      </bds-list>
    </div>
  );
};

export default Tips;
