import { ILogArgs, json, logger } from 'packs-template-baseweb';
import { DeleteAttendanceQueue } from './data/delete/DeleteAttendanceQueue';
import { DeleteAttendant } from './data/delete/DeleteAttentant';
import { DeleteResource } from './data/delete/DeleteResource';

import { GetAllAttendanceQueues } from './data/get/GetAllAttendanceQueues';
import { GetAllAttendanceQueuesSimplified } from './data/get/GetAllAttendanceQueuesSimplified';
import { GetAllAttendants } from './data/get/GetAllAttendants';
import { GetAllResources } from './data/get/GetAllResources';
import { GetOneAttendanceQueue } from './data/get/GetOneAttendanceQueue';
import { GetSpecificResource } from './data/get/GetSpecificResource';
import { GetValidatePageToken } from './data/get/GetValidatePageToken';

import { SetAttendanceQueue } from './data/set/SetAttendanceQueue';
import { SetAttendanceRule } from './data/set/SetAttendanceRule';
import { SetAttendant } from './data/set/SetAttendant';
import { SetResource } from './data/set/SetResource';

export class Requests {
  private api: any;

  private logArgs: ILogArgs;

  constructor(api: any) {
    this.api = api;
    this.logArgs = {
      className: "Requests"
    }
  }

  private async executeRequest(data: any, isFatal: boolean = false, acceptFailure: boolean = false) {
    this.logArgs.methodName = "executeRequest";

    try {
      const response = await this.api.post('/commands', data);

      if (response.data.status === 'failure') {
        if (acceptFailure) return false;

        throw new Error(response.data);
      }

      return response.data;
    } catch (error) {
      const message = `Problem on doing the following request to Blip 
      | api: '${json.format(this.api.defaults)}' 
      | data: '${json.format(data)}' 
      | error: '${json.format(error)}`;

      if (isFatal)
        logger.fatal(message, this.logArgs);
      else
        logger.error(message, this.logArgs);
    }
  }

  async setAttendanceQueue(ownerIdentity: string, queueName: string, guid: string) {
    const data = SetAttendanceQueue({
      ownerIdentity,
      queueName,
      guid,
    });

    return await this.executeRequest(data);
  }

  async setAttendant(attendantIdentity: string, teams: string[], guid: string) {
    const data = SetAttendant({
      attendantIdentity,
      teams,
      guid,
    });

    return await this.executeRequest(data);
  }

  async setAttendanceRule(ownerIdentity: string, queueName: string, queueId: string, guid: string) {
    const data = SetAttendanceRule({
      ownerIdentity,
      queueName,
      queueId,
      guid,
    });

    return await this.executeRequest(data);
  }

  async setResource(resourceValue: string, resourceName: string, guid: string, isFatal: boolean = false) {
    const data = SetResource({
      resourceValue,
      resourceName,
      guid,
    });

    return await this.executeRequest(data, isFatal);
  }

  async deleteResource(resourceName: string, guid: string) {
    const data = DeleteResource({
      resourceName,
      guid,
    });

    return await this.executeRequest(data);
  }

  async deleteAttendanceQueue(queueName: string, guid: string) {
    const data = DeleteAttendanceQueue({
      queueName,
      guid,
    });

    return await this.executeRequest(data);
  }

  async deleteAttendant(attendant: string, guid: string) {
    const data = DeleteAttendant({
      attendant,
      guid,
    });

    return await this.executeRequest(data);
  }

  async getAllAttendants(guid: string) {
    const data = GetAllAttendants({ guid });

    return await this.executeRequest(data);
  }

  async getAllAttendanceQueues(guid: string) {
    const data = GetAllAttendanceQueues({ guid });

    return await this.executeRequest(data);
  }

  async getAllAttendanceQueuesSimplified(guid: string) {
    const data = GetAllAttendanceQueuesSimplified({ guid });

    return await this.executeRequest(data);
  }

  async getOneAttendanceQueue(guid: string, queueName: string) {
    const data = GetOneAttendanceQueue({ guid, queueName });

    return await this.executeRequest(data);
  }

  async getAllResources(guid: string) {
    const data = GetAllResources({ guid });

    return await this.executeRequest(data);
  }

  async getSpecificResource(
    guid: string,
    resourceName: string,
    isFatal: boolean = true,
    acceptFailure: boolean = false) {
    const data = GetSpecificResource({ guid, resourceName });

    return await this.executeRequest(data, isFatal, acceptFailure);
  }

  async getValidatePageToken(channel: string, guid: string) {
    const payload = GetValidatePageToken({ channel, guid });

    return await this.executeRequest(payload);
  }
}
