import { AnalyticsService as _analyticsService } from 'services/analyticsService/AnalyticsService';

type SendTrackProps = {
  success?: boolean;
  personalPhone?: string;
  companyPhone?: string;
  hasCnpj: string;
  hasWebsite: string;
  cnpj?: string;
  site?: string;
};

export class CompanyVerificationTracks {
  sendTrackCompanyVerificationButton = async ({
    personalPhone, companyPhone, hasCnpj, hasWebsite, cnpj, site }: SendTrackProps) => {
    _analyticsService.Track('blippacks-management-general-channels-merchant-infos', {
      personalPhone,
      companyPhone,
      hasCnpj,
      hasWebsite,
      cnpj,
      site,
    });
  };
}
