import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px #e7edf4 solid;

  > * {
    margin: 12px 8px;
  }
`;
