import { AnalyticsService as _analyticsService } from 'services/analyticsService/AnalyticsService';

const sendTrackMetaLearnMore = async () => {
  _analyticsService.Track('blippacks-management-general-channels-wpp-moreinfoMeta');
};

const sendTrackMetaOpenPage = async () => {
  _analyticsService.Track('blippacks-management-general-channels-wpp-registerMeta');
};

const sendTrackMetaRegisterNow = async () => {
  _analyticsService.Track('blippacks-management-general-channels-wpp-registernow');
};

const sendTrackMetaRetryOpenEmbedded = async () => {
  _analyticsService.Track('blippacks-management-general-channels-wpp-registerMeta-opened');
};

const sendTrackMetaReportProblem = async (payload: any) => {
  _analyticsService.Track('blippacks-management-general-channels-wpp-reportproblem', payload);
};

const sendTrackMetaEmbeddedRedirect = async () => {
  _analyticsService.Track('blippacks-management-general-channels-wpp-registerMeta-redirected');
};

const sendTrackMetaConnectApiClick = async (payload: any) => {
  _analyticsService.Track('blippacks-management-general-channels-whatsapp-connect-to-api', payload);
};

const sendTrackMetaEmbeddedCompleted = async () => {
  _analyticsService.Track('blippacks-management-general-channels-wpp-registerMeta-completed');
};

export const MetaConnectiontTracks = {
  sendTrackMetaLearnMore,
  sendTrackMetaOpenPage,
  sendTrackMetaRegisterNow,
  sendTrackMetaReportProblem,
  sendTrackMetaRetryOpenEmbedded,
  sendTrackMetaEmbeddedRedirect,
  sendTrackMetaEmbeddedCompleted,
  sendTrackMetaConnectApiClick,
};
