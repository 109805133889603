import axios from 'axios';

export const api = (accountToken: string) => {
  const url = `${process.env.REACT_APP_API_SERVER}`;

  return axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${accountToken}`,
    },
  });
};
