import React from 'react';
import TipWhatsappBot from 'assets/TipWppBot.svg';
import {
    BotBodyMessage,
    BotMessage,
    InformationsWhatsapp,
    TimeMessage,
    BotBalloonPoint
} from './style';

const NO_OPACITY = 1;
const MEDIUM_OPACITY = 0.5;

type IBotContentProps = {
    testid?: string;
    time: string;
    disable?: boolean;
    message: React.ReactNode;
};

const BotContent: React.FC<IBotContentProps> = ({
    testid,
    time,
    message,
    disable = false,
}) => {
    return (
        <BotMessage data-testid={testid} style={{ opacity: disable ? MEDIUM_OPACITY : NO_OPACITY }}>
            <BotBalloonPoint src={TipWhatsappBot} alt="Bot balloon point" />

            <BotBodyMessage>
                {message}
                <InformationsWhatsapp>
                    <TimeMessage>{time}</TimeMessage>
                </InformationsWhatsapp>
            </BotBodyMessage>

        </BotMessage>
    );
};

export default BotContent;