import axios, { AxiosInstance } from 'axios';
import { PG_API_CALL_ERROR } from 'constants/TrackingEvents';
import qs from 'qs';
import { handleApiErrorResponseForTrack } from '../utils';
import { logTrack } from './LogTrackService';
import { ILogArgs, json, logger } from 'packs-template-baseweb';

export class ApiService {
  private server: string;
  private port: string;
  private access_token: string | undefined;
  private api: AxiosInstance;
  private logArgs: ILogArgs;

  constructor(server: string, port: string, access_token: string | undefined) {
    this.server = server;
    this.port = port;
    this.access_token = access_token;
    this.api = this.instantiateApi();
    this.logArgs = {
      className: 'ApiService',
    };
  }

  private getHeadersDefault(access_token: string | undefined): any {
    const headersDefault: any = {
      'Content-Type': 'application/json',
    };

    if (!!access_token) {
      headersDefault['Authorization'] = `bearer ${access_token}`;
    }

    return headersDefault;
  }

  private instantiateApi(): AxiosInstance {
    const api = axios.create({
      baseURL: !!this.port ? `${this.server}:${this.port}/api` : `${this.server}/api`,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      headers: this.getHeadersDefault(this.access_token),
      validateStatus: status => status >= 200 && status < 300,
    });

    return api;
  }

  async get<T = any>(url: string, parameters?: any): Promise<T> {
    this.logArgs.methodName = 'get';
    let response: any;

    try {
      response = await this.api.get<T>(url, { params: parameters });
      return Promise.resolve(response.data);
    } catch (error: any) {
      logTrack(PG_API_CALL_ERROR, {
        apiCall: `GET ${url}`,
        status: 'Falha',
        error: handleApiErrorResponseForTrack(error),
      });

      logger.fatal(
        `Error on doing get on '${url}': 
      | response: ${json.format(response)}
      | api: ${json.format(this.api.defaults)}
      | error: ${json.format(error)}`,
        this.logArgs,
      );

      return Promise.reject(error);
    }
  }

  async post<T = any>(url: string, body: any): Promise<T> {
    this.logArgs.methodName = 'post';
    let response: any;

    try {
      const response = await this.api.post<T>(url, body);
      return Promise.resolve(response.data);
    } catch (error: any) {
      logTrack(PG_API_CALL_ERROR, {
        apiCall: `POST ${url}`,
        status: 'Falha',
        error: handleApiErrorResponseForTrack(error),
      });

      logger.fatal(
        `Error on doing post on '${url}': 
      | response: ${json.format(response)}
      | api: ${json.format(this.api.defaults)}
      | error: ${json.format(error)}`,
        this.logArgs,
      );
      return Promise.reject(error);
    }
  }

  async patch<T = any>(url: string, body: T): Promise<T> {
    this.logArgs.methodName = 'patch';
    let response: any;

    try {
      const response = await this.api.patch<T>(url, body);
      return Promise.resolve(response.data);
    } catch (error: any) {
      logTrack(PG_API_CALL_ERROR, {
        apiCall: `PATCH ${url}`,
        status: 'Falha',
        error: handleApiErrorResponseForTrack(error),
      });

      logger.fatal(
        `Error on doing patch on '${url}': 
      | response: ${json.format(response)}
      | api: ${json.format(this.api.defaults)}
      | error: ${json.format(error)}`,
        this.logArgs,
      );
      return Promise.reject(error);
    }
  }

  async delete<T = any>(url: string, parameters?: any): Promise<T> {
    this.logArgs.methodName = 'delete';
    let response: any;

    try {
      const response = await this.api.delete<T>(url, {
        params: parameters,
      });
      return Promise.resolve(response.data);
    } catch (error: any) {
      logTrack(PG_API_CALL_ERROR, {
        apiCall: `DELETE ${url}`,
        status: 'Falha',
        error: handleApiErrorResponseForTrack(error),
      });

      logger.fatal(
        `Error on doing delete on '${url}': 
      | response: ${json.format(response)}
      | api: ${json.format(this.api.defaults)}
      | error: ${json.format(error)}`,
        this.logArgs,
      );
      return Promise.reject(error);
    }
  }
}
