import { validateInputPontuationMarks } from './validateInputPontuationMarks';
import { validateTextInput } from './validateTextInput';
import { FAQField } from 'types/botMolule/FAQField';

export const validateCustomizableMenu = (menu: FAQField[]) => {
  let isValid = true;

  menu.forEach(option => {
    isValid =
      isValid &&
      validateTextInput(option.topic) &&
      validateTextInput(option.answer) &&
      validateInputPontuationMarks(option.topic);
  });
  return isValid;
};
