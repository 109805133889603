import Button from 'components/Button';
import { ButtonsContainer, LoadingContainer, SingleButtonContainer } from './styles';
import tabsTexts from 'texts/tabsTexts.json';
import { useHumanServiceContext } from 'contexts/HumanServiceContext';
import { Dispatch, SetStateAction } from 'react';
import { LoggedAreaTracks } from 'services/humanService/trackings/handleSendTrackings';
import { useAuthContext } from 'contexts/AuthContext';
import { getAttendanceQueues } from 'services/getAttendanceQueues';
import { saveQueues } from 'services/save/humanService/saveQueues';
import { savePreServiceQuestions } from 'services/save/humanService/savePreServiceQuestions';
import { saveSatisfactionSurvey } from 'services/save/humanService/saveSatisfactionSurvey';
import { saveTwentyFourHourService } from 'services/save/humanService/saveTwentyFourHourService';
import { saveDays } from 'services/save/humanService/saveDays';
import { SendTrackProps } from 'types/humanService/SendTrackProps';
import { RESOURCES } from 'constants/ResourcesNames';
import { TEXT_TYPE } from 'constants/Application';
import { searchResources } from 'services/humanService/searchResources';

type ButtonSubmitProps = {
  cancelWarning: boolean;
  setCancelWarning: Dispatch<SetStateAction<boolean>>;
};

export const SubmitButton = ({ cancelWarning, setCancelWarning }: ButtonSubmitProps) => {
  const { userData, activationOption, routerData } = useAuthContext();
  const {
    attendanceQueueTab,
    setAttendanceQueueTab,
    preServiceQuestionTab,
    setPreServiceQuestionTab,
    satisfactionSurveyTab,
    setSatisfactionSurveyTab,
    attendanceHoursTab,
    setAttendanceHoursTab,

    currentTab,
    setCurrentTab,
    request,
    setResources,

    queueList,
    preQueueMessage,
    emojis,
    preServiceQuestionList,
    satisfactionSurvey,
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    holiday,
    twentyFourHours,

    keyDesk,
    keyRouter,

    requestRouterResources,
    setLoadingResources,
  } = useHumanServiceContext();

  const loggedAreaTracks = new LoggedAreaTracks();

  const tabsIsLoading = () => {
    return (
      (attendanceHoursTab && attendanceHoursTab.isLoading) ||
      (attendanceQueueTab && attendanceQueueTab.isLoading) ||
      (preServiceQuestionTab && preServiceQuestionTab.isLoading) ||
      (satisfactionSurveyTab && satisfactionSurveyTab.isLoading)
    );
  };

  const tabsIsInvalid = () => {
    switch (currentTab) {
      case 'Pré-atendimento':
        return preServiceQuestionTab && preServiceQuestionTab.isInvalid;
      case tabsTexts.satisfactionSurvey.title:
        return satisfactionSurveyTab && satisfactionSurveyTab.isInvalid;
      case 'Horários':
        return attendanceHoursTab && attendanceHoursTab.isInvalid;
      default:
        return attendanceQueueTab && attendanceQueueTab.isInvalid;
    }
  };

  const handleCancelAlert = async () => {
    setCancelWarning(!cancelWarning);

    if (!currentTab) {
      setCurrentTab(tabsTexts.attendanceQueue.title);
    }

    sendTrackingButtonHandleCancelAlert(currentTab ? currentTab : tabsTexts.attendanceQueue.title, cancelWarning);
  };

  const sendTrackingButtonHandleCancelAlert = async (screenName: string, cancelWarning: boolean) => {
    if (!cancelWarning) {
      await loggedAreaTracks.sendTrackClickButtonEdit({
        merchantName: userData.name,
        screenName,
        activationOption,
      });
    }
  };
  const handleSaveChanges = () => {
    handleClickButton();
  };

  const configTrackingSave = (): SendTrackProps => {
    return {
      merchantName: userData.name,
      numQueue: queueList.length,
      service24h: twentyFourHours,
      statusskill: `pesquisa satisfacao ${satisfactionSurvey}`,
    };
  };

  const updateResources = (tab: string) => {
    switch (tab) {
      case 'Pré-atendimento':
        setResources([
          {
            name: RESOURCES.PRE_SERVICE_QUESTIONS,
            type: 'text/plain',
            value: JSON.stringify(preServiceQuestionList),
          },
        ]);
        break;
      case 'Fila de atendimento':
        setResources([
          {
            name: RESOURCES.QUEUES,
            type: TEXT_TYPE,
            value: JSON.stringify(queueList),
          },
          {
            name: RESOURCES.MESSAGES.PRE_QUEUE,
            type: TEXT_TYPE,
            value: preQueueMessage,
          },
          {
            name: RESOURCES.EMOJI,
            type: 'text/plain',
            value: emojis.toString(),
          },
          {
            name: RESOURCES.PRE_SERVICE_QUESTIONS,
            type: 'text/plain',
            value: JSON.stringify(preServiceQuestionList),
          },
        ]);
        break;
      case tabsTexts.satisfactionSurvey.title:
        setResources([
          {
            name: RESOURCES.SATISFACTION_SURVEY,
            type: 'text/plain',
            value: satisfactionSurvey.toString(),
          },
        ]);
        break;
      case 'Horários':
        searchResources(
          [
            RESOURCES.DAYS.SATURDAY.END,
            RESOURCES.DAYS.SATURDAY.START,
            RESOURCES.MESSAGES.WORKDAY_WITH_SATURDAY,
            RESOURCES.DAYS.SUNDAY.END,
            RESOURCES.DAYS.SUNDAY.START,
            RESOURCES.DAYS.HOLIDAY.START,
            RESOURCES.DAYS.HOLIDAY.END,
            RESOURCES.HOLIDAY_ATTENDANCE,
            RESOURCES.HOLIDAYS,
            RESOURCES.WORK_SCHEDULE,
            RESOURCES.WORK_DAYS,
            RESOURCES.TWENTY_FOUR_HOURS,
          ],
          requestRouterResources,
          setResources,
          setLoadingResources,
        );
        break;
    }
  };

  const handleClickButton = async () => {
    switch (currentTab) {
      case tabsTexts.attendanceQueue.title:
        setAttendanceQueueTab(current => {
          return {
            ...current,
            isLoading: true,
            isOpenModalSuccess: false,
          };
        });

        const oldQueues = await getAttendanceQueues(request);

        saveQueues({
          deskKey: keyDesk || '',
          routerKey: keyRouter || '',
          routerData,
          trackConfig: configTrackingSave(),
          newQueues: queueList,
          oldQueues: oldQueues,
          preServiceQuestionList,
          setAttendanceQueue: setAttendanceQueueTab,
          preQueueMessage,
          useEmojis: emojis,
        }).then(() => {
          setAttendanceQueueTab(current => {
            return {
              ...current,
              isLoading: false,
            };
          });
          updateResources(tabsTexts.attendanceQueue.title);
        });
        break;
      case tabsTexts.preService.title:
        setPreServiceQuestionTab(current => {
          return {
            ...current,
            isLoading: true,
            isOpenModalSuccess: false,
          };
        });
        savePreServiceQuestions({
          preServiceQuestions: preServiceQuestionList,
          setPreServiceQuestion: setPreServiceQuestionTab,
          routerData,
          trackConfig: configTrackingSave(),
          keyRouter: keyRouter || '',
        }).then(() => {
          setPreServiceQuestionTab(current => {
            return {
              ...current,
              isLoading: false,
            };
          });
          updateResources(tabsTexts.preService.title);
        });

        break;
      case tabsTexts.satisfactionSurvey.title:
        setSatisfactionSurveyTab(current => {
          return {
            ...current,
            isLoading: true,
            isOpenModalSuccess: false,
          };
        });

        saveSatisfactionSurvey({
          routerData,
          trackConfig: configTrackingSave(),
          satisfactionSurvey,
          setSatisfactionSurveyTab,
          keyRouter: keyRouter || '',
        }).then(() => {
          setSatisfactionSurveyTab(current => {
            return {
              ...current,
              isLoading: false,
            };
          });
          updateResources(tabsTexts.satisfactionSurvey.title);
        });

        break;
      case tabsTexts.schedules.title:
        setAttendanceHoursTab(current => {
          return {
            ...current,
            isLoading: true,
            isOpenModalSuccess: false,
          };
        });

        if (twentyFourHours) {
          saveTwentyFourHourService({
            routerData,
            trackConfig: configTrackingSave(),
            twentyFourHours,
            setAttendanceHours: setAttendanceHoursTab,
            keyRouter: keyRouter || '',
          }).then(() => {
            setAttendanceHoursTab(current => {
              return {
                ...current,
                isLoading: false,
              };
            });
            updateResources(tabsTexts.schedules.title);
          });
        } else {
          saveDays({
            routerData,
            trackConfig: configTrackingSave(),
            days: [
              ['Sunday', sunday],
              ['Monday', monday],
              ['Tuesday', tuesday],
              ['Wednesday', wednesday],
              ['Thursday', thursday],
              ['Friday', friday],
              ['Saturday', saturday],
              ['holiday', holiday],
            ],
            twentyFourHours,
            setAttendanceHours: setAttendanceHoursTab,
            keyRouter: keyRouter || '',
          }).then(() => {
            setAttendanceHoursTab(current => {
              return {
                ...current,
                isLoading: false,
              };
            });
            updateResources(tabsTexts.schedules.title);
          });
        }

        break;
    }
  };

  return (
    <ButtonsContainer>
      <SingleButtonContainer>
        <Button
          text={tabsTexts.cancel}
          isAdd={false}
          isFAQ={false}
          onClick={handleCancelAlert}
          isSubmit={false}
          isCancel
          isDisabled={tabsIsLoading()}
        />

        {tabsIsLoading() ? (
          <LoadingContainer>
            <bds-loading-spinner color="main" size="small" />
          </LoadingContainer>
        ) : (
          <Button
            text={tabsTexts.save}
            isAdd={false}
            isFAQ={false}
            onClick={handleSaveChanges}
            isSubmit={false}
            isDisabled={tabsIsInvalid()}
          />
        )}
      </SingleButtonContainer>
    </ButtonsContainer>
  );
};
