import React from 'react';
import './styles.scss';

interface Props {
  linkPressed: () => void;
  linkTitle: string;
}

export const WhatsappActivationFooter = ({ linkPressed, linkTitle }: Props) => {
  return (
    <div className="report-btn">
      <bds-typo variant="fs-14" onClick={() => linkPressed()} data-testid="footer-link">
        {linkTitle}
      </bds-typo>
    </div>
  );
};
