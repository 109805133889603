interface IGetValidatePageToken {
    channel: string;
    guid: string
};

export const GetValidatePageToken = ({ channel, guid }: IGetValidatePageToken) => {
    return {
        to: `postmaster@${channel}.gw.msging.net`,
        method: 'get',
        uri: '/validate-page-token',
        id: guid
    };
};
