import { FC, useEffect, useState, useRef } from 'react';
import IframeModules from 'pages/IframeModules';
import { ModuleComponents } from 'typings/ModuleComponents';
import { ConfigurationService } from '../../services/configurationService/ConfigurationService';
import { usePackContext } from 'contexts/PackContext';
import { mountKey } from 'utils/mountKey';
import { useNavigate } from 'react-router-dom';
import { MainContainer } from './styles';
import { ILogArgs, logger } from 'packs-template-baseweb';
import { useAuth } from 'oidc-react';
import { checkEmailRegistered } from 'services/experienceCluster/Api';
import { PACK_MANAGER_ROUTE, CHANNELS_ROUTE, NUMBER_PREPARATION } from 'constants/Routes';
import WhatsappModal from 'pages/channels/components/whatsappModal';


const ActiveMessage: FC<ModuleComponents> = ({ activeModule }) => {
  const logArgs: ILogArgs = {
    className: 'ActiveMessage',
  };

  const { pack } = usePackContext();
  const [wppActive, setWppActive] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef<null | HTMLBdsModalElement>(null);
  const navigate = useNavigate();
  const auth = useAuth();
  const screenTitle = true;

  const VerificationActiveWpp = () => {
    const key = mountKey(pack.routerData.shortName, pack.routerData.accessKey);
    ConfigurationService.GetInfoWppActiveAsync(key)
      .then(response => {
        logger.info('check if WhatsApp is active', logArgs);
        if (response.resource) {
          setWppActive(true);
        }
      })
      .catch(error => {
        logger.error(`Error check if WhatsApp is active: ${error}`, logArgs);
        setWppActive(false);
      });
  };

  useEffect(() => {
    VerificationActiveWpp();
  }, []);

  async function checkIsRegistered() {

    if (auth?.userData?.profile?.email?.length) {
      setIsLoading(true);
      await checkEmailRegistered(auth.userData.profile.email).then(res => {
        setIsLoading(false);
        if (res.data.emailRegistered) {
          navigate(`${PACK_MANAGER_ROUTE}\\${CHANNELS_ROUTE}\\${NUMBER_PREPARATION}`, { replace: true });
        } else {
          if (modalRef.current) {
            modalRef.current.open = !modalRef.current.open;
          }
        }
      });
    }
  }
  return (
    <>
      {wppActive ? (
        <IframeModules data-testid="valid-iframe" activeModule={activeModule} />
      ) : (
        <>
          <MainContainer>
            <span>Disponível no plano PRO.</span>
            <bds-button
              variant="primary"
              data-testid="company-submit-btn"
              onClick={() => {
                checkIsRegistered();
              }}
            >
              Atualizar plano
            </bds-button>
          </MainContainer>
          {isLoading ? <bds-loading-page /> : ''}
          <WhatsappModal modalRef={modalRef} introductionTitle={screenTitle} />
        </>
      )}
    </>
  );
};

export default ActiveMessage;
