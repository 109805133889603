import { RESOURCES } from 'constants/ResourcesNames';
import { Resource } from 'types/Resource';

interface ResetPreServiceQuestionsProps {
  resources: Resource[];
  setPreServiceQuestionList: React.Dispatch<
    React.SetStateAction<
      {
        [x: string]: string;
      }[]
    >
  >;
}

function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const resetPreServiceQuestions = ({ resources, setPreServiceQuestionList }: ResetPreServiceQuestionsProps) => {
  resources.forEach(resource => {
    const { name, value } = resource;

    if (name === RESOURCES.PRE_SERVICE_QUESTIONS) {
      const newText = value.replace(/(\r\n|\n|\r)/gm, '');
      const newTextWithoutLastComma =
        newText[newText.length - 2] === ',' ? newText.substring(0, newText.length - 2) + ']' : newText;

      if (isJsonString(newTextWithoutLastComma)) {
        setPreServiceQuestionList(JSON.parse(newTextWithoutLastComma));
      } else {
        setPreServiceQuestionList([]);
      }
    }
  });
};
