interface GetAllAttendanceQueuesSimplifiedProps {
    guid: string
}

export const GetAllAttendanceQueuesSimplified = ({ guid }: GetAllAttendanceQueuesSimplifiedProps) => {
    return {
        id: guid,
        to: 'postmaster@desk.msging.net',
        method: 'get',
        uri: '/teams'
    }
}
