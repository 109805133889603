import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_EXPERIENCE_CLUSTER_API_URL,
});

export const checkEmailRegistered = async (email: string) => {
  return await api.get('approval', {
    params: { email },
    auth: {
      username: process.env.REACT_APP_EXPERIENCE_CLUSTER_ADMIN_USER as string,
      password: process.env.REACT_APP_EXPERIENCE_CLUSTER_ADMIN_PASSWORD as string,
    },
  });
};

export const directionLeadFindByInstallationId = async (installationId: number) => {
  return await api.get('directionlead/find-by-installation-id', {
    params: { installationId },
    auth: {
      username: process.env.REACT_APP_EXPERIENCE_CLUSTER_ADMIN_USER as string,
      password: process.env.REACT_APP_EXPERIENCE_CLUSTER_ADMIN_PASSWORD as string,
    },
  });
};

export const directionLeadFindByEmail = async (email: string) => {
  try {
    const response = await api.get('directionlead/find-by-email', {
      params: { email },
      auth: {
        username: process.env.REACT_APP_EXPERIENCE_CLUSTER_ADMIN_USER as string,
        password: process.env.REACT_APP_EXPERIENCE_CLUSTER_ADMIN_PASSWORD as string,
      },
    });
    return response
  } catch (error: any) {
    const errorPayload = {
      status: error.response?.status,
      error: error.response?.data.message,
      data: {
        leadSource: ''
      }
    };

    if (error.response?.status === 404) {
      errorPayload.data.leadSource = 'Other'
    }
    return errorPayload;
  }
};

