import Guid from 'guid';
import { RESOURCES } from 'constants/ResourcesNames';
import { api } from 'services/requests/Api';
import { Requests } from 'services/requests/Requests';
import { RouterData } from 'types/PluginProps';

import { Tabs } from 'types/Tabs';

import saveResourceData from './saveResourceData';
import { SendTrackProps } from 'types/humanService/SendTrackProps';
import { LoggedAreaTracks } from 'services/humanService/trackings/handleSendTrackings';
import { WarningConstants } from 'constants/WarningsConstants';
import { Queue } from 'types/humanService/Queue';
import { validateQueue } from 'utils/validates/humanService/validateQueue';
import { QUEUE_DEFAULT } from 'constants/Config';

interface SaveQueuesProps {
  setAttendanceQueue: React.Dispatch<React.SetStateAction<Tabs>>;
  oldQueues: Queue[];
  newQueues: Queue[];
  routerData: RouterData;
  trackConfig: SendTrackProps;
  routerKey: string;
  deskKey: string;
  preServiceQuestionList: { [x: string]: string }[];
  preQueueMessage: string;
  useEmojis: boolean;
}

export const saveQueues = async ({
  setAttendanceQueue,
  newQueues,
  oldQueues,
  routerData,
  trackConfig,
  routerKey,
  deskKey,
  preQueueMessage,
  preServiceQuestionList,
  useEmojis,
}: SaveQueuesProps) => {
  const loggedAreaTracks = new LoggedAreaTracks();
  const request = new Requests(api(routerKey));
  const deskRequest = new Requests(api(deskKey));
  const guid = Guid.raw();

  const setNewAttendant = async (email: string, queue: string) => {
    const attendantIdentity = email.replace('@', '%40').concat('@blip.ai');
    await deskRequest.setAttendant(attendantIdentity, [queue, QUEUE_DEFAULT], guid);
  };

  const deleteAttendanceQueues = async (oldQueues: Queue[]) => {
    const { resource } = await deskRequest.getAllAttendants(guid);
    if (resource) {
      const { items: attendants } = resource;

      for (const attendant of attendants) {
        await deskRequest.setAttendant(attendant.identity, [], guid);
      }

      for (const oldQueue of oldQueues) {
        if (oldQueue.id) {
          await deskRequest.deleteAttendanceQueue(oldQueue.id, guid);
        }

        if (!newQueues.some(newQueue => oldQueue.name === newQueue.name)) {
          const index = preServiceQuestionList.findIndex(question => Object.keys(question)[0] === oldQueue.name);

          preServiceQuestionList.splice(index, 1);
        }
      }
    }
  };

  const createAttendanceQueues = async (newQueues: Queue[]) => {
    const ownerIdentity = routerData.skillTransbordo.shortName + '@msging.net';

    for (const newQueue of newQueues) {
      const { resource: queue } = await deskRequest.setAttendanceQueue(ownerIdentity, newQueue.name, guid);

      await deskRequest.setAttendanceRule(ownerIdentity, newQueue.name, queue.uniqueId, queue.uniqueId);

      const question = preServiceQuestionList.some(question => Object.keys(question)[0] === newQueue.name);

      if (!question) {
        preServiceQuestionList.push({
          [newQueue.name as string]: '',
        });
      }

      const { resource: allAttendants } = await deskRequest.getAllAttendants(guid);

      if (allAttendants) {
        const { items: attendants } = allAttendants;

        for (const email of newQueue.emails) {
          const attendant = attendants.filter((attendant: any) => attendant.email === email);

          if (attendant.length > 0) {
            const teams = attendant[0].teams.concat(newQueue.name, QUEUE_DEFAULT);
            await deskRequest.setAttendant(attendant[0].identity, teams, guid);
          } else {
            setNewAttendant(email, newQueue.name);
          }
        }
      } else {
        for (const email of newQueue.emails) {
          setNewAttendant(email, newQueue.name);
        }
      }
    }
  };

  if (validateQueue(newQueues)) {
    const responses = [];
    if (newQueues.length > 0) {
      await deleteAttendanceQueues(oldQueues);
      await createAttendanceQueues(newQueues);

      const resourceQueue: Queue[] = newQueues.map((queue: Queue) => ({
        name: queue.name,
        emails: queue.emails,
      }));

      const res = await saveResourceData(request, [
        {
          name: RESOURCES.QUEUES,
          type: 'text/plain',
          value: JSON.stringify(resourceQueue),
        },
      ]);

      responses.push(res);
    }
    if (preServiceQuestionList.length > 0) {
      const res = await saveResourceData(request, [
        {
          name: RESOURCES.PRE_SERVICE_QUESTIONS,
          type: 'text/plain',
          value: JSON.stringify(preServiceQuestionList),
        },
      ]);
      responses.push(res);
    }
    if (preQueueMessage) {
      const res = await saveResourceData(request, [
        {
          name: RESOURCES.MESSAGES.PRE_QUEUE,
          type: 'text/plain',
          value: preQueueMessage,
        },
      ], false);
      responses.push(res);
    }
    if (useEmojis !== undefined) {
      const res = await saveResourceData(request, [
        {
          name: RESOURCES.EMOJI,
          type: 'text/plain',
          value: useEmojis.toString(),
        },
      ]);
      responses.push(res);
    }
    setAttendanceQueue(current => {
      return {
        ...current,
        isOpenModalSuccess: true,
      };
    });

    await loggedAreaTracks.sendTrackClickButtonSave({
      screenName: 'Fila de atendimento',
      msgWarning: 'none',
      ...trackConfig,
      success: true,
      error: responses.length === 0 ? 'The data was not saved' : 'none',
      numQueue: newQueues.length,
    });

    return responses;
  } else {
    setAttendanceQueue(current => {
      return {
        ...current,
        isInvalid: true,
        isOpenWarning: true,
      };
    });

    await loggedAreaTracks.sendTrackClickButtonSave({
      screenName: 'Fila de atendimento',
      msgWarning: WarningConstants.ATTENDANCEQUEUEWARNING,
      ...trackConfig,
      success: false,
      error: 'user input error',
      numQueue: newQueues.length,
    });
  }
};
