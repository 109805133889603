import { MetaConnectiontTracks } from 'pages/MetaConnection/trackings/MetaConnectionTracks';
import { GraphApiUrl } from '../constants/GraphApi';
import { Facebook as FB } from '../services/FacebookSDK.js';
import { PhoneNumber, Waba } from '../typings/WhatsAppActivation';
import { ILogArgs, logger } from 'packs-template-baseweb';

const logArgs: ILogArgs = {
  className: "useGraphApi"
}

const useGraphApi = () => {

  const logArgs: ILogArgs = {
    className: 'useGraphApi',
  };

  const initFacebookSdk = async () => {
    logArgs.methodName = "initFacebookSdk";

    try {
      await FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v16.0',
      });
    } catch (error) {
      logger.error(`Error on init Facebook SDK: ${error}`, logArgs)
    }
  };

  const launchWhatsAppSignup = async (callback: (arg0: string) => any) => {
    logArgs.methodName = "launchWhatsAppSignup"
    try {
      FB.login(
        function (response: any) {
          if (response.authResponse) {
            MetaConnectiontTracks.sendTrackMetaEmbeddedCompleted();
            const accessToken = response.authResponse.accessToken;
            callback && callback(accessToken);
          }
        },
        {
          auth_type: 'reauthorize',
          scope: 'business_management,whatsapp_business_management',
          extras: {
            feature: 'whatsapp_embedded_signup',
            setup: {},
          },
        },
      );
    } catch (error) {
      logger.error(`Error on doing login on Facebook: ${error}`, logArgs);
    }
  };

  const getWabaPhoneNumbersById = async (wabaId: string): Promise<PhoneNumber[]> => {
    logArgs.methodName = "getWabaPhoneNumbersById";

    const url = `${GraphApiUrl}/${wabaId}/phone_numbers?
    fields=display_phone_number&access_token=${process.env.REACT_APP_FACEBOOK_BUSINESS_SYSTEM_USER_TOKEN}`;
    const response = await fetch(url);

    if (!response.ok) {
      logger.error(`Error on get waba phone numbers`, logArgs);
      return [];
    }

    const data = await response.json();
    const phoneNumbers: PhoneNumber[] = data.data.map(({ display_phone_number, id }: any) => ({
      displayPhoneNumber: display_phone_number,
      id,
    }));
    return phoneNumbers;
  };

  const getWabaIdsAndAppName = async (inputToken: string): Promise<{ wabaIds: string[]; applicationName: string }> => {
    logArgs.methodName = "getWabaIdsAndAppName";

    const url = `${GraphApiUrl}/debug_token?input_token=${inputToken}
    &access_token=${process.env.REACT_APP_FACEBOOK_BUSINESS_SYSTEM_USER_TOKEN}`;
    const response = await fetch(url);

    if (!response.ok) {
      logger.error(`Error on get app Name`, logArgs);

      return Promise.reject(new Error(JSON.stringify(response)));
    }

    const data = await response.json();

    try {
      const waScope = data.data.granular_scopes.find(({ scope }: any) => scope === 'whatsapp_business_management');
      logger.info(`successfully obtaining wabaIds: ${waScope.target_ids}`, logArgs);
      return Promise.resolve({ wabaIds: waScope.target_ids, applicationName: data.data.application });
    } catch (error) {
      logger.error(`Error on get wabaIds: ${error}`, logArgs);

      return Promise.reject(new Error(error as string));
    }
  };

  const getWabas = async (inputToken: string): Promise<{ wabas: Waba[]; applicationName: string }> => {
    logArgs.methodName = "getWabas";

    const wabas: Waba[] = [];

    try {
      const response = await getWabaIdsAndAppName(inputToken);
      for (const wabaId of response.wabaIds) {
        const wabaPhoneNumbers = await getWabaPhoneNumbersById(wabaId);
        wabas.push({
          id: wabaId,
          phoneNumbers: wabaPhoneNumbers,
        });
      }
      logger.info(`successfully obtaining wabas: ${wabas}`, logArgs);
      return Promise.resolve({ wabas: wabas, applicationName: response.applicationName });
    } catch (error) {
      logger.error(`Error on get wabas: ${error}`, logArgs);

      return Promise.reject(new Error(error as string));
    }
  };

  return { initFacebookSdk, launchWhatsAppSignup, getWabas };
};

export default useGraphApi;
