import { RESOURCES } from 'constants/ResourcesNames';
import { api } from 'services/requests/Api';
import { Requests } from 'services/requests/Requests';
import { ModuleBotConfigurationTracks } from 'services/trackingService/handleSendTrackings';
import { validateTexts } from 'utils/validates/validateTexts';
import { WarningConstants } from 'constants/WarningsConstants';
import { SendTrackProps } from 'types/botMolule/SendTrackProps';
import tabsTexts from 'texts/tabsTexts.json';
import saveResourceData from './saveResourceData';
import { RouterData } from 'types/PluginProps';
import { Tabs } from 'types/Tabs';

interface SaveGreetingsProps {
  setGreetings: React.Dispatch<React.SetStateAction<Tabs>>;
  messages: string[];
  routerData: RouterData;
  trackConfig: SendTrackProps;
  keyRouter: string;
}

export const saveGreetings = async ({
  messages,
  setGreetings,
  routerData,
  trackConfig,
  keyRouter,
}: SaveGreetingsProps) => {
  const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();
  const request = new Requests(api(keyRouter));

  if (validateTexts(messages)) {
    const [returnMessage, firstContactMessage] = messages;

    const responses = await saveResourceData(request, [
      {
        name: RESOURCES.MESSAGES.RETURN,
        type: 'text/plain',
        value: returnMessage,
      },
      {
        name: RESOURCES.MESSAGES.FIRST_CONTACT,
        type: 'text/plain',
        value: firstContactMessage,
      },
    ]);

    setGreetings(current => {
      return {
        ...current,
        isOpenModalSuccess: true,
      };
    });

    await moduleBotConfigurationTracks.sendTrackClickButtonSave({
      screenName: tabsTexts.saudation.name,
      msgWarning: 'none',
      ...trackConfig,
      success: true,
      error: responses.length === 0 ? 'The data was not saved' : 'none',
    });

    return responses;
  } else {
    setGreetings(current => {
      return {
        ...current,
        isInvalid: true,
        isOpenWarning: true,
      };
    });

    await moduleBotConfigurationTracks.sendTrackClickButtonSave({
      screenName: tabsTexts.saudation.name,
      msgWarning: WarningConstants.GREETINGSWARNING,
      ...trackConfig,
      success: false,
      error: 'user input error',
    });
  }
};
