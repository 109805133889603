interface IGetSpecificResource {
  guid: string;
  resourceName: string;
}

export const GetSpecificResource = ({ guid, resourceName }: IGetSpecificResource) => {
  return {
    id: guid,
    method: 'get',
    uri: `/resources/${encodeURIComponent(resourceName)}`,
  };
};
