import Grid from 'components/Grid';
import React from 'react';

interface ITabGridContainer {
  children: React.ReactNode;
}

const TabGridContainer = ({ children }: ITabGridContainer) => {
  return (
    <Grid xxs="12" gap="3" style={{ display: 'flex' }}>
      {children}
    </Grid>
  );
};

export default TabGridContainer;
