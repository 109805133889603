import { AxiosHeaders } from 'axios';

export class BlipCommandHeader extends AxiosHeaders {
  'Content-Type': string;
  Authorization: string | undefined;

  public constructor(botKey: string | undefined, contentType = 'application/json') {
    super();
    this.Authorization = botKey;
    this['Content-Type'] = contentType;
  }
}
