import { GetConfigurationRequest } from './models/getConfiguration/GetConfigurationRequest';
import { GetConfigurationResponse } from './models/getConfiguration/GetConfigurationResponse';
import { Json } from '../../utils/Json';
import axios from 'axios';
import { BlipRoute } from '../base/constants/Commands/BlipRoute';
import { BlipCommandHeader } from '../base/models/BlipCommandHeader';
import { GetStatesResponse } from './models/getStates/GetStatesResponse';
import { GetStatesResquest as GetStatesRequest } from './models/getStates/GetStatesRequest';
import { ILogArgs, json, logger } from 'packs-template-baseweb';
import { GetInfoWppActiveRequest } from './models/getInfoWppActiveAsync/GetInfoWppActiveRequest';
import { GetInfoWppActiveResponse } from './models/getInfoWppActiveAsync/GetInfoWppActiveResponse';

const logArgs: ILogArgs = {
  className: 'ConfigurationService',
};

const msging = '@msging.net';

async function GetConfigurationsAsync(
  botKey: string,
  userMail: string,
  from?: string,
  pp?: string,
): Promise<GetConfigurationResponse> {
  logArgs.methodName = "GetConfigurationsAsync";

  try {
    const request = new GetConfigurationRequest(userMail, from, pp);

    logger.info('Request configurations');
    logger.debug(Json.StringifyFormat(request));

    const { data, status } = await axios.post<GetConfigurationResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    logger.debug(Json.StringifyFormat(data));
    logger.info(`Configurations received with status code ${status}.`);

    return data;
  } catch (exception: any) {
    logger.error(`Error on request configurations: ${exception}`, logArgs);

    throw exception;
  }
}

async function GetAcountInformation(
  botKey: string | undefined,
  userMail: string,
  from?: string,
  pp?: string,
): Promise<any> {
  logArgs.methodName = "GetAcountInformation";
  
  const request = new GetConfigurationRequest(userMail, from, pp);
  request.uri = '/account';

  try {
    const { data, status } = await axios.post<GetConfigurationResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    logger.info(`Configurations received with status code ${status}.`);

    return data;
  } catch (exception: any) {
    logger.error(`Error on get account information. | 
    request: '${json.format(request)}' | 
    error: ${json.format(exception)}`, logArgs);
    throw exception;
  }
}

async function GetApplicationConfiguration(botKey: string, userMail: string): Promise<any> {
  const response = await GetConfigurationsAsync(botKey, userMail);

  const application = JSON.parse(response.resource.Application);

  return application;
}

async function GetRouterIdentifier(botKey: string, userMail: string): Promise<string> {
  const application = await GetApplicationConfiguration(botKey, userMail);

  return application.identifier + msging;
}

async function GetFlowIdentifier(botKey: string, userMail: string, skillIdentifier: string): Promise<string> {
  logArgs.methodName = "GetFlowIdentifier";
  try {
    const routerIdentifier = await GetRouterIdentifier(botKey, userMail);

    logger.info('Request flow identifier');

    const response = await GetConfigurationsAsync(botKey, userMail, skillIdentifier, routerIdentifier);

    const application = JSON.parse(response.resource.Application);

    const flowId = application.settings.flow.id;

    logger.debug(flowId);

    return flowId;
  } catch (exception: any) {
    logger.error(`Error on request flow identifier ${exception}`, logArgs);

    throw exception;
  }
}

async function GetStatesAsync(botKey: string, userMail: string, skillName: string): Promise<GetStatesResponse> {
  logArgs.methodName = "GetStatesAsync";
  try {
    const request = new GetStatesRequest(userMail, skillName);

    logger.info(`Request states of ${skillName}`);
    logger.debug(Json.StringifyFormat(request));

    const { data, status } = await axios.post<GetStatesResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    logger.info(`States of of ${skillName} received with status code ${status}.`);
    logger.debug(Json.StringifyFormat(data));

    return data;
  } catch (exception: any) {
    logger.error(`Error on request configurations: ${exception}`, logArgs);

    throw exception;
  }
}

async function GetInfoWppActiveAsync(botKey: string): Promise<GetConfigurationResponse> {
  logArgs.methodName = "GetConfigurationsAsync";

  try {
    const request = new GetInfoWppActiveRequest();

    logger.info('Request configurations');
    logger.debug(Json.StringifyFormat(request));

    const { data, status } = await axios.post<GetInfoWppActiveResponse>(BlipRoute.Messaging, request, {
      headers: new BlipCommandHeader(botKey),
    });

    logger.debug(Json.StringifyFormat(data));
    logger.info(`Configurations received with status code ${status}.`);

    return data;
  } catch (exception: any) {
    logger.error(`Error on request configurations: ${exception}`, logArgs);

    throw exception;
  }
}

export const ConfigurationService = {
  GetConfigurationsAsync,
  GetAcountInformation,
  GetStatesAsync,
  GetApplicationConfiguration,
  GetRouterIdentifier,
  GetFlowIdentifier,
  GetInfoWppActiveAsync,
};
