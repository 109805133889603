import React, { FC } from 'react';
import { Module } from 'typings/Module';
import { Container } from './style';

type SidebarItemProps = {
  module: Module;
  onClick: (menuItem: Module) => void;
};

const SiderbarItem: FC<SidebarItemProps> = ({ module, onClick }) => {
  const handleOnClick = () => onClick && onClick(module);

  return (
    <Container>
      <bds-tooltip tooltip-text={module.tooltipText} position="right-bottom">
        <bds-icon name={module.iconName} size="x-large" onClick={handleOnClick} color="white" />
      </bds-tooltip>
    </Container>
  );
};

export default SiderbarItem;
