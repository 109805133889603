/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Tabs } from 'types/Tabs';

import { MainContainer } from './styles';
import tabsTexts from 'texts/tabsTexts.json';
import { validateTextInput } from 'utils/validates/validateTextInput';

import { BdsInput } from 'blip-ds/dist/blip-ds-react/components';
import { useHumanServiceContext } from 'contexts/HumanServiceContext';

type InputProps = {
  onChangeServiceQuestionList: (question: { [x: string]: string }, index: number) => void;
  setTabData: Dispatch<SetStateAction<Tabs>>;
  index: number;
  onFocus: (index: number, preServiceQuestion: string) => void;
  onBlur: () => void;
};

export const InputPreServiceQuestion = ({
  onChangeServiceQuestionList,
  index,
  setTabData,
  onFocus,
  onBlur
}: InputProps) => {
  const [isModified, setIsModified] = useState(false);

  const { preServiceQuestionList } = useHumanServiceContext();

  const objectQueue = Object.keys(preServiceQuestionList[index]);

  const handleOnChange = (event: Event) => {
    const input = event.target as HTMLSelectElement | null;
    if (objectQueue) {
      onChangeServiceQuestionList({ [objectQueue[0]]: input?.value as string }, index);

      setIsModified(true);
    }
  };

  useEffect(() => {
    if (isModified) {
      setTabData(current => {
        return {
          ...current,
          isModified,
        };
      });
    }
  }, [isModified]);

  const validateInputPreServiceQuestion = (questions: { [x: string]: string }) => {
    let isValid = true;

    objectQueue.forEach(queue => {
      isValid = isValid && validateTextInput(questions[queue]);
    });
    return isValid;
  };

  useEffect(() => {
    setTabData(current => {
      return {
        ...current,
        isInvalid: !validateInputPreServiceQuestion(preServiceQuestionList[index]),
      };
    });
  }, [preServiceQuestionList]);

  const getPreServiceQuestion = () => {
    return objectQueue ? preServiceQuestionList[index][objectQueue[0]] : ' ';
  }

  return (
    <MainContainer>
      <BdsInput
        isTextarea={true}
        rows={4}
        value={getPreServiceQuestion()}
        label={`Pergunta para fila ${objectQueue[0]}`}
        placeholder={tabsTexts.preService.preServiceQuestion.placeholder}
        required={false}
        requiredErrorMessage={tabsTexts.error.requiredField}
        onBdsChange={event => handleOnChange(event)}
        onFocus={() => onFocus(index, preServiceQuestionList[index][objectQueue[0]])}
        onBlur={onBlur}
        danger={getPreServiceQuestion() === ''}
        errorMessage={getPreServiceQuestion() === '' ? tabsTexts.error.requiredField : ''}
      />
    </MainContainer>
  );
};
