import axios from 'axios';
import { AUTHORIZATION_TOKEN, AUTHORIZATION_NAME, MAIL_API_BASE_URL } from '../constants/MailCredentials';
import { MAIL_TEMPLATE } from 'constants/ActivationEmail';

const mailApi = axios.create({
  baseURL: MAIL_API_BASE_URL,
});

const sendEmail = (
  fromName: string,
  tos: {
    toName: string;
    toMailAddress: string;
  },
  subject: string,
  body: string,
  isHtml: any,
) => {
  const toMailAddress = tos.toMailAddress;
  const toName = tos.toName;
  const formData = new FormData();
  formData.append('fromName', fromName);
  formData.append('tos[0].toName', toName);
  formData.append('tos[0].toMailAddress', toMailAddress);
  formData.append('subject', subject);
  formData.append('body', body);
  formData.append('isHtml', isHtml);
  return mailApi.post('/api/Mail', formData, {
    auth: {
      username: AUTHORIZATION_NAME,
      password: AUTHORIZATION_TOKEN,
    },
  });
};
const formatUpgradeWAMail = (userName: string, email: string, companyName: string): string => {
  let html = MAIL_TEMPLATE.html.code;
  html = html.replace(
    'userData',
    `
        <li> Nome: ${userName}</li>
        <li> E-mail: ${email}</li>
        <li> Empresa: ${companyName}</li>
    `,
  );
  return html;
};
const formatWAMail = (
  userName: string,
  email: string,
  companyPhone: string,
  personalPhone: string,
  companyName: string,
  cnpj: string,
  site: string,
  activateTrial?: string,
): string => {
  let html = MAIL_TEMPLATE.html.code;
  html = html.replace(
    'userData',
    `
        <li> Nome: ${userName}</li>
        <li> E-mail: ${email}</li>
        <li> Telefone: ${companyPhone}</li>
        <li> Telefone pessoal: ${personalPhone}</li>
        <li> Empresa: ${companyName}</li>
        <li> CPNJ: ${cnpj}</li>
        <li> Site: ${site}</li>
        <li> Período de teste: ${activateTrial}</li>
    `,
  );
  return html;
};

const formatContractMail = (
  userName: string,
  email: string,
  corporateName: string,
  companyName: string,
  companyAddress: string,
  procuratorName: string,
  procuratorCPF: string,
  procuratorEmail: string,
): string => {
  let html = MAIL_TEMPLATE.html.code;
  html = html.replace(
    'userData',
    `
        <li> Nome: ${userName}</li>
        <li> E-mail: ${email}</li>
        <li> Razão social: ${corporateName}</li>
        <li> Nome fantasia: ${companyName}</li>
        <li> Endereço: ${companyAddress}</li>
        <li> Nome representante legal: ${procuratorName}</li>
        <li> CPF representante legal: ${procuratorCPF}</li>
        <li> E-mail representante legal: ${procuratorEmail}</li>
    `,
  );
  return html;
};
export const MailService = {
  sendEmail,
  formatWAMail,
  formatContractMail,
  formatUpgradeWAMail,
};
