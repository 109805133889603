import { RouterData } from 'types/PluginProps';
import { BuildUrlChannels } from 'services/blipChat/buildUrlChannels';

export default class ChannelsController {
  private routerData: RouterData;
  private accountToken: string;

  constructor(routerData: RouterData, accountToken: string) {
    this.routerData = routerData;
    this.accountToken = accountToken;
  }

  public getTenantId = async () => {
    let tenantId = '';

    try {
      tenantId = await BuildUrlChannels.getTenantId(this.accountToken, this.routerData.shortName);
    } catch (error) {
      console.error('Error in get tenant id application', error);
    }

    return tenantId;
  };

  public activateChannelsUrl = async (tenandId: string) => {
    let activateInstagramUrl = '';
    let activateMessengerUrl = '';

    try {
      const activatedChannels = await BuildUrlChannels.buildActivateChannelsUrl(tenandId, this.routerData.shortName);

      if (activatedChannels) {
        activateInstagramUrl = activatedChannels.activateInstagramUrl;
        activateMessengerUrl = activatedChannels.activateMessengerUrl;
      }
    } catch (error) {
      console.error('Error in activate channel', error);
    }

    return { activateInstagramUrl, activateMessengerUrl };
  };

  public blipChatUrl = async (tenandId: string) => {
    let blipChatUrl = '';

    try {
      blipChatUrl = await BuildUrlChannels.buildBlipChatUrl(tenandId, this.routerData.shortName);
    } catch (error) {
      console.error('Error in build blip chat url', error);
    }

    return blipChatUrl;
  };

  public channelsUrl = async () => {
    const tenantId = await this.getTenantId();
    let activateChannels = { activateMessengerUrl: '', activateInstagramUrl: '' };
    let blipChatUrl = '';

    try {
      activateChannels = await this.activateChannelsUrl(tenantId);
      blipChatUrl = await this.blipChatUrl(tenantId);
    } catch (error) {
      console.error('Error in channels url', error);
    }

    return {
      messeger: activateChannels.activateMessengerUrl,
      instagram: activateChannels.activateInstagramUrl,
      blipChat: blipChatUrl,
    };
  };
}
