import React, { useEffect, Dispatch, SetStateAction, useRef, useState } from 'react';
import { IframeContent, ModalContent, ModalFooter, SurveyContainer, TextContainer } from './styles';
import useTrack from 'hooks/useTrack';
import { MODALIFRAME_SURVEY } from 'constants/TrackingEvents';

type ConfirmAccountModalProps = {
  src: string;
  isOpen: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};

const ModalIframe: React.FC<ConfirmAccountModalProps> = ({ isOpen, src, setOpenModal }) => {
  const modalRef = useRef<null | HTMLBdsModalElement>(null);
  const { track } = useTrack();
  const [isSurvey, setIsSurvey] = useState<boolean>(true);

  useEffect(() => {
    modalRef.current?.addEventListener('bdsModalChanged', (event: any) => {
      if (event.detail.modalStatus === 'closed') {
        setOpenModal(false);
      }
    });
  }, [modalRef, setOpenModal]);

  const saveSurvey = (answer: string) => {
    setIsSurvey(false);

    track(MODALIFRAME_SURVEY, {
      answer: answer,
    });
  };

  return (
    <bds-modal ref={modalRef} open={isOpen} close-button="true" size="dynamic">
      <ModalContent>
        <bds-loading-spinner color="main" size="small" />
        <IframeContent src={src} allowFullScreen />

        <ModalFooter>
          <TextContainer>
            <bds-typo variant="fs-14">
              Pressione o <b>CTRL</b> e use o <b>scroll do mouse</b> para aumentar ou diminuir o zoom facilmente.
            </bds-typo>
          </TextContainer>

          {isSurvey ? (
            <SurveyContainer>
              <bds-typo variant="fs-14" bold="bold">
                Este fluxo ajudou você?
              </bds-typo>

              <bds-button icon="like" size="short" variant="ghost" type-icon="icon" onClick={() => saveSurvey('Sim')}>
                Sim
              </bds-button>

              <bds-button
                icon="deslike"
                size="short"
                variant="ghost"
                type-icon="icon"
                onClick={() => saveSurvey('Não')}
              >
                Não
              </bds-button>
            </SurveyContainer>
          ) : (
            <SurveyContainer>
              <bds-typo variant="fs-14" bold="bold">
                Agradeçemos a sua resposta!
              </bds-typo>
            </SurveyContainer>
          )}
        </ModalFooter>
      </ModalContent>
    </bds-modal>
  );
};

export default ModalIframe;
