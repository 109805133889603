import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 16%;
`;

export const Icon = styled.div`
    display: block;
    width: 48px;
    margin: auto;
`;

export const TextIcon = styled.div`
    font-size: 14px;
    width: 171px;
    margin: auto;
    text-align: center;
    color: #8C8C8C;
`;