import { Environments } from 'constants/Environments';
import { ConfigurationBlipChat as _configurationBlipChat } from './BlipChat';
import { ApplicationService as _applicationService } from '../applicationService/ApplicationService';

function baseUrl(prod: string, hmg: string) {
  return Environments.IsProduction() ? prod : hmg;
}

async function getTenantId(accountToken: string, shortName: string) {
  const applicationInstallation = await _applicationService.getApplicationInstallation(accountToken, shortName);

  return applicationInstallation.tenantId;
}

async function getBlipChatAppKey(botShortName: string) {
  let configKey = await _configurationBlipChat.getBlipChatConfigKey(botShortName);

  if (configKey === undefined) {
    configKey = { appKey: '' };
  }

  return { appKey: configKey.appKey };
}

async function buildBlipChatUrl(tenandId: string, botShortName: string) {
  const urlbase = baseUrl('chat.blip.ai/', 'hmg-chat.blip.ai');
  if (botShortName != undefined) {
    const blipChatConfig = await getBlipChatAppKey(botShortName);

    const blipChatUrl = `https://${tenandId}.${urlbase}?appKey=${blipChatConfig.appKey}`;

    return blipChatUrl;
  }
  return '';
}

async function buildActivateChannelsUrl(tenandId: string, botShortName: string) {
  const urlbase = baseUrl('blip.ai/application/detail/', 'hmg.blip.ai/application/detail/');

  const activateInstagramUrl = `https://${tenandId}.${urlbase}${botShortName}/channels/instagram`;
  const activateMessengerUrl = `https://${tenandId}.${urlbase}${botShortName}/channels/messengerDpr`;
  return { activateInstagramUrl, activateMessengerUrl };
}

export const BuildUrlChannels = {
  buildBlipChatUrl,
  buildActivateChannelsUrl,
  getTenantId,
};
