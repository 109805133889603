import Rocket from 'assets/Rocket.svg';
import ExternalUrls from 'constants/ExternalUrls';
import { ModuleIds } from 'constants/ModuleIds';
import { PACK_MANAGER_ROUTE } from 'constants/Routes';
import { HOME_TEST_BOT } from 'constants/TrackingEvents';
import { useAuthContext } from 'contexts/AuthContext';
import { usePackContext } from 'contexts/PackContext';
import useTrack from 'hooks/useTrack';
import { useNavigate } from 'react-router-dom';
import Tips from './components/Tips';
import YoutubePlayer from './components/YoutubePlayer';
import { BottomContainer, Container, ImageContainer, TipsContainer, VideoContainer } from './styles';
import ChannelsController from 'controller/ChannelsController';
import { ILogArgs, logger } from 'packs-template-baseweb';
import { useEffect } from 'react';
import { handleInstagramAndMessengerChannelsWithHubspot } from 'services/ValidateChannelWithHubspot';
import { mountKey } from 'utils/mountKey';

const Home = () => {
  const logArgs: ILogArgs = {
    className: 'Home',
  };

  const navigate = useNavigate();
  const { track } = useTrack();
  const { modules, setActiveModule } = usePackContext();
  const { routerData, accountToken } = useAuthContext();

  const handleInstagramAndMessengerChannelsHubspot = async () => {
    await handleInstagramAndMessengerChannelsWithHubspot(
      mountKey(routerData.shortName ?? '', routerData.accessKey ?? ''),
      accountToken ?? '',
    );
  };

  useEffect(() => {
    if (accountToken != undefined && accountToken !== '') {
      handleInstagramAndMessengerChannelsHubspot();
    }
  }, [accountToken]);

  const redirectToBlipChat = () => {
    logArgs.methodName = 'redirectToBlipChat';

    const channelsController = new ChannelsController(routerData, accountToken || '');

    channelsController
      .channelsUrl()
      .then(value => value)
      .then(resp => {
        window.open(resp.blipChat, '_blank');
      })
      .then(() => {
        track(HOME_TEST_BOT);
        logger.info('Redirected to Blip Chat', logArgs);
      })
      .catch(err => logger.error(`Error on redirect do Blip chat: ${err}`, logArgs));
  };

  const handleNavigate = (route: string, moduleName?: string) => {
    logArgs.methodName = "handleNavigate";

    if (moduleName) {
      const moduleId =
        ModuleIds[
          moduleName as
            | 'desk'
            | 'blipGoHome'
            | 'configuration'
            | 'whatsAppProfile'
            | 'channels'
            | 'blipGoConfig'
            | 'blipGoChannels'
            | 'blipGoHelp'
            | 'blipGoBot'
        ];
      const selectedModule = modules.find(module => module.id === moduleId);
      setActiveModule(selectedModule);
    } else {
      logger.error(`Tried navigate to module doesn't exists: ${moduleName}`, logArgs);
    }
    navigate(`${PACK_MANAGER_ROUTE}\\${route}`);
  };

  return (
    <Container>
      <bds-grid container style={{ minWidth: 'fit-content' }}>
        <bds-grid xxs="12" flex-wrap="wrap" style={{ maxWidth: 1376 }}>
          <bds-grid xxs="12" md="8" margin="b-4">
            <bds-paper>
              <VideoContainer>
                <bds-typo variant="fs-24" bold="bold" style={{ textAlign: 'start' }}>
                  Meu bot em um Blip
                </bds-typo>
                <bds-typo variant="fs-16" style={{ textAlign: 'start', marginBottom: 32 }}>
                  Seu bot está pronto, e levou apenas alguns minutos para criarmos para você, veja o vídeo e saiba mais.
                  Para começar a entender seus clientes, personalize com as informações da sua empresa utilizando o
                  menu, ou o guia ao lado.
                </bds-typo>
                <YoutubePlayer videoUrl={ExternalUrls.youtubeHomeVideo} />
              </VideoContainer>
            </bds-paper>
          </bds-grid>
          <bds-grid xxs="12" md="4" margin="b-4">
            <TipsContainer>
              <Tips handleNavigate={handleNavigate} redirectToBlipChat={redirectToBlipChat} track={track} />
              <ImageContainer>
                <img src={Rocket} alt="rocket" />
              </ImageContainer>
            </TipsContainer>
          </bds-grid>

          <BottomContainer>
            <bds-typo variant="fs-12">
              © 2023 Blip Go! - Powered by Blip | Todos os direitos reservados |
              <a href={ExternalUrls.termsOfUseBlipGo} target="_blank" rel="noreferrer">
                Termos de Uso
              </a>
            </bds-typo>
          </BottomContainer>
        </bds-grid>
      </bds-grid>
    </Container>
  );
};

export default Home;
