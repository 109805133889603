import { Day } from 'types/humanService/Day';
import { Tabs } from 'types/Tabs';
import { RESOURCES } from 'constants/ResourcesNames';
import { TEXT_TYPE } from 'constants/Application';
import { api } from 'services/requests/Api';
import { Requests } from 'services/requests/Requests';

import { RouterData } from 'types/PluginProps';

import { validateHours } from 'utils/validates/humanService/validateHours';

import { setWorkSchedule } from 'services/humanService/resources/functions/setWorkSchedule';
import { setEarlierAndLaterValues } from 'services/humanService/resources/functions/setEarlierAndLaterValues';
import { setHoursMessage } from 'services/humanService/resources/functions/setHoursMessage';

import saveResourceData from './saveResourceData';

import { WarningConstants } from 'constants/WarningsConstants';
import { SendTrackProps } from 'types/humanService/SendTrackProps';
import { LoggedAreaTracks } from 'services/humanService/trackings/handleSendTrackings';

interface SaveDaysProps {
  setAttendanceHours: React.Dispatch<React.SetStateAction<Tabs>>;
  days: (string | Day)[][];
  twentyFourHours: boolean;
  trackConfig: SendTrackProps;
  routerData: RouterData;
  keyRouter: string;
}

export const saveDays = async ({
  setAttendanceHours,
  days,
  twentyFourHours,
  routerData,
  trackConfig,
  keyRouter,
}: SaveDaysProps) => {
  const loggedAreaTracks = new LoggedAreaTracks();

  const onlyDays = days.map(day => day[1]);
  const request = new Requests(api(keyRouter));

  if (validateHours(onlyDays as Day[])) {
    const { workSchedule, daysPortugueseNames } = setWorkSchedule(days);

    const [sunday, monday, tuesday, wednesday, thursday, friday, saturday, holiday]: Day[] = onlyDays as Day[];

    const workdays: Day[] = [monday, tuesday, wednesday, thursday, friday];

    const { earlier: saturdayStartEarlier, later: saturdayEndLater } = setEarlierAndLaterValues(saturday, false);
    const { earlier: sundayStartEarlier, later: sundayEndLater } = setEarlierAndLaterValues(sunday, false);
    const { earlier: holidayStartEarlier, later: holidayEndLater } = setEarlierAndLaterValues(holiday, false);

    const { workdayEndLater, workdayStartEarlier } = setEarlierAndLaterValues(workdays, true);

    const { msg: openingHoursMessage } = setHoursMessage(days);

    const responses = await saveResourceData(request, [
      {
        name: RESOURCES.WORK_SCHEDULE,
        type: TEXT_TYPE,
        value: JSON.stringify(workSchedule),
      },
      {
        name: RESOURCES.WORK_DAYS,
        type: TEXT_TYPE,
        value: daysPortugueseNames.toString(),
      },
      {
        name: RESOURCES.DAYS.WORKDAY.START,
        value: workdayStartEarlier || '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.WORKDAY.END,
        value: workdayEndLater || '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.SUNDAY.START,
        value: sundayStartEarlier || '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.SUNDAY.END,
        value: sundayEndLater || '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.SATURDAY.START,
        value: saturdayStartEarlier || '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.SATURDAY.END,
        value: saturdayEndLater || '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.HOLIDAY.START,
        value: holidayStartEarlier || '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.HOLIDAY.END,
        value: holidayEndLater || '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.HOLIDAYS,
        value: holiday.holidays || '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.HOLIDAY_ATTENDANCE,
        value: JSON.stringify(holiday.active),
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.TWENTY_FOUR_HOURS,
        value: twentyFourHours.toString(),
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.MESSAGES.WORKDAY_WITH_SATURDAY,
        value: openingHoursMessage || '',
        type: TEXT_TYPE,
      },
    ]);

    setAttendanceHours(current => {
      return {
        ...current,
        isOpenModalSuccess: true,
      };
    });

    await loggedAreaTracks.sendTrackClickButtonSave({
      screenName: 'Horários',
      service24h: false,
      msgWarning: 'none',
      ...trackConfig,
      success: true,
      error: responses.length === 0 ? 'The data was not saved' : 'none',
    });

    return responses;
  } else {
    setAttendanceHours(current => {
      return {
        ...current,
        isInvalid: true,
        isOpenWarning: true,
      };
    });

    await loggedAreaTracks.sendTrackClickButtonSave({
      screenName: 'Horários',
      service24h: false,
      msgWarning: WarningConstants.ATTENDANCEHOURSWARNING,
      ...trackConfig,
      success: false,
      error: 'user input error`',
    });
  }
};
