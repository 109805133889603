import BotContent from 'components/Simulator/BotContent';
import SimulatorContainer from 'components/Simulator/SimulatorContainer';
import UserContent from 'components/Simulator/UserContent';
import { useHumanServiceContext } from 'contexts/HumanServiceContext';
import React from 'react';

type IAttendanceQueueSimulatorProps = {
    isBlurQueue: boolean;
    isBlurQueueMessage: boolean;
};

const AttendanceQueueSimulator: React.FC<IAttendanceQueueSimulatorProps> = ({
    isBlurQueue,
    isBlurQueueMessage,
}: IAttendanceQueueSimulatorProps) => {
    const { preQueueMessage, queueList } = useHumanServiceContext();

    const renderAttendanceQueueSimulator = () => {
        return (
            <React.Fragment>
                <UserContent
                    key={'attendanceQueueSimulator-user-content-1'}
                    testid="attendanceQueueSimulator-user-content-1"
                    time="22:15"
                    disable
                    message="Falar com atendente"
                />
                <BotContent
                    key={'attendanceQueueSimulator-bot-content-1'}
                    testid="attendanceQueueSimulator-bot-content-1"
                    time="22:45"
                    disable={isBlurQueueMessage}
                    message={preQueueMessage}
                />
                <BotContent
                    key={'attendanceQueueSimulator-bot-content-2'}
                    testid="attendanceQueueSimulator-bot-content-2"
                    time="22:45"
                    disable={isBlurQueue}
                    message={handleMountedQueueList()}
                />
            </React.Fragment>
        );
    };

    const handleMountedQueueList = () => {
        return queueList.map((queue, index) => {
            return (
                <React.Fragment key={index}>
                    <b key={index}>{`${index + 1}. ${queue.name}`}</b>
                    <br />
                </React.Fragment>
            );
        });
    };

    return (
        <React.Fragment>
            <SimulatorContainer>
                {renderAttendanceQueueSimulator()}
            </SimulatorContainer>
        </React.Fragment>
    );
};

export default AttendanceQueueSimulator;