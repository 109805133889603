import { UpdateContactHubspot } from 'constants/UpdateContactHubspot';
import { ApiService } from './Api';
import { api } from './requests/Api';
import { Requests } from './requests/Requests';
import { v4 as uuidv4 } from 'uuid';

export const IS_PRO_PLAN_REQUESTED_ACTIVE = 'true';
const UPDATE_CONTACT_HUBSPOT = 'v1/hubspot/contact';

type UPDATE_CONTACT_BODY = {
    properties: UPDATE_CONTACT_PROPERTIES[];
};

type UPDATE_CONTACT_PROPERTIES = {
    property: string;
    value: string;
};

export const IS_PRO_PLAN_REQUESTED = 'isProPlanRequested';

const PacksApiServer = process.env.REACT_APP_API_SERVER ?? '';
const PacksApiPort = process.env.REACT_APP_API_PORT ?? '';

export const handleGetIsProPlanRequestedResource = async (keyRouter: string) => {
    const request = new Requests(api(keyRouter));

    return await request.getSpecificResource(uuidv4(), IS_PRO_PLAN_REQUESTED, false, true);
};

export const handleSetIsProPlanRequestedResource = async (keyRouter: string) => {
    const request = new Requests(api(keyRouter));

    return await request.setResource(IS_PRO_PLAN_REQUESTED_ACTIVE, IS_PRO_PLAN_REQUESTED, uuidv4(), false);
};

export const handleUpdateContactHubspot = async (status: string, accessToken: string) => {
    const updateContactProperty = {
        property: UpdateContactHubspot.Status,
        value: status
    } as UPDATE_CONTACT_PROPERTIES;

    await updateContactBlipGo([updateContactProperty], accessToken);
};

const updateContactBlipGo = async (properties: UPDATE_CONTACT_PROPERTIES[], accessToken: string) => {
    const apiService = new ApiService(PacksApiServer, PacksApiPort, accessToken);

    const updateContactBody = { properties: properties } as UPDATE_CONTACT_BODY;

    await apiService.post(UPDATE_CONTACT_HUBSPOT, updateContactBody);
};