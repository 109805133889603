import { RESOURCES } from '../../constants/ResourcesNames';
import { api } from '../../services/requests/Api';
import { Requests } from '../../services/requests/Requests';
import { RouterData } from '../../types/PluginProps';
import { Tabs } from '../../types/Tabs';
import { validateTexts } from '../../utils/validates/validateTexts';
import saveResourceData from './saveResourceData';
import { ModuleBotConfigurationTracks } from '../trackingService/handleSendTrackings';
import { WarningConstants } from '../../constants/WarningsConstants';
import { SendTrackProps } from '../../types/botMolule/SendTrackProps';
import tabsTexts from '../../texts/tabsTexts.json';

interface saveCallButtonProps {
  setCallButton: React.Dispatch<React.SetStateAction<Tabs>>;
  messages: string[];
  routerData: RouterData;
  trackConfig: SendTrackProps;
  keyRouter: string;
}

export const saveCallButton = async ({
  messages,
  setCallButton,
  routerData,
  trackConfig,
  keyRouter,
}: saveCallButtonProps) => {
  const moduleBotConfigurationTracks = new ModuleBotConfigurationTracks();
  const request = new Requests(api(keyRouter));

  if (validateTexts(messages)) {
    const [buttonMessage] = messages;

    const responses = await saveResourceData(request, [
      {
        name: RESOURCES.MESSAGES.BUTTON_MESSAGE,
        type: 'text/plain',
        value: buttonMessage,
      },
    ]);

    setCallButton(current => {
      return {
        ...current,
        isOpenModalSuccess: true,
      };
    });

    await moduleBotConfigurationTracks.sendTrackClickButtonSave({
      screenName: tabsTexts.callButton.name,
      msgWarning: 'none',
      ...trackConfig,
      success: true,
      error: responses.length === 0 ? 'The data was not saved' : 'none',
    });

    return responses;
  } else {
    setCallButton(current => {
      return {
        ...current,
        isInvalid: true,
        isOpenWarning: true,
      };
    });
    await moduleBotConfigurationTracks.sendTrackClickButtonSave({
      screenName: tabsTexts.callButton.name,
      msgWarning: WarningConstants.GREETINGSWARNING,
      ...trackConfig,
      success: false,
      error: 'user input error',
    });
  }
};
