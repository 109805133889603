import BotContent from 'components/Simulator/BotContent';
import CTAContent from 'components/Simulator/CTAContent';
import SimulatorContainer from 'components/Simulator/SimulatorContainer';
import UserContent from 'components/Simulator/UserContent';
import React from 'react';
import { FIRST_CONTACT_MESSAGE } from '../../constants';

type IGreetingsSimulatorProps = {
  isBlur: boolean;
  focusComponent: string;
  returnMessage?: string;
  firstContactMessage?: string;
};

const GreetingsSimulator: React.FC<IGreetingsSimulatorProps> = ({
  isBlur,
  focusComponent,
  returnMessage,
  firstContactMessage,
}: IGreetingsSimulatorProps) => {
  const renderFirstContactMessage = () => {
    return (
      <React.Fragment>
        <UserContent
          key={'returnMessage-user-content-1'}
          testid="returnMessage-user-content-1"
          time="22:15"
          disable
          message="Oi"
        />
        <BotContent
          key={'returnMessage-bot-content-1'}
          testid="returnMessage-bot-content-1"
          time="22:45"
          disable={isBlur}
          message={<p>{firstContactMessage}</p>}
        />
        <BotContent
          key={'returnMessage-bot-content-2'}
          testid="returnMessage-bot-content-2"
          time="22:45"
          disable
          message={'Digite o número da opção do menu a seguir para continuarmos 👇'}
        />
        <BotContent
          key={'returnMessage-bot-content-3'}
          testid="returnMessage-bot-content-3"
          time="22:45"
          disable
          message={
            <p>
              <b>1. Nossos produtos/serviços</b>
              <br />
              <b>2. Quero comprar/contratar</b>
              <br />
              <b>3. Onde nos encontrar</b>
              <br />
              <b>4. Falar no WhatsApp</b>
            </p>
          }
        />
        <BotContent
          key={'returnMessage-bot-content-4'}
          testid="returnMessage-bot-content-4"
          time="22:45"
          disable
          message={
            <p>
              Ou então, caso queira <b>conversar com a nossa equipe</b>, selecione o botão abaixo.
            </p>
          }
        />
        <CTAContent
          key={'returnMessage-cta-content-1'}
          testid="returnMessage-cta-content-1"
          disable
          message="Falar com Atendente"
        />
      </React.Fragment>
    );
  };

  const renderReturnMessageSimulator = () => {
    return (
      <React.Fragment>
        <UserContent
          key={'firstContactMessage-user-content-1'}
          testid="firstContactMessage-user-content-1"
          time="22:15"
          disable
          message="Oi, sou eu de novo!"
        />
        <BotContent
          key={'firstContactMessage-bot-content-2'}
          testid="firstContactMessage-bot-content-2"
          time="22:45"
          disable={isBlur}
          message={<p>{returnMessage}</p>}
        />
        <BotContent
          key={'firstContactMessage-bot-content-3'}
          testid="firstContactMessage-bot-content-3"
          time="22:45"
          disable
          message={'Como posso te ajudar hoje? 🙂'}
        />
        <CTAContent
          key={'firstContactMessage-cta-content-1'}
          testid="firstContactMessage-cta-content-1"
          disable
          message="Falar com Atendente"
        />
        <CTAContent
          key={'firstContactMessage-cta-content-2'}
          testid="firstContactMessage-cta-content-2"
          disable
          message="Ir para o menu"
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <SimulatorContainer>
        {focusComponent === FIRST_CONTACT_MESSAGE ? renderFirstContactMessage() : renderReturnMessageSimulator()}
      </SimulatorContainer>
    </React.Fragment>
  );
};

export default GreetingsSimulator;
