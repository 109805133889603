export const MAIL_TEMPLATE = {
  html: {
    code: `<!DOCTYPE html> 
<head>
   <style> 	
   * {
    font-size: 14px !important; 		
    font-family: "Carbona", sans-serif; 		
    line-height: 22px; 	
    } 	
    .bold { 		
        font-weight: 500; 	
    } 	
    .side-by-side { 		
        display: flex; 		
        flex-direction: row; 	
    } 	
    body { 		
        margin: 80px 80px; 	
    } 
    </style>
</head>
<body>
   <ul type="square">
      userData
   </ul>
   <br /><br /> <!-- Assinatura do e-mail --> 
   <tr>
      <td>
         <table 
         width="466" 
         border="0" 
         cellspacing="0" 
         cellpadding="0"
         style="font-family: \'Nunito Sans\', Arial, Helvetica, sans-serif" align="center">
            <tbody>
               <tr>
                  <td width="140" style="border-right: 2px solid #221f1f"> 						<img src="https://www.blip.ai/newsign/img/sign-blip-gif.gif" style="margin-right: 22px" 							width="125" height="auto" alt="take" title="take" /> 					</td>
                  <td width="326">
                     <table border="0" cellspacing="0" cellpadding="0" style="margin-left: 22px">
                        <tbody>
                           <tr>
                              <td 
                              style="font-size: 14px;
                              color: #221f1f; 					
                              line-height: 25px; 					
                              text-transform: uppercase;"> 										
                              <b>Experience </b>Cluster</td>
                           </tr>
                           <tr>
                              <td 
                              style="font-size: 11px; 
                              color: #221f1f; 
                              line-height: 18px"> 										experiencecluster@blip.ai</td>
                           </tr>
                           <tr>
                              <td style="font-size: 11px; color: #221f1f; line-height: 18px"> 										<a href="https://digital.blip.ai/" 											style="color: #58585b; text-decoration: none">https://digital.blip.ai</a> 									</td>
                           </tr>
                           <tr></tr>
                        </tbody>
                     </table>
                  </td>
               </tr>
            </tbody>
         </table>
      </td>
   </tr>
</body>`,
  },
};
