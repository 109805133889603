export const isNumber = (value: string | number) => {
  return value != null && value !== '' && !isNaN(Number(value.toString()));
};

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
};

export const handleApiErrorResponseForTrack = (error: any) => {
  const responseData = error?.response?.data;
  return responseData ? (responseData as string) : (error as string);
};

export const hideOrShowComponentInCss = (isShow: boolean) => (isShow ? 'block' : 'none');
