import styled from 'styled-components';

export const HorizontalLine = styled.div`
  border-top: 1px solid #d2dfe6;
  margin-top: 1%;
  margin-bottom: 1%;
`;

export const TitleContainer = styled.div`
  padding: 25px 50px 0px 0px;
  margin-bottom: -25px;
`;

export const AttendanceHoursContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 50px;
`;

export const ColumnContainer = styled.div`
  padding: 25px 50px 25px 50px;
  width: 100%;
`;

export const TextContainer = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const SingleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 350px;
`;
