import { FC, useEffect, useState } from 'react';
import Menu from './components/menu';
import { CHANNELS_LIST } from './constants/channels';

import { MainContainer, LoadingContainerChannels } from './styles';
import { useAuthContext } from 'contexts/AuthContext';
import ChannelsController from 'controller/ChannelsController';

export const Channels: FC = () => {
  const { routerData, accountToken } = useAuthContext();
  const [itens] = useState(CHANNELS_LIST);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const routerDataLength = Object.keys(routerData).length;

    if (routerDataLength > 0 && loading) {
      const channelsController = new ChannelsController(routerData, accountToken || '');

      (async () => {
        const activateChannels = await channelsController.channelsUrl();

        itens.forEach(item => {
          const itemTitle = item.title.toLowerCase();

          if (itemTitle === 'messenger') {
            item.link = activateChannels.messeger;
          }

          if (itemTitle === 'instagram') {
            item.link = activateChannels.instagram;
          }

          if (itemTitle === 'blip chat') {
            item.link = activateChannels.blipChat;
          }
        });

        setLoading(false);
      })();
    }
  }, [accountToken, itens, loading, routerData]);

  return (
    <>
      {loading ? (
        <LoadingContainerChannels>
          <bds-loading-spinner color="main" size="small" data-testid="loading-channels" />
        </LoadingContainerChannels>
      ) : (
        <MainContainer>
          <Menu itens={itens} />
        </MainContainer>
      )}
    </>
  );
};
