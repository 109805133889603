import styled, { css } from 'styled-components';

interface MainContainerProps {
  lastInput: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 20px 0px;
  border-bottom: 1px solid #dce8ef;
  width: 100%;
  ${props =>
    props.lastInput &&
    css`
      border: none;
      width: 100%;
    `}
  bds-button-icon {
    margin-left: 10px;
  }

  flex-wrap: wrap;
`;

export const TurnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputChipsContainer = styled.div`
  width: 100%;
`;
