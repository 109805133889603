export const RESOURCES = {
  DAYS: {
    WORKDAY: {
      START: 'Workday_opening_hour',
      END: 'Workday_closing_hour',
    },
    SATURDAY: {
      START: 'Saturday_opening_hour',
      END: 'Saturday_closing_hour',
    },
    SUNDAY: {
      START: 'Sunday_opening_hour',
      END: 'Sunday_closing_hour',
    },
    HOLIDAY: {
      START: 'Holiday_opening_hour',
      END: 'Holiday_closing_hour',
    },
  },
  MESSAGES: {
    PRE_QUEUE: 'preQueueMessage',
    FIRST_CONTACT: 'firstContactMessage',
    RETURN: 'returnMessage',
    WORKDAY_WITH_SATURDAY: 'workdayWithSaturday',
    BUTTON_MESSAGE: 'titleCallUS',
  },
  HOLIDAY_ATTENDANCE: 'Holiday_attendance',
  HOLIDAYS: 'Holidays',
  QUEUES: 'teams',
  PRE_SERVICE_QUESTIONS: 'preServiceQuestionList',
  WORK_DAYS: 'Work_days',
  WORK_SCHEDULE: 'workSchedule',
  EMOJI: 'emoji',

  TWENTY_FOUR_HOURS: 'openHour',
  WITHOUT_FAQ: 'semFaq',

  SATISFACTION_SURVEY: 'serviceNote',
};
