import React from 'react';
import { useAuthContext } from 'contexts/AuthContext';
import { useHumanServiceContext } from 'contexts/HumanServiceContext';
import { Dispatch, SetStateAction } from 'react';
import { getAttendanceQueues } from 'services/getAttendanceQueues';
import { LoggedAreaTracks } from 'services/humanService/trackings/handleSendTrackings';
import { resetDays } from 'services/reset/humanService/resetDays';
import { resetPreServiceQuestions } from 'services/reset/humanService/resetPreServiceQuestions';
import { resetQueues } from 'services/reset/humanService/resetQueues';
import { resetSatisfactionSurvey } from 'services/reset/humanService/resetSatisfactionSurvey';
import tabsTexts from 'texts/tabsTexts.json';

type AlertsButtonCancelProps = {
  cancelWarning: boolean;
  setCancelWarning: Dispatch<SetStateAction<boolean>>;
};

export const AlertsButtonCancel = ({ cancelWarning, setCancelWarning }: AlertsButtonCancelProps) => {
  const { userData, activationOption } = useAuthContext();
  const {
    currentTab,
    setCurrentTab,
    resources,
    request,
    setQueueList,
    setEmojis,
    setPreQueueMessage,
    setPreServiceQuestionList,
    setSatisfactionSurvey,
    setSunday,
    setMonday,
    setTuesday,
    setWednesday,
    setThursday,
    setFriday,
    setSaturday,
    setHoliday,
    setTwentyFourHours,
    setIsCanceledAction,
  } = useHumanServiceContext();

  const loggedAreaTracks = new LoggedAreaTracks();

  const messageButtonCancel = `Suas alterações de ${currentTab} não serão salvas.`;

  const sendTrackingHandlePressYes = async () => {
    await loggedAreaTracks.sendTrackAlertOpen({
      merchantName: userData.name,
      screenName: currentTab ? currentTab : tabsTexts.attendanceQueue.title,
      activationOption,
      msgWarning: messageButtonCancel,
    });
  };

  const handleCancelChanges = () => {
    setIsCanceledAction(true);
    handleClickButton();
    setCancelWarning(!cancelWarning);

    sendTrackingHandlePressYes();
  };
  const handleCancelAlert = async () => {
    setCancelWarning(!cancelWarning);

    if (!currentTab) {
      setCurrentTab(tabsTexts.attendanceQueue.title);
    }

    sendTrackingButtonHandleCancelAlert(currentTab ? currentTab : tabsTexts.attendanceQueue.title, cancelWarning);
  };

  const sendTrackingButtonHandleCancelAlert = async (screenName: string, cancelWarning: boolean) => {
    if (!cancelWarning) {
      await loggedAreaTracks.sendTrackClickButtonEdit({
        merchantName: userData.name,
        screenName,
        activationOption,
      });
    }
  };

  const handleClickButton = async () => {
    switch (currentTab) {
      case 'Fila de atendimento':
        const teams = await getAttendanceQueues(request);

        setQueueList(teams);

        resetQueues({
          resources,
          setEmojis,
          setPreQueueMessage,
        });

        break;
      case 'Pré-atendimento':
        resetPreServiceQuestions({
          setPreServiceQuestionList,
          resources,
        });

        break;
      case tabsTexts.satisfactionSurvey.title:
        resetSatisfactionSurvey({ resources, setSatisfactionSurvey });

        break;
      case 'Horários':
        resetDays({
          resources,
          setSunday,
          setMonday,
          setTuesday,
          setWednesday,
          setThursday,
          setFriday,
          setSaturday,
          setHoliday,
          setTwentyFourHours,
        });

        break;
    }
  };

  return (
    <>
      <bds-alert open={cancelWarning}>
        <bds-alert-header variant="error" icon="warning">
          Deseja cancelar as alterações?
        </bds-alert-header>
        <bds-alert-body>{messageButtonCancel}</bds-alert-body>
        <bds-alert-actions>
          <bds-button variant="secondary" onClick={handleCancelChanges}>
            Sim
          </bds-button>
          <bds-button variant="secondary" onClick={handleCancelAlert}>
            Não
          </bds-button>
        </bds-alert-actions>
      </bds-alert>
    </>
  );
};
