import React from 'react';
import './styles.scss';
import { MODAL_TEXTS } from 'pages/channels/constants/modalTexts';

interface IWhatsappModalTextSubitem {
  title: string;
}

interface IWhatsappModalTextItem {
  title: string;
  subtitle: string;
  subitems: Array<IWhatsappModalTextSubitem>;
  basicPlanDisabled: boolean;
  basicPlanTitle: string;
  plan?: string;
}

interface IContractInformation {
  activateTrial?: boolean;
}

interface IModalText {
  [field: string]: any;
}

function RenderItems({ title, subtitle, basicPlanTitle, subitems, basicPlanDisabled, plan }: IWhatsappModalTextItem) {
  return (
    <div className={basicPlanDisabled && plan === 'basic' ? 'item-disabled' : ''}>
      <span className="bold">{plan === 'basic' ? basicPlanTitle : title}</span>
      <span>{subtitle}</span>
      <ul className="subitem-container">
        {subitems.map((subitem: IWhatsappModalTextSubitem) => {
          return <li key={subitem?.title}>{subitem?.title}</li>;
        })}
      </ul>
    </div>
  );
}

export const ProPlan = ({ activateTrial }: IContractInformation) => {
  const modalTexts = MODAL_TEXTS as IModalText;
  const proPlan = modalTexts?.WhatsApp.map((item: IWhatsappModalTextItem) => ({ ...item, plan: 'standard' }));
  return (
    <div>
      <div className="header-plan">
        <span>Contratação Plano Pro</span>
      </div>
      <div className="plan-paper-container">
        <bds-paper style={{ margin: '4px' }}>
          <div className="plan-container">
            <div>
              <div>
                <span className="plan-name">Plano </span>
                <span className="plan-name bolder">Pro</span>
              </div>
              <div className="plan-price">
                {activateTrial ?
                  <span className="bolder">7 dias grátis e depois apenas R$ 399,90</span> :
                  <span className="bolder">R$ 399,99</span>}
                <span>/mês</span>
              </div>
            </div>
            {proPlan?.map((item: IWhatsappModalTextItem) => {
              return <RenderItems key={item.title} {...item} />;
            })}
            <span>*Conversas extras via whatsapp por R$1,00/cada</span>
            <span>*Atendentes extras por R$150,00/cada (limite máximo de 10 atendentes no total)</span>
          </div>
        </bds-paper>
      </div>
    </div>
  );
};
