import { BlipPacksTrackingSegment } from 'constants/MicrofrontEndEvents';
import { getDeviceType } from '../utils';
import { PublishEvent } from './EventPublisher';

export type TrackProps = {
  screenName?: string;
  status?: string;
  error?: string;
  apiCall?: string;
};

export const logTrack = (event: string, trackProps?: TrackProps) => {
  let payload = {
    deviceType: getDeviceType(),
    operationalSystem: navigator.userAgentData?.platform ?? '',
    screenResolution: `${screen.width}x${screen.height}`,
    ownerSO: 'november',
  };

  if (trackProps) {
    payload = { ...payload, ...trackProps };
  }

  PublishEvent(BlipPacksTrackingSegment, { event, payload });
};
