export const MY_EVENT = 'my-event';
export const PG_API_CALL = 'blippacks-management-general-apicall';
export const PG_API_CALL_ERROR = 'blippacks-management-general-apicall-error';
export const PG_OPENED = 'blippacks-management-general-opened';
export const PG_OPEN_MODULE = 'blippacks-management-general-module';
export const PG_LOGOUT = 'blippacks-management-general-logout';
export const PG_OPEN_MOBILE_MENU = 'blippacks-management-general-menuhamburguer';
export const PG_CLOSE_MOBILE_MENU = 'blippacks-management-general-menuclosed';
export const PG_ACTIVE_WHATSAPP_BUTTON = 'blippacks-management-general-activationbutton-whatsapp';
export const PG_WHATSAPP_ACTIVATED = 'blippacks-management-general-activated-whatsapp';
export const PG_WHATSAPP_ACTIVATION_ERROR = 'blippacks-management-general-activatederror-whatsapp';
export const PG_EMBEDDED_SIGNUP_INTERRUPTED = 'blippacks-management-general-embeddedsingup-interrupted';
export const PG_WHATSAPP_UPDATE_PROFILE = 'blippacks-management-general-updateprofile-whatsapp';
export const PG_WHATSAPP_ACTIVATE = 'blippacks-management-general-active-whatsapp';
export const PG_OPEN_EMBEDDED_SIGNUP = 'blippacks-management-general-facebook-loginFB';
export const PG_EMBEDDED_SIGNUP_STATUS = 'blippacks-management-general-facebook-loginFB-status';
export const PG_TEST_BLIP_CHAT = 'blippacks-management-general-testbutton-blipchat';
export const PG_ACTIVATIONBUTTON_WPP = 'blippacks-management-general-activationbutton-whatsapp';
export const PG_REOPEN_WPP_POPUP = 'blippacks-management-general-reopen-popup';
export const ANALYTICS_KEY_HMG = 'd9pdZmq8Zir53Nrpm9X2seash4bPUUpt';
export const ANALYTICS_KEY_PRD = 'c54ZPJr9H48j4Js23n8ZiRLqJHiwjBjt';
export const SO_OPENED = 'blipapps-selfonboarding-opened';
export const SO_NEXT = 'blipapps-selfonboarding-next';
export const SO_LINK = 'blipapps-selfonboarding-link';
export const SO_FILTER = 'blipapps-selfonboarding-filter';
export const HOME_FIRST_ATTENDANCE = 'blippacks-management-general-home-first-attendance';
export const HOME_HUMAN_SERVICE_LINK = 'blippacks-management-general-home-invite-attendants-create-teams';
export const HOME_TEST_BOT = 'blippacks-management-general-home-steps-meet-thebot';
export const HOME_CUSTOMIZE_BOT_MENU = 'blippacks-management-general-home-customize-bot';
export const HOME_DEFINE_OPENING_HOURS = 'blippacks-management-general-home-define-opening-hours';
export const HOME_CONNECT_CHANNEL = 'blippacks-management-general-home-connect-channel';
export const HOME_HELP_CENTER = 'blippacks-management-general-home-help-center';
export const HOME_DESK_MOBILE = 'blippacks-management-general-home-desk-mobile';
export const HOME_CONNECT_WHATSAPP = 'blippacks-management-general-connect-whatsapp';
export const HOME_ADVANCED = 'blippacks-management-general-home-advanced-resources';
export const FLOWCHAT_BOT_SAW = 'blippacks-management-general-flowchatbot-saw';
export const MODALIFRAME_SURVEY = 'blippacks-management-general-flowchatbot-survey';
export const BLIPPACKS_MANAGEMENT_GENERAL_HOME_SIGN_PRO = 'blippacks-management-general-home-sign-pro';
export const BLIPPACKS_MANAGEMENT_GENERAL_HOME_TEST_BOT = 'blippacks-management-general-home-test-bot';
export const BLIPPACKS_MANAGEMENT_GENERAL_HOME_ASSIST_CELLPHONE = 'blippacks-management-general-home-assist-celphone';
export const BLIPPACKS_MANAGEMENT_GENERAL_HOME_STEPS_MEET_THE_BOT =
  'blippacks-management-general-home-steps-meet-the-bot';
