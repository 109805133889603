import { CreateToastType } from 'blip-ds/dist/types/components/toast/toast-interface';

export const createToast = async (toast: CreateToastType) => {
  if (toast) {
    toast.duration = 3;
    appendToast(toast);
  }
};

export const createToastSuccess = async (title: string, text?: string) => {
  if (!!text) {
    const toast: CreateToastType = {
      actionType: 'icon',
      icon: 'like',
      variant: 'success',
      toastTitle: title ?? '',
      toastText: text,
      position: 'bottom-left',
      duration: 3,
    };
    appendToast(toast);
  }
};

export const createToastError = async (title: string, text: string) => {
  if (!!title && !!text) {
    const toast: CreateToastType = {
      actionType: 'icon',
      icon: 'error',
      variant: 'error',
      toastTitle: title,
      toastText: text,
      position: 'bottom-left',
    };
    appendToast(toast);
  }
};

const appendToast = async (toast: CreateToastType) => {
  const newToast = document.createElement('bds-toast');
  document.body.appendChild(newToast);
  await newToast.create(toast);
};
