import { InputChipsTypes } from 'blip-ds/dist/types/components/input-chips/input-chips-interface';
import React, { useEffect, useRef } from 'react';

type InputChipsProps = {
  onChange: (e: Event) => void;
  disabled?: boolean;
  chips: string | undefined;
  duplicated?: boolean;
  label?: string;
  type?: InputChipsTypes;
  errorMessage?: string;
  helperMessage?: string;
  danger?: boolean;
};

export const InputChips = ({
  onChange,
  disabled,
  chips,
  duplicated,
  label,
  type,
  errorMessage,
  helperMessage,
  danger,
}: InputChipsProps) => {
  const inputChipsRf = useRef<null | HTMLBdsInputChipsElement>(null);

  useEffect(() => {
    inputChipsRf.current?.addEventListener('bdsChangeChips', event => onChange(event));
  }, [onChange]);

  return (
    <bds-input-chips
      ref={inputChipsRf}
      disabled={disabled}
      duplicated={duplicated}
      chips={disabled ? '' : chips}
      label={label}
      type={type ? type : 'text'}
      error-message={errorMessage}
      helper-message={helperMessage}
      danger={danger}
    />
  );
};
