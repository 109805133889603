/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { MAX_ATTENDANCE_QUEUE } from 'constants/Config';
import { Tabs } from 'types/Tabs';
import { ButtonIconContainer, InputContainer, InputTextContainer, LabelContainer, MainContainer } from './styles';

import { Requests } from 'services/requests/Requests';

import tabsTexts from 'texts/tabsTexts.json';
import { Queue } from 'types/humanService/Queue';
import { LoggedAreaTracks } from 'services/humanService/trackings/handleSendTrackings';
import { getAttendanceQueues } from 'services/humanService/getAttendanceQueues';
import Button from 'components/Button';
import { useAuthContext } from 'contexts/AuthContext';
import AlertDelete from 'pages/HumanService/components/AlertDelete';
import { BdsInput, BdsInputChips } from 'blip-ds/dist/blip-ds-react/components';
import Grid from 'components/Grid';

import { validateInputWithoutSpecialCharacter } from 'utils/validates/validateInputWithoutSpecialCharacter';
import { validateEmail } from 'utils/validates/validateEmail';

type QueueFieldsProps = {
  setQueueList: Dispatch<SetStateAction<Queue[]>>;
  setIdxRemoved: Dispatch<SetStateAction<number>>;
  setTabData: Dispatch<SetStateAction<Tabs>>;
  idxRemoved: number;
  queueList: Queue[];
  setIsCanceledAction: Dispatch<SetStateAction<boolean>>;
  isCanceledAction?: boolean;
  requestObject?: Requests;
  setBlurComponent: Dispatch<SetStateAction<boolean>>;
};

export const QueueFields = ({
  setQueueList,
  queueList,
  setIdxRemoved,
  setTabData,
  setIsCanceledAction,
  isCanceledAction,
  requestObject,
  setBlurComponent,
}: QueueFieldsProps) => {
  const { userData } = useAuthContext();
  const loggedAreaTracks = new LoggedAreaTracks();

  let list = [...queueList];
  const [isAlert, setIsAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const [indexRemove, setIndexRemove] = useState<number | undefined>(undefined);

  const handleAdd = async () => {
    setIsModified(true);

    const newQueuList = [...queueList, { name: '', emails: [] }];
    setQueueList(newQueuList);

    await loggedAreaTracks.sendTrackClickButtonAddQueue({
      merchantName: userData.Name,
      success: true,
      screenName: tabsTexts.attendanceQueue.title,
      error: 'none',
    });
  };

  const handleClickDeleteButton = async (index: number) => {
    setIndexRemove(index);

    setIsAlert(true);
    setIsOpen(true);

    await loggedAreaTracks.sendTrackClickButtonDelete({
      merchantName: userData.Name,
      success: true,
      screenName: tabsTexts.attendanceQueue.title,
      error: 'none',
      type: 'queue',
    });
  };

  const handleRemove = async () => {
    // Set if is removed and the index of queue removed for remove the pre service question field too.
    setIdxRemoved(indexRemove || 0);

    const newList = queueList.filter((_, index) => index !== indexRemove);
    list.splice(indexRemove || 0, 1);

    setQueueList(newList);

    setIsModified(true);

    setIsOpen(false);
  };

  const handleOnChange = (event: Event, index: number) => {
    const { value } = event.target as HTMLBdsInputElement;

    if (value !== undefined && value !== null) {
      const newList = list.map((queue, i) => {
        if (index === i) {
          return {
            ...queue,
            name: value,
          };
        }
        return queue;
      });

      setQueueList(newList);
    }
  };

  const handleOnChangeChips = async (event: Event, index: number) => {
    const input = event.currentTarget as HTMLBdsInputChipsElement | null;
    const chips = await input?.get();

    if (chips) {
      const newList = list.map((queue, i) => {
        if (index === i) {
          return {
            ...queue,
            emails: chips as string[],
          };
        }
        return queue;
      });

      setQueueList(newList);
    }
  };

  useEffect(() => {
    if (isModified) {
      setTabData(current => {
        return {
          ...current,
          isModified,
        };
      });
    }
  }, [isModified]);

  const handleGetAttendanceQueues = async () => {
    setQueueList([]);
    const teams = await getAttendanceQueues(requestObject as Requests);
    list = [...teams];

    setQueueList(teams);

    if (teams && teams.length && isCanceledAction) {
      list = [...teams];
      setIsCanceledAction(false);
    }
  };

  useEffect(() => {
    if (isCanceledAction) {
      handleGetAttendanceQueues();
    }
  }, [isCanceledAction]);

  return (
    <Grid xxs="12" flex-wrap="wrap" direction="row" style={{ paddingLeft: 0 }}>
      <Grid xxs="12" flex-wrap="wrap" direction="row" style={{ paddingLeft: 0 }}>
        {queueList &&
          queueList.map((queue, index) => {
            return (
              <Grid xxs="12" md="6" key={index} style={{ paddingLeft: 0, paddingRight: 16 }}>
                <MainContainer>
                  <LabelContainer>
                    <InputTextContainer queueListLength={queueList.length}>
                      <BdsInput
                        label={tabsTexts.attendanceQueue.queue.label}
                        value={queue.name}
                        onBdsChange={event => {
                          handleOnChange(event, index);
                        }}
                        required={true}
                        requiredErrorMessage={tabsTexts.error.requiredField}
                        onFocus={() => setBlurComponent(false)}
                        onBlur={() => setBlurComponent(true)}
                        danger={!validateInputWithoutSpecialCharacter(queue.name)}
                      />
                    </InputTextContainer>

                    {queueList.length > 1 && (
                      <ButtonIconContainer>
                        <bds-button-icon variant="ghost" icon="trash" onClick={() => handleClickDeleteButton(index)} />
                      </ButtonIconContainer>
                    )}
                  </LabelContainer>

                  <InputContainer>
                    <BdsInputChips
                      type="email"
                      label={tabsTexts.attendanceQueue.attendants.label}
                      chips={JSON.stringify(queue.emails, null, 1)}
                      onBdsChangeChips={event => {
                        handleOnChangeChips(event, index);
                      }}
                      helperMessage={tabsTexts.attendanceQueue.queue.instruction}
                      errorMessage={tabsTexts.attendanceQueue.queue.format}
                      danger={!validateEmail(queue.emails)}
                    />
                  </InputContainer>
                </MainContainer>
              </Grid>
            );
          })}
      </Grid>

      {queueList.length < MAX_ATTENDANCE_QUEUE && (
        <Grid xxs="12" justifyContent="flex-start" margin="t-2" style={{ paddingRight: 16 }}>
          <Button isAdd isFAQ={false} text={tabsTexts.attendanceQueue.queue.add} onClick={handleAdd} />
        </Grid>
      )}

      {isAlert && (
        <AlertDelete
          handleRemove={handleRemove}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          merchantName={userData.Name}
          screenName={tabsTexts.attendanceQueue.title}
          type="queue"
        />
      )}
    </Grid>
  );
};
