export const BLIP_GO_INTRO = 'blip-go-introduction';
export const WPP_PAYWALL = 'whatsapp-paywall';
export const ACTIVATION_FORM = 'activation-whatsapp-form';
export const TRIAL_WARN = 'trial-warn';
export const USER_NEEDS = [
  {
    title: '1 número de telefone para vários atendentes',
    selected: false,
    id: 'UmNumeroVariosAtendentes',
    icon: 'agent',
  },
  { title: 'Campanhas de mensagens em massa', selected: false, id: 'Campanhas', icon: 'megaphone' },
  { title: 'Segmentação do atendimento', selected: false, id: 'Segmentacao', icon: 'team' },
  {
    title: 'Respostas automáticas para perguntas frequentes',
    selected: false,
    id: 'RespostasAutomaticas',
    icon: 'robot',
  },
  { title: 'Pesquisa de satisfação do atendimento', selected: false, id: 'PesquisaSatisfacao', icon: 'user-engaged' },
  { title: 'Relatórios de atendimento', selected: false, id: 'Relatorios', icon: 'monitoring' },
];

export const WPP_PLANS_INFO = {
  free: {
    name: 'FREE',
    status: '(Seu plano atual)',
    price: 'R$ 0,00',
    actionBtn: { title: 'Testar meu bot', variant: 'ghost', testId: 'test-bot-btn' },
    introduction: 'Ideal para empresas que estão começando a estruturar sua gestão de atendimento.',
    features: {
      title: 'O plano FREE inclui:',
      items: [
        'Criar 3 equipes e convidar 5 atendentes',
        'Respostas automáticas para perguntas frequentes',
        'Pesquisa de satisfação do atendimento',
        'Relatórios de atendimento',
      ],
    },
    note: '',
  },
  pro: {
    name: 'PRO',
    status: '',
    price: 'R$ 399,99',
    actionBtn: { title: 'Contratar o plano', variant: 'primary', testId: 'upgrade-btn' },
    introduction: 'Indicado para empresas que já possuem uma segmentação de atendimento bem definida.',
    features: {
      title: 'Tudo do FREE, mais:',
      items: [
        'Criar 2 equipes (5 no total) e convidar 2 atendentes (7 no total)**',
        '1 número de telefone para vários atendentes',
        'Campanhas de mensagem em massa',
      ],
    },
    note: `<b>*450 Conversas no WhatsApp.</b> Passando disso, apenas R$1,00/cada. 
    **No PRO, pode contratar até <b>3 atendentes extras</b> por R$150,00/cada.`,
  },
};
export const SITE_OPTIONS = [
  { value: 'Sim', label: 'Sim' },
  { value: 'Não, mas pretendo adquirir em até 3 meses', label: 'Não, mas pretendo adquirir em até 3 meses' },
  { value: 'Não e não pretendo adquirir', label: 'Não e não pretendo adquirir' },
];
export const CNPJ_OPTIONS = [
  { value: 'Sim', label: 'Sim' },
  { value: 'Não, mas pretendo abrir em até 3 meses', label: 'Não, mas pretendo abrir em até 3 meses' },
  { value: 'Não e não pretendo abrir', label: 'Não e não pretendo abrir' },
];
