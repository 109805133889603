import { Requests } from 'services/requests/Requests';

import Guid from 'guid';
import { Queue } from 'types/humanService/Queue';

export const getAttendanceQueues = async (request?: Requests) => {
  const teams: Queue[] = [];
  const data = await request?.getAllAttendants(Guid.raw());

  if (data && data.status !== 'failure') {
    const { resource: allAttendants } = data;
    const { items: attendants } = allAttendants;

    const { resource: allQueues } = await request?.getAllAttendanceQueues(Guid.raw());
    const { items: queues } = allQueues;

    for (const queue of queues) {
      const emails = attendants
        .filter((attendant: any) => attendant.teams.includes(queue.name))
        .map((attendant: any) => {
          return attendant.email;
        });
      if (queue.name !== 'Default') {
        teams.push({
          id: queue.id,
          name: queue.name,
          emails,
        });
      }
    }
  }
  return teams;
};
