import styled from 'styled-components';

export const FooterChanges = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;

  @media screen and (max-width: 470px) {
    margin-top: 20px;
    flex-wrap: wrap-reverse;
  }
`;

export const RightContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 470px) {
    width: 100%;
  }
`;

export const LeftContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 470px) {
    width: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: flex-end;
  }
`;

export const AlertChanges = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
  margin-top: 5px;

  @media screen and (max-width: 470px) {
    width: 50%;
    margin-bottom: 10px;
    margin-right: 0px;
  }
`;

export const SingleButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  bds-button {
    margin-top: 24px;
  }
`;
