import BlipChatLogo from './BlipChatLogo.svg';
import InstagramLogo from './InstagramLogo.svg';
import LogoExtended from './LogoExtended.svg';
import LogoSmall from './LogoSmall.svg';
import MessengerLogo from './MessengerLogo.svg';
import WhatsAppLogo from './WhatsAppLogo.svg';
import WhatsAppLogoExtended from './WhatsappLogoExtended.svg';
import BlipGoLogo from './BlipGoLogo.svg';
import BlipGoLogoExtended from './BlipGoLogoExtended.svg';

const Logos = {
  WhatsAppLogo,
  WhatsAppLogoExtended,
  BlipChatLogo,
  InstagramLogo,
  LogoExtended,
  LogoSmall,
  MessengerLogo,
  BlipGoLogo,
  BlipGoLogoExtended,
};

export default Logos;
