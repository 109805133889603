import { Module } from 'typings/Module';
import IframeModules from 'pages/IframeModules';
import { Route, Routes } from 'react-router-dom';
import {
  BOT_ROUTE,
  CHANNELS_ROUTE,
  DESK_ROUTER,
  HUMAN_SERVICE_ROUTE,
  META_CONNECTION,
  NUMBER_PREPARATION,
  PROCESSING_UPGRADE,
  CONTRACT_INFORMATION,
  ACTIVE_MESSAGE,
} from 'constants/Routes';
import BotConfiguration from 'pages/BotConfiguration';
import { Channels } from 'pages/channels';
import { HumanServiceProvider } from 'contexts/HumanServiceContext';
import HumanService from 'pages/HumanService';
import Home from 'pages/Home';
import { NumberPreparation } from 'pages/NumberPreparation';
import { ProcessUpgrade } from 'pages/ProcessUpgrade';
import { MetaConnection } from 'pages/MetaConnection';
import { ContractInformation } from 'pages/ContractInformation';
import ActiveMessage from 'pages/ActiveMessage';

interface IRouterModule {
  activeModule: Module;
}

const RoutersModule = ({ activeModule }: IRouterModule) => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path={DESK_ROUTER} element={<IframeModules activeModule={activeModule} />} />
      <Route path={BOT_ROUTE} element={<BotConfiguration />} />
      <Route
        path={HUMAN_SERVICE_ROUTE}
        element={
          <HumanServiceProvider>
            <HumanService />
          </HumanServiceProvider>
        }
      />
      <Route path={CHANNELS_ROUTE} element={<Channels />} />
      <Route path={`${CHANNELS_ROUTE}/${NUMBER_PREPARATION}`} element={<NumberPreparation />} />
      <Route path={`${CHANNELS_ROUTE}/${PROCESSING_UPGRADE}`} element={<ProcessUpgrade />} />
      <Route path={`${CHANNELS_ROUTE}/${META_CONNECTION}`} element={<MetaConnection />} />
      <Route path={`${CHANNELS_ROUTE}/${CONTRACT_INFORMATION}`} element={<ContractInformation />} />
      <Route path={ACTIVE_MESSAGE} element={<ActiveMessage activeModule={activeModule} />} />
    </Routes>
  );
};

export default RoutersModule;
