import { ILogArgs, logger } from 'packs-template-baseweb';
import api from './Api';

const logArgs: ILogArgs = {
  className: 'BlipChatService',
};

async function getBlipChatConfigKey(botShortName: string) {
  logArgs.methodName = "getBlipChatConfigKey";

  try {
    const response = await api().get(`configurations/blip-chat/${botShortName}`);

    if (response.data.status === 'failure') {
      logger.error(response.data, logArgs);
    }

    return response.data;
  } catch (exception: any) {
    logger.error(`Error on get blip chat config key: ${exception}`, logArgs);
  }
}

export const ConfigurationBlipChat = {
  getBlipChatConfigKey,
};
