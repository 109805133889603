import { useState } from 'react';

import { BlipPacksTrackingSegment } from 'constants/MicrofrontEndEvents';
import { usePackContext } from 'contexts/PackContext';

import { PublishEvent } from 'services/EventPublisher';
import { getDeviceType } from '../utils';
import { usePackManagerContext } from 'contexts/PackManagerContext';

type TrackProps = {
  screenName?: string;
  buttonName?: string;
  linkName?: string;
  userOption?: string;
  activationOption?: string;
  statusButton?: string;
  status?: string;
  moduleName?: string;
  error?: string;
  moduleInternal?: boolean;
  action?: string;
  answer?: string;
};

type DefaultProps = {
  packName: string;
  packId: string | 60 | 430;
  botIdentifier: string;
  screenResolution: string;
  operationalSystem: string;
  deviceType: string;
  tenant: string;
  plan?: string;
  merchantName: string;
  isOwner: boolean;
  ownerSO: 'november';
};

const useTrack = () => {
  const { pack } = usePackContext();
  const { profile } = usePackManagerContext();
  const { isOwner } = usePackContext();
  const [defaultProperties, updateDefaultProperties] = useState<DefaultProps>({
    packName: pack.name,
    packId: pack.id,
    botIdentifier: pack.routerData?.shortName ?? '',
    screenResolution: `${screen.width}x${screen.height}`,
    operationalSystem: navigator.userAgentData?.platform ?? '',
    deviceType: getDeviceType(),
    tenant: pack.tenant.id,
    plan: pack.tenant.plan?.name ?? '',
    merchantName: profile?.FullName,
    isOwner: isOwner,
    ownerSO: 'november',
  });

  const track = (event: string, trackProps?: TrackProps) => {
    const payload = { ...defaultProperties, ...trackProps };
    PublishEvent(BlipPacksTrackingSegment, { event, payload });
  };

  return { track, updateDefaultProperties };
};

export default useTrack;
