import React, { Component } from 'react';

import { AppTabsProvider } from 'contexts/TabsContext';
import { Tabs } from 'pages/BotConfiguration/components/Tabs';

import { WarningTab } from 'pages/BotConfiguration/components/Alerts/WarningTab';
import { AlertsTab } from 'pages/BotConfiguration/components/Alerts/AlertsTab';

export default class BotConfiguration extends Component {
  render() {
    return (
      <AppTabsProvider>
        <Tabs />
        <WarningTab></WarningTab>
        <AlertsTab></AlertsTab>
      </AppTabsProvider>
    );
  }
}
