interface DeleteResourceProps {
  resourceName: string;
  guid: string;
}

export const DeleteResource = ({ resourceName, guid }: DeleteResourceProps) => {
  return {
    id: guid,
    method: 'delete',
    uri: `/resources/${encodeURIComponent(resourceName)}`,
  };
};
