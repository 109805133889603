import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  cursor: pointer;
  user-select: none;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const IconContainer = styled.div`
  width: 60px;
  padding-right: 24px;
`;

export const TipText = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
  margin-bottom: 8px;
`;
